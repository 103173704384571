import { ReactChild } from 'react';

import { IOption } from '../../../models/Option';
import { IOptionFacade } from '../../OptionFacade';
import { IOptionsFacadeService } from '../IOptionsFacadeService';

export class OptionsFacadeService<Label extends ReactChild, Value extends ReactChild>
  implements IOptionsFacadeService<Label, Value>
{
  public readonly options: Array<IOption<Label, Value>>;

  public constructor(options: Array<IOption<Label, Value>>) {
    this.options = options;
  }

  public pushOptionsIfNonNullable(...optionFacades: Array<IOptionFacade<Label, Value>>): Array<boolean> {
    return optionFacades.map(optionFacade => this.pushOptionIfNonNullable(optionFacade));
  }

  public pushOptionIfNonNullable(optionFacade: IOptionFacade<Label, Value>): boolean {
    const option = optionFacade.getOption();

    if (option) {
      this.pushOption(option);

      return true;
    }

    return false;
  }

  public pushOption(option: IOption<Label, Value>): number {
    return this.options.push(option);
  }

  public pushOptions(...options: Array<IOption<Label, Value>>): Array<number> {
    return options.map(option => this.pushOption(option));
  }
}

import { UIHeading3, UIText1 } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectIsOfferWithMultiAds } from 'shared/selectors';

import * as styles from './AreaParts.css';
import { IAreaPartsProps } from './types';
import { AreasTableContainer } from '../../containers/AreasTableContainer';
import { getIsRent, getPrice, getPriceOfMeter } from '../../utils/areaParts';

export const AreaParts: React.FC<IAreaPartsProps> = props => {
  const { areaParts, bargainTerms } = props;
  const { paymentPeriod } = bargainTerms;
  const isOfferMultiAds = useSelector(selectIsOfferWithMultiAds);

  if (isOfferMultiAds) {
    return <AreasTableContainer />;
  }

  if (!areaParts.length) {
    return null;
  }

  const isRent = getIsRent(paymentPeriod);

  const areaPartsView = areaParts.map((areaPart, index) => {
    const price = getPrice(isRent, areaPart);
    const priceOfMeter = getPriceOfMeter(isRent, areaPart);

    return (
      <div key={`offer-area-part-${index}`} data-testid="area-part" className={styles['area-part']}>
        <div className={styles['area-col']}>
          {!!areaPart.area && <UIText1>{areaPart.area.toString().replace('.', ',')} м²</UIText1>}
        </div>
        <UIText1 display="block" color="gray60_100">
          {priceOfMeter}
        </UIText1>
        <UIText1 display="block">{price}</UIText1>
      </div>
    );
  });

  return (
    <div className={styles['container']}>
      <div className={styles['heading-wrapper']}>
        <UIHeading3>Доступные площади</UIHeading3>
      </div>
      <div className={styles['area-parts-list']}>{areaPartsView}</div>
    </div>
  );
};

AreaParts.displayName = 'AreaParts';

import { IAgentSchema, IOfferSchema, IUserSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

interface IHandleStartChatDependencies {
  user: IUserSchema | null;
  offer: IOfferSchema;
  agent: IAgentSchema | null;
  onOwnOfferPopupOpen(): void;
  onOpenNotAvailablePopup(): void;
}

export function handleAvailability(dependencies: IHandleStartChatDependencies): void {
  const { user, offer, onOwnOfferPopupOpen, onOpenNotAvailablePopup } = dependencies;

  const isUserAuthenticated = !!user?.isAuthenticated;
  const isOfferOwnedByUser = isUserAuthenticated && user.realtyUserId === offer.userId;

  if (isOfferOwnedByUser) {
    onOwnOfferPopupOpen();
  } else {
    onOpenNotAvailablePopup();
  }
}

import { UIText1, UIText2 } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './AreasRow.css';
import { IFormattedAreaPartSchema } from '../../../../../repositories/offer-card/entities/formatted_area_parts_info/FormattedAreaPartSchema';

interface IAreasRowProps {
  part: IFormattedAreaPartSchema;
  isTableExpandable: boolean;
  gallerySlot: React.ReactNode;
}

export const AreasRow: React.FC<IAreasRowProps> = props => {
  const { part, isTableExpandable, gallerySlot } = props;

  return (
    <div className={cx(styles['row'], isTableExpandable && styles['expandable'])}>
      <div className={styles['photo']}>{gallerySlot}</div>
      <div className={styles['area']}>
        <div className={styles['area-data-wrapper']}>
          <UIText1>{part.area}</UIText1>
        </div>
        <UIText2 color="gray60_100">{`${part.floor} этаж`}</UIText2>
      </div>
      <div className={styles['price']}>
        <div className={styles['price-data-wrapper']}>
          <UIText1>{part.price}</UIText1>
        </div>
        <UIText2 color="gray60_100">{part.pricePerSquareMeter}</UIText2>
      </div>
    </div>
  );
};

AreasRow.displayName = 'AreasRow';

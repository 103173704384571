import { ILinkButton } from '../../../../models/LinkButton';
import { LinkButton } from '../../../../models/LinkButton/implementations';
import { IBusinessShoppingCenterSchema } from '../../../../repositories/offer-card/entities/business_shopping_center/BusinessShoppingCenterSchema';
import { getFriendlyBCTCShortLabel } from '../../../../utils/tcbc/getFriendlyBCTCShortLabel';
import { ILinkButtonFacade } from '../../ILinkButtonFacade';

export class TCBCMoreDetailsButtonFacade implements ILinkButtonFacade {
  protected readonly businessShoppingCenter: IBusinessShoppingCenterSchema | null;

  public constructor(businessShoppingCenter: IBusinessShoppingCenterSchema | null) {
    this.businessShoppingCenter = businessShoppingCenter;
  }

  public getLinkButton(onClick?: VoidFunction): ILinkButton | null {
    const title = this.getTitle();
    const link = this.getLink();

    if (!title || !link) {
      return null;
    }

    return new LinkButton(link, title, onClick);
  }

  protected getLink(): string | null {
    if (!this.businessShoppingCenter) {
      return null;
    }

    const { url, parentUrl } = this.businessShoppingCenter;

    return url || parentUrl || null;
  }

  protected getTitle(): string | null {
    if (!this.businessShoppingCenter) {
      return null;
    }

    const { type } = this.businessShoppingCenter;

    const friendlyBCTCShortLabel = getFriendlyBCTCShortLabel(type);

    if (!friendlyBCTCShortLabel) {
      return null;
    }

    return `Подробнее о ${friendlyBCTCShortLabel}`;
  }
}

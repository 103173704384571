import { RepeatableRequestManager } from '@cian/valuation-utils-component';

export let AnalyticsPaymentStatusUpdateService: RepeatableRequestManager | undefined;

export const initUpdatePaymentStatusService = (maxRepeatCount: number) => {
  let requestsCount = 0;

  AnalyticsPaymentStatusUpdateService = new RepeatableRequestManager();

  const incrementRequestsCount = () => {
    requestsCount += 1;
  };

  const resetRequestsCount = () => {
    requestsCount = 0;
  };

  const getRequestCount = () => requestsCount;

  const getIsMaxRequestCountExceeded = () => requestsCount > maxRepeatCount;

  return {
    incrementRequestsCount,
    resetRequestsCount,
    getIsMaxRequestCountExceeded,
    getRequestCount,
  };
};

import { UIHeading1, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './FeedbackFormSuccessScreen.css';

export const FeedbackFormSuccessScreen: React.FC = () => {
  return (
    <section className={styles['form-success-header']}>
      <div className={styles['form-header-title-container']}>
        <UIHeading1>Спасибо, что рассказали</UIHeading1>
      </div>
      <UIText1>Это поможет улучшить отчёты</UIText1>
    </section>
  );
};

FeedbackFormSuccessScreen.displayName = 'FeedbackFormSuccessScreen';

import { IOption, Option } from 'shared/models/Option';
import { IBuildingSchema } from 'shared/repositories/offer-card/entities/building/BuildingSchema';
import {
  EConditioningType,
  IBusinessShoppingCenterSchema,
} from 'shared/repositories/offer-card/entities/business_shopping_center/BusinessShoppingCenterSchema';
import { IOptionFacade } from 'shared/services/OptionFacade';

export class ConditioningTypeOptionFacade implements IOptionFacade<string, string> {
  protected readonly building: IBuildingSchema | null;
  protected readonly businessShoppingCenter: IBusinessShoppingCenterSchema | null;

  public constructor(building: IBuildingSchema | null, businessShoppingCenter: IBusinessShoppingCenterSchema | null) {
    this.building = building;
    this.businessShoppingCenter = businessShoppingCenter;
  }

  public getOption(): IOption<string, string> | null {
    const value = this.value;

    if (!value) {
      return null;
    }

    return new Option(this.label, value, this.key);
  }

  protected get value(): string | null {
    const conditioningType = this.building?.conditioningType ?? this.businessShoppingCenter?.conditioningType;

    switch (conditioningType) {
      case EConditioningType.Central:
        return 'Центральное';
      case EConditioningType.Local:
        return 'Местное';
      default:
        return null;
    }
  }

  protected get label(): string {
    return 'Кондиционирование';
  }

  protected get key(): string {
    return 'conditioningType';
  }
}

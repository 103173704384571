import { useIsomorphicLayoutEffect } from '@cian/react-utils';
import * as React from 'react';

import * as styles from './AreaPartsGallery.css';
import { GalleryFullscreen } from './components/GalleryFullscreen';
import { GalleryInner } from './components/GalleryInner';
import { InfoControls } from './components/InfoControls';
import { TMedia } from './types';

const INITIAL_SLIDE_INDEX = 0;
const noMediaTestId = 'no-media-test-id';

interface IGalleryProps {
  slotFullscreenBefore?: React.ComponentProps<typeof GalleryFullscreen>['slotBefore'];
  media: TMedia[];
}

const AreaPartsGalleryComponent: React.FC<React.PropsWithChildren<IGalleryProps>> = props => {
  const { slotFullscreenBefore, media } = props;

  const galleryInnerRef = React.useRef<React.ElementRef<typeof GalleryInner>>(null);
  const [isFullscreenOpen, setIsFullscreenOpen] = React.useState(false);

  useIsomorphicLayoutEffect(() => {
    if (!isFullscreenOpen) {
      requestAnimationFrame(() => {
        galleryInnerRef.current?.scrollTo(INITIAL_SLIDE_INDEX, { behavior: 'auto' });
      });
    }
  }, [isFullscreenOpen]);

  /* istanbul ignore next */
  const handleScrollerClick = React.useCallback(() => {
    setIsFullscreenOpen(true);
  }, []);

  /* istanbul ignore next */
  const handleFullscreenClose = React.useCallback(() => {
    setIsFullscreenOpen(false);
  }, []);

  if (media.length === 0) {
    return <div className={styles['no-media']} data-testid={noMediaTestId} />;
  }

  return (
    <div className={styles['container']}>
      <GalleryInner
        ref={galleryInnerRef}
        bordered={true}
        media={media}
        slotScrollerEnd={<InfoControls media={media} />}
        onScrollerClick={handleScrollerClick}
        isFullscreen={false}
      />
      <GalleryFullscreen
        initialSlide={INITIAL_SLIDE_INDEX}
        slotBefore={slotFullscreenBefore}
        media={media}
        open={isFullscreenOpen}
        onClose={handleFullscreenClose}
      />
    </div>
  );
};

export const AreaPartsGallery = React.memo(AreaPartsGalleryComponent);

export const REPORT_PROPERTIES_NAMES = [
  'Аналитика цен за 3 года',
  'Вакантные помещения и объявления с Циан',
  'Технические характеристики',
  'Кадастровый номер и стоимость',
  'Подборка похожих зданий с диапазонами цен',
  'Формат готовой презентации',
  'Правообладатели и обременения по ним (при наличии выписки из Росреестра)',
  'Информация по текущим арендаторам',
];

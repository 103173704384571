import { UIText1 } from '@cian/ui-kit';
import { IconMediaPhoto16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './InfoControls.css';
import { TMedia } from '../../types';

export const MIN_ITEMS_NUMBER_CONTROLS_VISIBLE = 1;

interface IInfoControlsProps {
  media: TMedia[];
}

const InfoControlsComponent: React.FC<React.PropsWithChildren<IInfoControlsProps>> = ({ media }) => {
  const numberOfMedia = media.length;

  if (numberOfMedia === 0 || numberOfMedia < MIN_ITEMS_NUMBER_CONTROLS_VISIBLE) {
    return null;
  }

  return (
    <span className={styles['container']}>
      <button className={styles['button']}>
        <span className={styles['icon']}>
          <IconMediaPhoto16 display="block" color="icon-inverted-default" />
        </span>
        <UIText1 color="text-inverted-default">{numberOfMedia}</UIText1>
      </button>
    </span>
  );
};

export const InfoControls = React.memo(InfoControlsComponent);

import { ActionAfterViewed } from '@cian/action-after-viewed-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { OfferSummaryInfoItem } from 'shared/components/OfferSummaryInfo';
import { CommercialOfferSummaryInfoGroup } from 'shared/components/OfferSummaryInfo/parts/CommercialOfferSummaryInfoGroup';
import { FeaturesNewV2 } from 'shared/containers/FeaturesNew';
import { selectFeatures } from 'shared/selectors';

import { trackAboutFlatAndHomeShow } from './tracking/tracking';

export const OfferSummaryInfoCommercial: React.FC = () => {
  const features = useSelector(selectFeatures);

  const [firstFeature, secondFeature, ...othersFeatures] = features || [];

  if (!firstFeature) {
    return null;
  }

  return (
    <ActionAfterViewed callback={trackAboutFlatAndHomeShow}>
      <>
        <div>
          <CommercialOfferSummaryInfoGroup header={firstFeature.title}>
            {firstFeature.features.map(({ label, value }, index) => (
              <OfferSummaryInfoItem key={label + index} name={label} value={value} />
            ))}
          </CommercialOfferSummaryInfoGroup>

          {secondFeature && (
            <CommercialOfferSummaryInfoGroup header={secondFeature.title}>
              {secondFeature.features.map(({ label, value }, index) => (
                <OfferSummaryInfoItem key={label + index} name={label} value={value} />
              ))}
            </CommercialOfferSummaryInfoGroup>
          )}
        </div>

        <FeaturesNewV2 />

        {othersFeatures && (
          <div>
            {othersFeatures.map(({ title, features }, index) => (
              <CommercialOfferSummaryInfoGroup key={title + index} header={title}>
                {features.map(({ label, value }, index) => (
                  <OfferSummaryInfoItem key={label + index} name={label} value={value} />
                ))}
              </CommercialOfferSummaryInfoGroup>
            ))}
          </div>
        )}
      </>
    </ActionAfterViewed>
  );
};

import { IAgentSchema } from '../../../../../../repositories/offer-card/entities/agent/AgentSchema';
import { IBusinessShoppingCenterSchema } from '../../../../../../repositories/offer-card/entities/business_shopping_center/BusinessShoppingCenterSchema';
import { IOfferSchema } from '../../../../../../repositories/offer-card/entities/offer/OfferSchema';
import { trackTCBCFactory } from '../tracking/trackTCBCFactory';

export function onPhotoClick(
  offer: IOfferSchema,
  businessShoppingCenter: IBusinessShoppingCenterSchema,
  agent: IAgentSchema | null,
) {
  if (businessShoppingCenter.url) {
    trackTCBCFactory(offer, businessShoppingCenter, agent)('to_BC_card');

    window.open(businessShoppingCenter.url, '_blank');
  }
}

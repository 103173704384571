import * as React from 'react';

export interface IBestPlaceReportContextValue {
  reportEmail: string;
  setReportEmail: (email: string) => void;
}

export const defaultContextValue: IBestPlaceReportContextValue = {
  reportEmail: '',
  setReportEmail: () => {},
};

export const BestPlaceReportEmailContext = React.createContext<IBestPlaceReportContextValue>(defaultContextValue);

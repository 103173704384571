import { numberToPrettyString } from '@cian/utils';

import { getPrefix } from './getPrefix';

function getCurrencyPostfix(isRent: boolean): string {
  return isRent ? '/мес.' : '';
}

export function getFriendlyPrice(price: number | undefined | null, isRent: boolean, hasPrefix?: boolean): string {
  if (price == null) {
    return '';
  }

  return `${getPrefix(!!hasPrefix)} ${numberToPrettyString(Math.round(price))} ₽${getCurrencyPostfix(isRent)}`.trim();
}

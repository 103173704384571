import { Image, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './OfferListItem.css';
import { OfferListItemProps } from './types';

export const OfferListItem: React.FC<OfferListItemProps> = props => {
  const { labelsModel, titleLink, price, totalArea, photoMiniUrl, onClick } = props;

  return (
    <div className={styles['container']}>
      <UIText1 color="text-main-default">
        <a
          data-testid="offer-list-item-link"
          className={styles['title-link']}
          href={titleLink}
          rel="noopener noreferrer"
          target="_blank"
          onClick={onClick}
          data-label={labelsModel.label}
          data-short-label={labelsModel.shortLabel}
        />
      </UIText1>
      <UIText1 textAlign="right" color="gray60_100">
        {totalArea}
      </UIText1>
      <UIText1 textAlign="right">{price}</UIText1>
      <div className={styles['image-col']}>
        <a className={styles['photo-link']} href={titleLink} rel="noopener noreferrer" target="_blank">
          <Image height={40} src={photoMiniUrl ?? ''} borderRadius={0} width={40} />
        </a>
      </div>
    </div>
  );
};

OfferListItem.displayName = 'OfferListItem';

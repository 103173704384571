import { IPublicV1GetPaymentUrlRequestSchema } from 'shared/repositories/commercial-geo-analytics/entities/requests/PublicV1GetPaymentUrlRequestSchema';
import { fetchGetPaymentUrl } from 'shared/repositories/commercial-geo-analytics/v1/get-payment-url';
import { IApplicationContext } from 'shared/types/applicationContext';
import { ResponseError } from 'shared/utils/errors';

import { DEFAULT_ERROR_MESSAGE, DOMAIN_TEXT } from './constants';
import { paymentUrlMappers } from './mappers';
import { TGetBestPlaceAnalyticsPaymentUrlResult } from './types';

export const fetchBestPlaceAnalyticsPaymentUrl = async (
  context: IApplicationContext,
  parameters: IPublicV1GetPaymentUrlRequestSchema,
): Promise<TGetBestPlaceAnalyticsPaymentUrlResult> => {
  const { httpApi, logger } = context;

  try {
    const response = (await fetchGetPaymentUrl({
      httpApi,
      parameters,
      mappers: paymentUrlMappers,
    })) as TGetBestPlaceAnalyticsPaymentUrlResult;

    if (response.errorMessage) {
      logger.warning(
        new ResponseError({
          domain: DOMAIN_TEXT,
          message: response.errorMessage,
          details: { requestParameters: JSON.stringify(parameters) },
        }),
      );
    }

    return response;
  } catch (error) {
    logger.error(error, {
      domain: DOMAIN_TEXT,
      description: DEFAULT_ERROR_MESSAGE,
      requestParameters: JSON.stringify(parameters),
    });

    return {
      response: null,
      errorKey: 'integration',
      errorMessage: DEFAULT_ERROR_MESSAGE,
    };
  }
};

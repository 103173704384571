import { IOption, Option } from 'shared/models/Option';
import { IBuildingSchema } from 'shared/repositories/offer-card/entities/building/BuildingSchema';
import {
  EExtinguishingSystemType,
  IBusinessShoppingCenterSchema,
} from 'shared/repositories/offer-card/entities/business_shopping_center/BusinessShoppingCenterSchema';
import { IOptionFacade } from 'shared/services/OptionFacade';

export class ExtinguishingSystemTypeOptionFacade implements IOptionFacade<string, string> {
  protected readonly building: IBuildingSchema | null;
  protected readonly businessShoppingCenter: IBusinessShoppingCenterSchema | null;

  public constructor(building: IBuildingSchema | null, businessShoppingCenter: IBusinessShoppingCenterSchema | null) {
    this.building = building;
    this.businessShoppingCenter = businessShoppingCenter;
  }

  public getOption(): IOption<string, string> | null {
    const value = this.value;

    if (!value) {
      return null;
    }

    return new Option(this.label, value, this.key);
  }

  protected get value(): string | null {
    const extinguishingSystemType =
      this.building?.extinguishingSystemType ?? this.businessShoppingCenter?.extinguishingSystemType;

    switch (extinguishingSystemType) {
      case EExtinguishingSystemType.Alarm:
        return 'Сигнализация';
      case EExtinguishingSystemType.Gas:
        return 'Газовая';
      case EExtinguishingSystemType.Hydrant:
        return 'Гидрантная';
      case EExtinguishingSystemType.Powder:
        return 'Порошковая';
      case EExtinguishingSystemType.Sprinkler:
        return 'Спринклерная';
      default:
        return null;
    }
  }

  protected get label(): string {
    return 'Система пожаротушения';
  }

  protected get key(): string {
    return 'extinguishingSystemType';
  }
}

import cx from 'clsx';
import * as React from 'react';

import * as style from './style.css';

export interface ICoworkingAmenitiesItemProps {
  theme: string;
  name: string;
}

export const CoworkingAmenitiesItem: React.FC<React.PropsWithChildren<ICoworkingAmenitiesItemProps>> = ({
  theme,
  name,
}) => {
  return <div className={cx(style['item'], style[theme])}>{name}</div>;
};

import { IOption, Option } from 'shared/models/Option';
import { IBuildingSchema } from 'shared/repositories/offer-card/entities/building/BuildingSchema';
import {
  EVentilationType,
  IBusinessShoppingCenterSchema,
} from 'shared/repositories/offer-card/entities/business_shopping_center/BusinessShoppingCenterSchema';
import { IOptionFacade } from 'shared/services/OptionFacade';

export class VentilationTypeOptionFacade implements IOptionFacade<string, string> {
  protected readonly building: IBuildingSchema | null;
  protected readonly businessShoppingCenter: IBusinessShoppingCenterSchema | null;

  public constructor(building: IBuildingSchema | null, businessShoppingCenter: IBusinessShoppingCenterSchema | null) {
    this.building = building;
    this.businessShoppingCenter = businessShoppingCenter;
  }

  public getOption(): IOption<string, string> | null {
    const value = this.value;

    if (!value) {
      return null;
    }

    return new Option(this.label, value, this.key);
  }

  protected get value(): string | null {
    const ventilationType = this.building?.ventilationType ?? this.businessShoppingCenter?.ventilationType;

    switch (ventilationType) {
      case EVentilationType.Forced:
        return 'Приточная';
      case EVentilationType.Natural:
        return 'Естественная';
      default:
        return null;
    }
  }

  protected get label(): string {
    return 'Вентиляция';
  }

  protected get key(): string {
    return 'ventilationType';
  }
}

import { LinkButton } from '@cian/ui-kit';
import React from 'react';

import { CardSectionNew } from 'shared/components/CardSectionNew';

import styles from './CommercialAboutTCBCView.css';
import { ICommercialAboutTCBCProps } from './types';
import { CommercialOtherTCBCOffers } from '../CommercialOtherTCBCOffers';
import { CommercialTCBCInfo } from '../CommercialTCBCInfo';
import { CommercialTCBCPhotoGallery } from '../CommercialTCBCPhotoGallery';

export const CommercialAboutTCBCView: React.FC<ICommercialAboutTCBCProps> = props => {
  const {
    photos,
    tcbcInfoTitle,
    tcbcInfoItems,
    photoGalleryTitle,
    moreDetailsButton,
    tcbcOffersButton,
    otherOffersTitle,
    businessShoppingCenterOffers,
    isRent,
    onPhotoClick,
    onShowAllOffersClick,
    onListItemClick,
  } = props;

  return (
    <div className={styles['container']} data-testid="commercial-about-tcbc-view">
      {!!tcbcInfoItems.length && (
        <CardSectionNew title={tcbcInfoTitle}>
          <CommercialTCBCInfo items={tcbcInfoItems} />
          {!!moreDetailsButton && (
            <div className={styles['more-details-button-wrapper']}>
              <LinkButton
                data-testid="more-details-button"
                fullWidth={false}
                href={moreDetailsButton.link}
                rel="noreferrer noopener"
                size="XS"
                target="_blank"
                theme="fill_secondary"
                onClick={moreDetailsButton.onClick}
              >
                {moreDetailsButton.title}
              </LinkButton>
            </div>
          )}
        </CardSectionNew>
      )}

      {!!photos.length && (
        <CardSectionNew title={photoGalleryTitle}>
          <CommercialTCBCPhotoGallery photos={photos} onPhotoClick={onPhotoClick} />
        </CardSectionNew>
      )}

      {!!businessShoppingCenterOffers?.otherOfferGroups.length && (
        <CardSectionNew title={otherOffersTitle}>
          <CommercialOtherTCBCOffers
            isRent={isRent}
            otherOffersGroups={businessShoppingCenterOffers.otherOfferGroups}
            onShowAllOffersClick={onShowAllOffersClick}
            onListItemClick={onListItemClick}
          />

          {!!tcbcOffersButton && (
            <div className={styles['tcbc-offers-button-wrapper']}>
              <LinkButton
                data-testid="tcbc-offers-button"
                fullWidth={false}
                href={tcbcOffersButton.link}
                rel="noreferrer noopener"
                size="XS"
                target="_blank"
                theme="fill_secondary"
                onClick={tcbcOffersButton.onClick}
              >
                {tcbcOffersButton.title}
              </LinkButton>
            </div>
          )}
        </CardSectionNew>
      )}
    </div>
  );
};

CommercialAboutTCBCView.displayName = 'CommercialAboutTCBCView';

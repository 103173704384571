import { ca } from '@cian/analytics';

import { abGroupSelector, selectOfferId, selectUser } from 'shared/selectors';
import { getUser } from 'shared/utils/tracking/getUser';

import { IThunkActionCreator } from '../../store';

export function trackBestPlaceAnalytics(): IThunkActionCreator {
  return async (dispatch, getState) => {
    const state = getState();
    const id = selectOfferId(state);
    const user = selectUser(state);
    const abGroup = abGroupSelector(state);
    const { userId } = getUser(user, abGroup);

    ca('eventSite', {
      name: 'oldevent',
      action: 'click_order_report',
      category: 'Card',
      label: 'geoanalytics_popup',
      page: {
        pageType: 'Card',
      },
      products: [{ id }],
      user: { userId },
    });
  };
}

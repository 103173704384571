import { EType } from '../../repositories/offer-card/entities/business_shopping_center/BusinessShoppingCenterSchema';

export function getTCBCAnalyticsLabel(type: EType | null | undefined): string | null {
  switch (type) {
    case EType.Warehouse:
      return 'sr';
    case EType.ShoppingCenter:
      return 'tc';
    case EType.BusinessCenter:
      return 'bc';
    default:
      return null;
  }
}

import { TCBCOffersButtonFacade } from './TCBCOffersButtonFacade';
import { getFriendlyBCTCShortLabel } from '../../../../utils/tcbc/getFriendlyBCTCShortLabel';
import { ILinkButtonFacade } from '../../ILinkButtonFacade';

export class ShowAllTCBCOffersButtonFacade extends TCBCOffersButtonFacade implements ILinkButtonFacade {
  protected getTitle(): string | null {
    if (!this.businessShoppingCenter) {
      return null;
    }

    const { type } = this.businessShoppingCenter;

    const friendlyBCTCLabel = getFriendlyBCTCShortLabel(type);

    if (friendlyBCTCLabel) {
      return `Смотреть все объявления в ${friendlyBCTCLabel}`;
    }

    return null;
  }
}

import * as React from 'react';
import { useSelector } from 'react-redux';

import { AreasTable } from '../../components/AreaParts/components/AreasTable';
import { selectOfferPhotos } from '../../selectors/offerData/offer';
import { selectOfferFormattedAreaParts } from '../../selectors/offerData/selectOfferFormattedAreaParts';

export const AreasTableContainer: React.FC = () => {
  const formattedAreaParts = useSelector(selectOfferFormattedAreaParts);
  const offerDataPhotos = useSelector(selectOfferPhotos);

  if (!formattedAreaParts || formattedAreaParts.parts.length === 0) {
    return null;
  }

  return <AreasTable formattedAreaParts={formattedAreaParts} offerDataPhotos={offerDataPhotos} />;
};

AreasTableContainer.displayName = 'AreasTableContainer';

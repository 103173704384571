import { UIText1 } from '@cian/ui-kit';
import React from 'react';

import styles from './CommercialTCBCInfoItem.css';

export interface ICommercialTCBCInfoItemProps {
  label: JSX.Element | string | number;
  value: JSX.Element | string | number;
}

export const CommercialTCBCInfoItem: React.FC<ICommercialTCBCInfoItemProps> = props => {
  const { label, value } = props;

  return (
    <li className={styles['container']}>
      <UIText1 color="gray60_100">{label}</UIText1>
      <UIText1 textAlign="right" color="text-primary-default">
        {value}
      </UIText1>
    </li>
  );
};

CommercialTCBCInfoItem.displayName = 'CommercialTCBCInfoItem';

import { useDidMount } from '@cian/valuation-utils-component';
import { useFormik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setBestPlaceAnalyticsStatus } from 'shared/actions/bestPlaceAnalytics';
import { selectReportUserEmail } from 'shared/selectors/bestPlaceAnalytics';
import { EBestPlaceReportStatus } from 'shared/types/bestPlaceAnalytics';

import { useFeedbackModalTracking } from './internal/hooks';
import { FeedbackForm } from '../../components/FeedbackForm';
import { FORM_FIELDS } from '../../components/FeedbackForm/constants';
import { IFormValues } from '../../components/FeedbackForm/types';

const initialFormValues: IFormValues = {
  [FORM_FIELDS.businessType]: '',
};

export const FeedbackFormContainer: React.FC = () => {
  const { trackGeoAnalyticsPopupOpened, trackGeoAnalyticsPopupFeedback } = useFeedbackModalTracking();
  const dispatch = useDispatch();

  const reportUserEmail = useSelector(selectReportUserEmail);

  useDidMount(() => {
    trackGeoAnalyticsPopupOpened();
  });

  const formik = useFormik({
    initialValues: initialFormValues,
    onSubmit: (values, formikHelpers) => {
      trackGeoAnalyticsPopupFeedback(values);
      formikHelpers.resetForm();

      dispatch(setBestPlaceAnalyticsStatus(EBestPlaceReportStatus.FeedbackSent));
    },
  });

  return <FeedbackForm formik={formik} reportUserEmail={reportUserEmail} />;
};

FeedbackFormContainer.displayName = 'FeedbackFormContainer';

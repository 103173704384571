import cx from 'clsx';
import * as React from 'react';
import { throttle } from 'throttle-debounce';

import { VerticalArrow, IVerticalArrowProps } from './VerticalArrow';
import { SCROLL_CLICK_DEAD_ZONE, SCROLL_TROTTLING_DELAY } from './consts';
import * as styles from './styles.css';
import { getRightScrollOffset, getLeftScrollOffset } from './utils/getScrollOffsets';
import { scrollToPos } from './utils/scrollTo';

export interface IVerticalSliderProps {
  arrowOffset?: number;
  arrow?: React.FC<React.PropsWithChildren<IVerticalArrowProps>>;
  containerClass?: string;
  arrowPositionTop?: string;
  onItemClick?(index: number): void;
}

export type TVerticalSliderProps = React.PropsWithChildren<IVerticalSliderProps>;

interface IVerticalSliderState {
  leftArrowVisible: boolean;
  rightArrowVisible: boolean;
}

export class VerticalSlider extends React.Component<TVerticalSliderProps, IVerticalSliderState> {
  private scrollRef = React.createRef<HTMLUListElement>();
  private startX: number;
  private scrollLeft: number;
  private isDragging = false;

  public state = {
    leftArrowVisible: false,
    rightArrowVisible: false,
  };

  public componentDidMount() {
    this.handleScroll();
  }

  public render() {
    const { containerClass, arrowOffset = 26, arrow: Arrow = VerticalArrow, arrowPositionTop } = this.props;
    const { leftArrowVisible, rightArrowVisible } = this.state;

    return (
      <div className={styles['wrapper']}>
        <ul
          className={cx(styles['container'], containerClass)}
          ref={this.scrollRef}
          onMouseDown={this.handleMouseDown}
          onScroll={this.throttledHandleScroll}
        >
          {React.Children.map(
            this.props.children,
            (child, i) =>
              child && (
                <li key={i} className={cx(styles['item'])} onClick={this.handleItemClick(i)}>
                  {child}
                </li>
              ),
          )}
        </ul>
        {leftArrowVisible && (
          <Arrow direction={-1} offset={arrowOffset} positionTop={arrowPositionTop} onClick={this.onLeftArrowClick} />
        )}
        {rightArrowVisible && (
          <Arrow direction={1} offset={arrowOffset} positionTop={arrowPositionTop} onClick={this.onRightArrowClick} />
        )}
      </div>
    );
  }

  private handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();

    const container = this.scrollRef.current;

    /* istanbul ignore else */
    if (container) {
      this.startX = e.pageX - container.offsetLeft;
      this.scrollLeft = container.scrollLeft;

      window.addEventListener('mousemove', this.handleMove);
      window.addEventListener('mouseup', this.handleMoveEnd);
    }

    this.isDragging = false;
  };

  private handleMoveEnd = () => {
    window.removeEventListener('mousemove', this.handleMove);
    window.removeEventListener('mousemove', this.handleMoveEnd);
  };

  private handleItemClick = (i: number) => (e: React.MouseEvent) => {
    if (this.isDragging) {
      e.stopPropagation();

      return;
    }

    const { onItemClick } = this.props;

    /* istanbul ignore else */
    if (onItemClick) {
      onItemClick(i);
    }
  };

  private handleMove = (e: MouseEvent) => {
    const container = this.scrollRef.current;

    /* istanbul ignore else */
    if (container) {
      if (Math.abs(e.pageX - this.startX) >= SCROLL_CLICK_DEAD_ZONE) {
        this.isDragging = true;
      }

      const x = e.pageX - container.offsetLeft;
      const walk = x - this.startX;

      container.scrollLeft = this.scrollLeft - walk;

      if (container.scrollLeft <= 0) {
        this.startX = x;
        this.scrollLeft = 0;
      }

      const hiddenWidth = container.scrollWidth - container.offsetWidth;

      if (container.scrollLeft >= hiddenWidth) {
        this.startX = x;
        this.scrollLeft = hiddenWidth;
      }
    }
  };

  private handleScroll = () => {
    const container = this.scrollRef.current;
    const { leftArrowVisible, rightArrowVisible } = this.state;

    /* istanbul ignore else */
    if (container) {
      const leftChild = container.firstChild as HTMLLIElement;
      const rightChild = container.lastChild as HTMLLIElement;

      const leftBorderPosition = container.scrollLeft;
      const rightBorderPosition = container.offsetWidth + container.scrollLeft;
      const showLeftArrow = leftChild.offsetLeft - container.offsetLeft < leftBorderPosition;
      const showRightArrow =
        rightChild.offsetLeft + rightChild.offsetWidth - container.offsetLeft > rightBorderPosition;

      if (leftArrowVisible !== showLeftArrow) {
        this.setState({ leftArrowVisible: showLeftArrow });
      }

      if (rightArrowVisible !== showRightArrow) {
        this.setState({ rightArrowVisible: showRightArrow });
      }
    }
  };

  private throttledHandleScroll = throttle(SCROLL_TROTTLING_DELAY, this.handleScroll);

  private onRightArrowClick = () => {
    const container = this.scrollRef.current;

    /* istanbul ignore else */
    if (container) {
      const scrollOffset = getRightScrollOffset(container) as number;

      scrollToPos(container, scrollOffset, 300);
    }
  };

  private onLeftArrowClick = () => {
    const container = this.scrollRef.current;

    /* istanbul ignore else */
    if (container) {
      const scrollOffset = getLeftScrollOffset(container) as number;

      scrollToPos(container, scrollOffset, 300);
    }
  };
}

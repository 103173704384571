/**
 * @todo Удалить файл с экспериментом
 * @description Данный функционал появился в задаче CD-129223, будет удалён в задаче CD-129224
 * Удалить, если эксперимент будет не удачным
 */

import { getGlobalEventsLogs } from '@cian/events-log';

import { IAgentSchema, IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { trackPhonesLimitShown } from './tracking/trackPhonesLimitShown';

/**
 * @deprecated
 * @todo Удалить комментарий с экспериментом
 * @description Данный функционал появился в задаче CD-129223, будет удалён в задаче CD-129224
 * Удалить если эксперимент будет удачным
 */
export function showPhonesLimitAuth(offer: IOfferSchema, agent: IAgentSchema | null) {
  const globalLog = getGlobalEventsLogs();

  trackPhonesLimitShown(offer, agent);

  globalLog.produce('user', {
    type: 'authenticate',
    value: {
      autoRefresh: true,
      viewData: { headingType: 'phoneAuthPermission' },
    },
  });
}

import { ILabelsModel } from '../ILabelsModel';

export class LabelsModel implements ILabelsModel {
  public readonly label: string;
  public readonly shortLabel: string;

  public constructor(label: string, shortLabel = label) {
    this.label = label;
    this.shortLabel = shortLabel;
  }
}

import { getSpecialtyWithIconNameById } from 'shared/components/PossibleAssignment/internal/utils/getSpecialtyWithIconNameById';
import { ISpecialtySchema } from 'shared/repositories/offer-card/entities/commercial_specialty/SpecialtySchema';
import { ISpecialtySchema as DEPECATED__ISpecialtySchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';
import { capitalize } from 'shared/utils/capitalize';

export type SpecialtyItemWithIcon = {
  name: string;
  id: number;
  title: string;
};

export type SpecialtyByTypes = {
  specialtyItemsWithIcon: SpecialtyItemWithIcon[];
  otherSpecialties: string | null;
};

/**
 * @description из-за корявой типизации, которая в проекте берётся не из сваггер генерации, а из легаси api-models
 *   приходится перегрузку делать на входящие типы, если что это можно смело удалять, если в будущем откажетесь от
 *   легаси типов
 */
export function getSpecialties(specialties: ISpecialtySchema[] | DEPECATED__ISpecialtySchema[]): SpecialtyByTypes;
export function getSpecialties(specialties: ISpecialtySchema[]): SpecialtyByTypes {
  const specialtyItemsWithIcon = Array.of<SpecialtyItemWithIcon>();
  const specialtyItemsWithoutIcon = Array.of<string>();

  for (const specialty of specialties) {
    const { id, rusName: title } = specialty;

    if (id != null && title) {
      const specialtyWithIconName = getSpecialtyWithIconNameById(id);

      if (specialtyWithIconName) {
        specialtyItemsWithIcon.push({ title, name: specialtyWithIconName, id });
      } else {
        specialtyItemsWithoutIcon.push(title.toLowerCase());
      }
    }
  }

  const otherSpecialties = ((): string | null => {
    if (specialtyItemsWithoutIcon.length) {
      if (specialtyItemsWithIcon.length) {
        return `Ещё: ${specialtyItemsWithoutIcon.join(', ')}`;
      }

      specialtyItemsWithoutIcon[0] = capitalize(specialtyItemsWithoutIcon[0]);

      return specialtyItemsWithoutIcon.join(', ');
    }

    return null;
  })();

  return { otherSpecialties, specialtyItemsWithIcon };
}

import { IOfferSchema as DEPRECATED__IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { ILinkButton } from '../../../../models/LinkButton';
import { LinkButton } from '../../../../models/LinkButton/implementations';
import { IBusinessShoppingCenterSchema } from '../../../../repositories/offer-card/entities/business_shopping_center/BusinessShoppingCenterSchema';
import { IOfferSchema } from '../../../../repositories/offer-card/entities/offer/OfferSchema';
import { getIsSaleByOffer } from '../../../../utils/deal_type';
import { pluralize } from '../../../../utils/pluralize';
import { getFriendlyBCTCShortLabel } from '../../../../utils/tcbc/getFriendlyBCTCShortLabel';
import { getTCBCOffersCount } from '../../../../utils/tcbc/getTCBCOffersCount';
import { ILinkButtonFacade } from '../../ILinkButtonFacade';

export class TCBCOffersButtonFacade implements ILinkButtonFacade {
  protected static readonly offersPluralize: [string, string, string] = ['объявление', 'объявления', 'объявлений'];

  protected readonly offer: IOfferSchema;
  protected readonly businessShoppingCenter: IBusinessShoppingCenterSchema | null;

  public constructor(offer: IOfferSchema, businessShoppingCenter: IBusinessShoppingCenterSchema | null) {
    this.offer = offer;
    this.businessShoppingCenter = businessShoppingCenter;
  }

  public getLinkButton(onClick?: VoidFunction): ILinkButton | null {
    const title = this.getTitle();
    const link = this.getLink();

    if (!title || !link) {
      return null;
    }

    return new LinkButton(link, title, onClick);
  }

  protected getLink(): string | null {
    if (!this.businessShoppingCenter) {
      return null;
    }

    const { offersRentLink, offersSaleLink } = this.businessShoppingCenter;

    const isSale = getIsSaleByOffer(this.offer as unknown as DEPRECATED__IOfferSchema);

    const link = isSale ? offersSaleLink : offersRentLink;

    return link ?? null;
  }

  protected getTitle(): string | null {
    if (!this.businessShoppingCenter) {
      return null;
    }

    const offersCount = getTCBCOffersCount(this.offer, this.businessShoppingCenter);

    if (offersCount == null) {
      return null;
    }

    const { type } = this.businessShoppingCenter;

    const offersQuantity = pluralize(offersCount, TCBCOffersButtonFacade.offersPluralize);

    const friendlyBCTCLabel = getFriendlyBCTCShortLabel(type);

    if (friendlyBCTCLabel) {
      return `${offersQuantity} в этом ${friendlyBCTCLabel}`;
    }

    return offersQuantity;
  }
}

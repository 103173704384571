import { DEFAULT_ERROR_MESSAGE } from './constants';
import { TGetBestPlaceAnalyticsPaymentUrlMappers, IGetBestPlaceAnalyticsPaymentUrlResult400, IError } from './types';

type TPrepare400ErrorFieldsResult = Omit<IGetBestPlaceAnalyticsPaymentUrlResult400, 'statusCode' | 'response'>;

export const defaultError: TPrepare400ErrorFieldsResult = {
  errorKey: 'integration',
  errorMessage: DEFAULT_ERROR_MESSAGE,
};
export const prepare400ErrorFields = (errors?: IError[]): TPrepare400ErrorFieldsResult => {
  if (!errors || !errors.length) {
    return defaultError;
  }

  const [{ key, message }] = errors;

  return { errorKey: key, errorMessage: message };
};

export const paymentUrlMappers: TGetBestPlaceAnalyticsPaymentUrlMappers = {
  200: response => ({
    response,
    errorKey: null,
    errorMessage: null,
  }),
  400: response => ({
    response: null,
    ...prepare400ErrorFields(response.errors as IError[]),
  }),
};

import { fetchGetPaymentStatus } from 'shared/repositories/commercial-geo-analytics/v1/get-payment-status';
import { IGetPaymentStatusRequest } from 'shared/repositories/commercial-geo-analytics/v1/get-payment-status/types';
import { IApplicationContext } from 'shared/types/applicationContext';
import { ResponseError } from 'shared/utils/errors';

import { DEFAULT_ERROR_MESSAGE, DOMAIN_TEXT } from './constants';
import { paymentStatusMappers } from './mappers';
import { TGetBestPlaceAnalyticsStatusResult } from './types';

export const fetchBestPlaceAnalyticsPaymentStatus = async (
  context: IApplicationContext,
  parameters: IGetPaymentStatusRequest,
): Promise<TGetBestPlaceAnalyticsStatusResult> => {
  const { httpApi, logger } = context;

  try {
    const response = (await fetchGetPaymentStatus({
      httpApi,
      parameters,
      mappers: paymentStatusMappers,
    })) as TGetBestPlaceAnalyticsStatusResult;

    if (response.errorMessage) {
      logger.warning(
        new ResponseError({
          domain: DOMAIN_TEXT,
          message: response.errorMessage,
          details: { requestParameters: JSON.stringify(parameters) },
        }),
      );
    }

    return response;
  } catch (error) {
    logger.error(error, {
      domain: DOMAIN_TEXT,
      description: DEFAULT_ERROR_MESSAGE,
      requestParameters: JSON.stringify(parameters),
    });

    return {
      response: null,
      errorKey: 'integration',
      errorMessage: DEFAULT_ERROR_MESSAGE,
    };
  }
};

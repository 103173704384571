import { IBusinessShoppingCenterSchema } from '../../../../../../repositories/offer-card/entities/business_shopping_center/BusinessShoppingCenterSchema';
import { pluralize } from '../../../../../../utils/pluralize';
import { getFriendlyBCTCShortLabel } from '../../../../../../utils/tcbc/getFriendlyBCTCShortLabel';

export function getOtherOffersGroupsTitle(businessShoppingCenter: IBusinessShoppingCenterSchema): string {
  const friendlyBCTCShortLabel = getFriendlyBCTCShortLabel(businessShoppingCenter.type) ?? 'здании';

  const offersCountText = pluralize(businessShoppingCenter.businessShoppingCenterOffers?.totalOffersCount ?? 0, [
    'объявление',
    'объявления',
    'объявлений',
  ]);

  return `Ещё ${offersCountText} в этом ${friendlyBCTCShortLabel}`;
}

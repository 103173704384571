import { UIHeading2 } from '@cian/ui-kit';
import { plural } from '@cian/utils';
import * as React from 'react';

import * as styles from './AreasHeaderRow.css';

interface IAreasHeaderRowProps {
  numberOfParts: number;
}

export const AreasHeaderRow: React.FC<IAreasHeaderRowProps> = props => {
  const { numberOfParts } = props;
  const areaText = `${plural(numberOfParts, ['Доступна', 'Доступно', 'Доступно'])} ${numberOfParts} ${plural(
    numberOfParts,
    ['площадь', 'площади', 'площадей'],
  )}`;

  return (
    <div className={styles['row']}>
      <UIHeading2>{areaText}</UIHeading2>
    </div>
  );
};

AreasHeaderRow.displayName = 'AreasHeaderRow';

import { numberToPrettyString } from '@cian/utils/lib/shared/number_helpers';

import {
  IBscDeveloperSchema,
  IBuildingSchema,
  IBusinessShoppingCenterSchema,
  ILiftTypeSchema,
  IManagementCompanySchema,
  IOfferSchema,
} from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { SquareSymbol } from './formatters';
import { isArrayWithItems } from './index';

export function getTotalLiftsCount(
  building: IBuildingSchema | null,
  BSC: IBusinessShoppingCenterSchema | null,
): number {
  let totalLiftCount = 0;

  if (building) {
    const { passengerLiftsCount, cargoLiftsCount, liftTypes } = building;

    if (passengerLiftsCount) {
      totalLiftCount += passengerLiftsCount;
    }

    if (cargoLiftsCount) {
      totalLiftCount += cargoLiftsCount;
    }

    if (isArrayWithItems<ILiftTypeSchema>(liftTypes)) {
      liftTypes.forEach(lift => {
        if (lift.count) {
          totalLiftCount += lift.count;
        }
      });
    }
  }

  if (!totalLiftCount && BSC && BSC.totalLiftCount) {
    totalLiftCount = Number(BSC.totalLiftCount);
  }

  return totalLiftCount;
}

export function getBuildingBuildYear(
  building: IBuildingSchema | null,
  BSC: IBusinessShoppingCenterSchema | null,
): number | undefined {
  if (building && building.buildYear) {
    return Number(building.buildYear);
  }

  if (BSC && BSC.buildYear) {
    return Number(BSC.buildYear);
  }

  return undefined;
}

export function getBuildingDeveloper(
  building: IBuildingSchema | null,
  BSC: IBusinessShoppingCenterSchema | null,
): string | undefined {
  if (building?.developer) {
    return building.developer;
  }

  if (BSC && isArrayWithItems<IBscDeveloperSchema>(BSC.developers)) {
    return BSC.developers
      .reduce((accumulator, developer) => {
        if (developer.name) {
          accumulator.push(developer.name);
        }

        return accumulator;
      }, Array.of<string>())
      .join(', ');
  }

  return undefined;
}

export function getBuildingManagementCompany(
  building: IBuildingSchema | null,
  BSC: IBusinessShoppingCenterSchema | null,
): string | undefined {
  if (building?.managementCompany) {
    return building.managementCompany;
  }

  if (BSC && isArrayWithItems<IManagementCompanySchema>(BSC.managementCompanies)) {
    return BSC.managementCompanies
      .reduce((accumulator, managementCompany) => {
        if (managementCompany.name) {
          accumulator.push(managementCompany.name);
        }

        return accumulator;
      }, Array.of<string>())
      .join(', ');
  }

  return undefined;
}

export function getBuildingTotalArea(
  offer: IOfferSchema,
  building: IBuildingSchema | null,
  BSC: IBusinessShoppingCenterSchema | null,
): string | undefined {
  let totalArea: number | undefined;

  if (building && building.totalArea) {
    totalArea = Number(building.totalArea);
  }

  if (BSC && BSC.totalArea) {
    totalArea = Number(BSC.totalArea);
  }

  if (!totalArea) {
    totalArea = Number(offer.totalArea);
  }

  if (totalArea) {
    return `${numberToPrettyString(totalArea)} ${SquareSymbol.SquareMeter}`;
  }

  return undefined;
}

export function getShoppingCenterScaleType(
  building: IBuildingSchema | null,
  BSC: IBusinessShoppingCenterSchema | null,
): string | undefined {
  if (building && building.shoppingCenterScaleType) {
    return building.shoppingCenterScaleType;
  }

  if (BSC && BSC.shoppingCenterScaleType) {
    return BSC.shoppingCenterScaleType;
  }

  return undefined;
}

import { IPublicV1GetCurrentPriceResponseSchema } from 'shared/repositories/commercial-geo-analytics/entities/responses/PublicV1GetCurrentPriceResponseSchema';
import {
  fetchGetCurrentPrice,
  TGetCurrentPriceResponse,
} from 'shared/repositories/commercial-geo-analytics/v1/get-current-price';

import { DEFAULT_ERROR_MESSAGE, DOMAIN_TEXT } from './constants';
import { IApplicationContext } from '../../../types/applicationContext';
import { ResponseError } from '../../../utils/errors';

export async function fetchBestPlaceAnalyticsReportPrices({
  httpApi,
  logger,
}: IApplicationContext): Promise<IPublicV1GetCurrentPriceResponseSchema | null> {
  try {
    const response: TGetCurrentPriceResponse = await fetchGetCurrentPrice({
      httpApi,
    });

    if (response.statusCode === 400) {
      logger.warning(
        new ResponseError({
          domain: DOMAIN_TEXT,
          message: response.response.message ? response.response.message : DEFAULT_ERROR_MESSAGE,
        }),
      );

      return null;
    }

    return response.response;
  } catch (e) {
    logger.warning(e, {
      degradation: 'Данные по ценам для отчета best place',
      domain: DOMAIN_TEXT,
    });

    return null;
  }
}

/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetPaymentStatusModel,
  IGetPaymentStatusRequest,
  IMappers,
  IGetPaymentStatusResponse,
  IGetPaymentStatusResponseError,
  TGetPaymentStatusResponse,
} from './types';

const defaultConfig: TGetPaymentStatusModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'iap-reports-sale',
  pathApi: '/v1/get-payment-status/',
  requestType: 'json',
} as TGetPaymentStatusModel;

function createGetPaymentStatusModel(parameters: IGetPaymentStatusRequest): TGetPaymentStatusModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetPaymentStatusOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetPaymentStatusRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetPaymentStatus<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetPaymentStatusOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetPaymentStatusResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetPaymentStatusModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetPaymentStatusResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetPaymentStatusResponseError);
    }
  }

  return { response, statusCode } as TGetPaymentStatusResponse;
}

export { defaultConfig, createGetPaymentStatusModel, fetchGetPaymentStatus };

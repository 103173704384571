import * as React from 'react';
import { useSelector } from 'react-redux';

import { CardSectionNew } from 'shared/components/CardSectionNew';
import {
  CoworkingAmenitiesLayout,
  CoworkingAmenitiesGroup,
  CoworkingAmenitiesItem,
} from 'shared/components/CoworkingAmenitiesNew';
import { selectCoworkingIncludedAmenities } from 'shared/selectors';
import { isCoworkingSelector } from 'shared/selectors/offerData/offer/isCoworkingSelector';

export const CoworkingAmenities: React.FC = () => {
  const props = useSelector(selectCoworkingIncludedAmenities);
  const isCoworking = useSelector(isCoworkingSelector);

  if (!isCoworking && !props.length) {
    return null;
  }

  return (
    <CardSectionNew>
      <CoworkingAmenitiesLayout>
        {props.map(({ title, amenities }, index) => (
          <CoworkingAmenitiesGroup key={title + index} title={title}>
            {amenities.map(({ iconId, title }) => (
              <CoworkingAmenitiesItem key={iconId} theme={iconId.toLowerCase()} name={title} />
            ))}
          </CoworkingAmenitiesGroup>
        ))}
      </CoworkingAmenitiesLayout>
    </CardSectionNew>
  );
};

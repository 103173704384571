import cx from 'clsx';
import * as React from 'react';

import * as styles from './index.css';

export interface IAdditionalFeatureItemProps {
  className?: string;
  name: string;
  value: React.ReactNode;
}

// FIXME: Отоброжение блока не совпадает с дизайном и использует старый механизм сбора данных на фронте
/* istanbul ignore next */
export class AdditionalFeatureItem extends React.PureComponent<IAdditionalFeatureItemProps, {}> {
  public render() {
    const { className, name, value } = this.props;

    if (!value) {
      return null;
    }

    return (
      <li className={cx([styles['item'], className])}>
        <span className={styles['name']}>{name}</span>
        <span className={styles['value']}>{value}</span>
      </li>
    );
  }
}

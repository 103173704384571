import { IPhotoSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { EMediaType, TMedia } from '../../../AreaPartsGallery';

interface IPrepareFormattedMedia {
  (partPhotosWithFullData: IPhotoSchema[]): TMedia[];
}

export const prepareFormattedMedia: IPrepareFormattedMedia = partPhotosWithFullData =>
  partPhotosWithFullData
    .sort((a, b) => (a.isDefault === b.isDefault ? 0 : a.isDefault ? -1 : 1))
    .map<TMedia>(part => ({
      type: EMediaType.Photo,
      src: part.fullUrl,
      thumbnailSrc: part.thumbnailUrl,
    }));

import { IPhoto } from '../IPhoto';

export class Photo implements IPhoto {
  public readonly key: string;

  public readonly url: string;

  public constructor(key: string, url: string) {
    this.key = key;
    this.url = url;
  }
}

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getBestPlaceAnalyticsReportPrices } from 'shared/actions/bestPlaceAnalytics';
import { openBestPlaceReportModal } from 'shared/actions/bestPlaceReportModal';
import { selectGeoCoordinates } from 'shared/selectors';
import { selectBestPlaceAnalyticsReportExampleUrl } from 'shared/selectors/bestPlaceAnalytics';
import { selectBestPlaceCheckoutEnabled } from 'shared/selectors/bestPlaceAnalytics/selectBestPlaceCheckoutEnabled';
import { selectBestPlaceReportBaseUrl } from 'shared/selectors/bestPlaceAnalytics/selectBestPlaceReportBaseUrl';
import { selectBestPlaceProviderInfo } from 'shared/selectors/bestPlaceAnalytics/selectBestPlaceReportProviderInfo';
import { hasGetParameter } from 'shared/utils/hasGetParameter';

import { BestPlaceReportDescription } from '../../components/BestPlaceReportDescription';
import { useTrackBestPlaceReport } from '../../hooks/useTrackBestPlaceReport';

export const BestPlaceReportDescriptionContainer: React.FC = () => {
  const dispatch = useDispatch();

  const { lat, lng } = useSelector(selectGeoCoordinates);

  const containerRef = React.useRef<HTMLDivElement>(null);

  const reportExampleUrl = useSelector(selectBestPlaceAnalyticsReportExampleUrl);
  const bestPlaceBaseUrl = useSelector(selectBestPlaceReportBaseUrl);
  const isBestPlaceCheckoutEnabled = useSelector(selectBestPlaceCheckoutEnabled);
  const bestPlaceProviderInfo = useSelector(selectBestPlaceProviderInfo);
  const trackBestPlaceReportButtonClick = useTrackBestPlaceReport();

  const reportUrl = `${bestPlaceBaseUrl}/?lonlat=${lng},${lat}&utm_source=cian`;

  React.useEffect(() => {
    const hasBestPlaceParameters = hasGetParameter('with_best_place') || hasGetParameter('transactionId');

    if (!hasBestPlaceParameters) {
      return;
    }

    if (!isBestPlaceCheckoutEnabled) {
      dispatch(openBestPlaceReportModal());
    } else if (containerRef.current) {
      containerRef.current.scrollIntoView();
    }
  }, [isBestPlaceCheckoutEnabled, dispatch]);

  const getReportPrices = React.useCallback(() => {
    if (isBestPlaceCheckoutEnabled) {
      return;
    }

    dispatch(getBestPlaceAnalyticsReportPrices());
  }, [isBestPlaceCheckoutEnabled, dispatch]);

  const handleBuyReport = React.useCallback(() => {
    trackBestPlaceReportButtonClick('click_buy_report');

    if (!isBestPlaceCheckoutEnabled) {
      dispatch(openBestPlaceReportModal());
    }
  }, [isBestPlaceCheckoutEnabled, dispatch, trackBestPlaceReportButtonClick]);

  const handleExamineReportExample = React.useCallback(() => {
    trackBestPlaceReportButtonClick('click_see_report_example');
  }, [trackBestPlaceReportButtonClick]);

  return (
    <div ref={containerRef}>
      <BestPlaceReportDescription
        reportExampleUrl={reportExampleUrl}
        getReportPrices={getReportPrices}
        handleBuyReport={handleBuyReport}
        handleExamineReportExample={handleExamineReportExample}
        reportUrl={reportUrl}
        providerInfo={isBestPlaceCheckoutEnabled ? bestPlaceProviderInfo : undefined}
        isBestPlaceCheckout={isBestPlaceCheckoutEnabled}
      />
    </div>
  );
};

BestPlaceReportDescriptionContainer.displayName = 'BestPlaceReportDescriptionContainer';

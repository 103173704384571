import { useSliderContext } from '@cian/slider';
import * as React from 'react';

import * as styles from './PaginationThumbs.css';
import { Thumb } from './components/Thumb';
import { TMedia } from '../../types';

interface IPaginationThumbsContext {
  scrollCurrentThumbIntoView(): void;
}

interface IPaginationThumbsProps {
  media: TMedia[];
}

const PaginationThumbsComponent: React.ForwardRefRenderFunction<IPaginationThumbsContext, IPaginationThumbsProps> = (
  { media },
  ref,
) => {
  const containerRef = React.useRef<HTMLUListElement>(null);
  const { currentSlide, scrollTo } = useSliderContext();
  const currentSlideRef = React.useRef(currentSlide);
  currentSlideRef.current = currentSlide;

  const handleThumbClick = React.useCallback(
    (m: TMedia) => {
      scrollTo(media.indexOf(m));
    },
    [media, scrollTo],
  );

  /* istanbul ignore next */
  const scrollCurrentThumbIntoView = React.useCallback(() => {
    if (containerRef.current) {
      const children = Array.from(containerRef.current.children);

      children[currentSlideRef.current].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }, []);

  React.useImperativeHandle(
    ref,
    () => ({
      scrollCurrentThumbIntoView,
    }),
    [scrollCurrentThumbIntoView],
  );

  return (
    <ul ref={containerRef} className={styles['container']}>
      {media.map((m, i) => (
        <Thumb key={`media_${i}`} media={m} active={i === currentSlide} onClick={handleThumbClick} />
      ))}
    </ul>
  );
};

export const PaginationThumbs = React.forwardRef(PaginationThumbsComponent);

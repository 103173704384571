export type SpecialtyWithIconNames = 'office' | 'warehouse' | 'trade-area' | 'manufacture';

export function getSpecialtyWithIconNameById(id: number): SpecialtyWithIconNames | null {
  switch (id) {
    case 7026:
      return 'office';
    case 7113:
      return 'warehouse';
    case 7128:
      return 'trade-area';
    case 7104:
      return 'manufacture';
    default:
      return null;
  }
}

import React, { FC } from 'react';

import { CardSectionNew } from 'shared/components/CardSectionNew';
import { PossibleAssignment } from 'shared/components/PossibleAssignment/PossibleAssignment';
import { getSpecialties } from 'shared/components/PossibleAssignment/internal/utils/getSpecialties';
import { useOfferSelector } from 'shared/hooks/selectors/useOfferSelector';
import { FOfferType, getOfferType } from 'shared/utils/offer_type';

export const PossibleAssignmentContainer: FC = () => {
  const offer = useOfferSelector();

  if (
    !offer.specialty?.specialties?.length ||
    ![FOfferType.TradeArea, FOfferType.FreeAppointmentObject].includes(getOfferType(offer))
  ) {
    return null;
  }

  const { specialtyItemsWithIcon, otherSpecialties } = getSpecialties(offer.specialty.specialties);

  return (
    <CardSectionNew>
      <PossibleAssignment otherSpecialties={otherSpecialties} specialtyItemsWithIcon={specialtyItemsWithIcon} />
    </CardSectionNew>
  );
};

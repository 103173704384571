import {
  IconMapCar16,
  IconSocialLikeOn16,
  IconMapPin16,
  IconInfrastructureStore16,
  IconPlaceholderUser16,
  IconMapWalk16,
  IconFinanceWallet16,
  IconInfrastructureCommercialObject16,
} from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

export interface IReportFeature {
  Icon: JSX.Element;
  text: string;
}

export const REPORT_FEATURES: IReportFeature[] = [
  {
    Icon: <IconPlaceholderUser16 />,
    text: 'Охват населения',
  },
  {
    Icon: <IconMapWalk16 />,
    text: 'Пешеходный трафик',
  },
  {
    Icon: <IconMapCar16 />,
    text: 'Автомобильный трафик',
  },
  {
    Icon: <IconFinanceWallet16 />,
    text: 'Средний бюджет семьи по району',
  },
  {
    Icon: <IconInfrastructureCommercialObject16 />,
    text: 'Арендные ставки рядом',
  },
  {
    Icon: <IconMapPin16 />,
    text: 'Точки притяжения',
  },
  {
    Icon: <IconInfrastructureStore16 />,
    text: 'Конкуренты в радиусе 1 км',
  },
  {
    Icon: <IconSocialLikeOn16 />,
    text: 'Рекомендации по выбору места для бизнеса',
  },
];

/* eslint-disable */
//tslint:disable

import { IBusinessShoppingCenterOffersSchema } from '../business_shopping_center_offers/BusinessShoppingCenterOffersSchema';
import { IBusinessShoppingCenterCorpusSchema } from './BusinessShoppingCenterCorpusSchema';
import { ICranageTypeSchema } from './CranageTypeSchema';
import { IBscDeveloperSchema } from './BscDeveloperSchema';
import { IBscElectricitySchema } from './BscElectricitySchema';
import { IBscGeoSchema } from './BscGeoSchema';
import { IInfrastructureSchema } from './InfrastructureSchema';
import { IBscLandSchema } from './BscLandSchema';
import { ILiftTypeSchema } from './LiftTypeSchema';
import { IManagementCompanySchema } from './ManagementCompanySchema';
import { IOfficialRepresentativeAgentSchema } from './OfficialRepresentativeAgentSchema';
import { IOfficialRepresentativeCompanySchema } from './OfficialRepresentativeCompanySchema';
import { IBscOpeningHoursSchema } from './BscOpeningHoursSchema';
import { IBscParkingSchema } from '../parking/BscParkingSchema';
import { IBscPhoneSchema } from './BscPhoneSchema';
import { IBscPhotoSchema } from './BscPhotoSchema';
import { IRatesSchema } from './RatesSchema';
import { IBscTenantsSchema } from './BscTenantsSchema';

export interface IBusinessShoppingCenterSchema {
  /** Доступ **/
  accessType?: EAccessType | null;
  /** Список дополнительно оплачиваемых услуг **/
  additionalCharges?: EAdditionalCharges[] | null;
  /** Альтернативное название **/
  altName?: string | null;
  /** Название для автокомплита **/
  autocompleteName?: string | null;
  /** Год постройки **/
  buildYear?: number | null;
  /** Класс здания **/
  buildingClassType?: EBuildingClassType | null;
  /** Статус здания **/
  buildingStatusType?: EBuildingStatusType | null;
  /** Тип здания **/
  buildingType?: EBuildingType | null;
  /** Объявления Бизнеса/торговый центра **/
  businessShoppingCenterOffers?: IBusinessShoppingCenterOffersSchema | null;
  /** Высота потолков, м **/
  ceilingHeight?: string | null;
  /** Сетка колонн **/
  columnGrid?: string | null;
  /** Кондиционирование **/
  conditioningType?: EConditioningType | null;
  /** Корпус **/
  corpuses?: IBusinessShoppingCenterCorpusSchema[] | null;
  /** Крановое оборудование **/
  cranageTypes?: ICranageTypeSchema[] | null;
  /** Дата добавления **/
  createdDate?: string | null;
  /** Описание **/
  description?: string | null;
  /** Застройщик **/
  developers?: IBscDeveloperSchema[] | null;
  /** Электроснабжение **/
  electricity?: IBscElectricitySchema | null;
  /** Кол-во входов **/
  entranceCount?: number | null;
  /** Кол-во эскалаторов **/
  escalatorCount?: number | null;
  /** Система пожаротушения **/
  extinguishingSystemType?: EExtinguishingSystemType | null;
  /** Материал перекрытий **/
  floorMaterialType?: EFloorMaterialType | null;
  /** Материал покрытия пола **/
  floorType?: EFloorType | null;
  /** Количество этажей от **/
  floorsFrom?: number | null;
  /** Количество этажей до **/
  floorsTo?: number | null;
  /** Тип ворот **/
  gatesType?: EGatesType | null;
  /** Гео **/
  geo?: IBscGeoSchema | null;
  /** Есть официальные представители **/
  hasOfficialRepresentatives?: boolean | null;
  /** Есть официальные представители компании **/
  hasOfficialRepresentativesCompanies?: boolean | null;
  /** Есть объявления **/
  hasProps?: boolean | null;
  /** Отопление **/
  heatingType?: EHeatingType | null;
  /** ID объекта **/
  id?: number | null;
  /** Инфраструктура **/
  infrastructure?: IInfrastructureSchema | null;
  /** Вход **/
  inputType?: EInputType | null;
  /** Участок **/
  land?: IBscLandSchema | null;
  /** Дата последнего добавления объявления **/
  lastOfferAddedTimestamp?: number | null;
  /** Лифт **/
  liftTypes?: ILiftTypeSchema[] | null;
  /** Управляющая компания **/
  managementCompanies?: IManagementCompanySchema[] | null;
  /** Материал здания **/
  materialType?: EMaterialType | null;
  /** Название **/
  name?: string | null;
  /** Кол-во привязанных объявлений без учета корпусов **/
  offersCount?: number | null;
  /** Кол-во привязанных объявлений в аренде **/
  offersRentCount?: number | null;
  /** Ссылка на объявления в аренде **/
  offersRentLink?: string | null;
  /** Максимальная площадь помещений, м2 в аренде **/
  offersRentMaxArea?: string | null;
  /** Минимальная площадь помещений, м2 в аренде **/
  offersRentMinArea?: string | null;
  /** Кол-во привязанных объявлений в продаже **/
  offersSaleCount?: number | null;
  /** Ссылка на объявления в продаже **/
  offersSaleLink?: string | null;
  /** Максимальная площадь помещений, м2 в продаже **/
  offersSaleMaxArea?: string | null;
  /** Минимальная площадь помещений, м2 в продаже **/
  offersSaleMinArea?: string | null;
  /** Официальные представитель - агент **/
  officialRepresentativesAgents?: IOfficialRepresentativeAgentSchema[] | null;
  /** Официальные представитель - компания **/
  officialRepresentativesCompanies?: IOfficialRepresentativeCompanySchema[] | null;
  /** Кол-во привязанных объявлений от оф. преда (включая корпуса) **/
  offrepPropsCount?: number | null;
  /** Площадь открытого участка (площадка рядом с зданием, где можно сложить груз), м^2 **/
  openPlatformArea?: number | null;
  /** Режим работы **/
  openingHours?: IBscOpeningHoursSchema[] | null;
  /** Тип здания основного здания **/
  parentBuildingType?: EParentBuildingType | null;
  /** Гео основного здания **/
  parentGeo?: IBscGeoSchema | null;
  /** ID основного объекта (для корпусов) **/
  parentId?: number | null;
  /** Название основного здания **/
  parentName?: string | null;
  /** URL на карточку основного ТЦ/БЦ **/
  parentUrl?: string | null;
  /** Комментарий к парковке **/
  parkingDetails?: string | null;
  /** Парковка **/
  parkings?: IBscParkingSchema[] | null;
  /** Телефон **/
  phones?: IBscPhoneSchema[] | null;
  /** Фотография объекта **/
  photos?: IBscPhotoSchema[] | null;
  /** Кол-во привязанных объявлений **/
  propsCount?: number | null;
  /** Ставки **/
  rates?: IRatesSchema | null;
  /** Арендопригодная площадь, м^2 **/
  rentableArea?: number | null;
  /** Состояние здания **/
  repairStateType?: ERepairStateType | null;
  /** Масштаб торгового центра **/
  shoppingCenterScaleType?: EShoppingCenterScaleType | null;
  /** Показывать в поисковой выдаче **/
  showInSerp?: boolean | null;
  /** Сайт **/
  siteUrl?: string | null;
  /** Тип объекта **/
  status?: EStatus | null;
  /** Температурный режим **/
  temperatureMode?: ETemperatureMode | null;
  /** Арендаторы **/
  tenants?: IBscTenantsSchema | null;
  /** Общая площадь, м2 **/
  totalArea?: string | null;
  /** Кол-во пассажирских и грузовых лифтов **/
  totalLiftCount?: number | null;
  /** Кол-во траволаторов **/
  travelatorCount?: number | null;
  /** Тип объекта **/
  type?: EType | null;
  /** Список типов объекта **/
  types?: ETypes[] | null;
  /** Дата обновления **/
  updatedDate?: string | null;
  /** URL на карточку ТЦ/БЦ **/
  url?: string | null;
  /** Использовать название основного объекта **/
  useParentName?: boolean | null;
  /** Вентиляция **/
  ventilationType?: EVentilationType | null;
}

export enum EAccessType {
  /** Свободный **/
  Free = 'free',
  /** Пропускная система **/
  PassSystem = 'passSystem',
}
export enum EAdditionalCharges {
  /** Уборка **/
  Cleaning = 'cleaning',
  /** Коммунальные платежи **/
  CommunalPayments = 'communalPayments',
  /** Электричество **/
  Electricity = 'electricity',
  /** Интернет **/
  Internet = 'internet',
}
export enum EBuildingClassType {
  /** A **/
  A = 'a',
  /** A+ **/
  APlus = 'aPlus',
  /** B **/
  B = 'b',
  /** B- **/
  BMinus = 'bMinus',
  /** B+ **/
  BPlus = 'bPlus',
  /** C **/
  C = 'c',
  /** C+ **/
  CPlus = 'cPlus',
  /** D **/
  D = 'd',
}
export enum EBuildingStatusType {
  /** Новостройка **/
  NewBuilding = 'newBuilding',
  /** Действующее **/
  Operational = 'operational',
  /** Проект **/
  Project = 'project',
  /** Вторичка **/
  Secondary = 'secondary',
  /** Строящееся **/
  UnderConstruction = 'underConstruction',
}
export enum EBuildingType {
  /** Административное здание **/
  AdministrativeBuilding = 'administrativeBuilding',
  /** Бизнес-центр **/
  BusinessCenter = 'businessCenter',
  /** Деловой центр **/
  BusinessCenter2 = 'businessCenter2',
  /** Деловой дом **/
  BusinessHouse = 'businessHouse',
  /** Бизнес-парк **/
  BusinessPark = 'businessPark',
  /** Бизнес-квартал **/
  BusinessQuarter = 'businessQuarter',
  /** Деловой квартал **/
  BusinessQuarter2 = 'businessQuarter2',
  /** Свободное **/
  Free = 'free',
  /** Объект свободного назначения **/
  FreeAppointmentObject = 'freeAppointmentObject',
  /** Производственный комплекс **/
  IndustrialComplex = 'industrialComplex',
  /** Индустриальный парк **/
  IndustrialPark = 'industrialPark',
  /** Промплощадка **/
  IndustrialSite = 'industrialSite',
  /** Производственно-складской комплекс **/
  IndustrialWarehouseComplex = 'industrialWarehouseComplex',
  /** Логистический центр **/
  LogisticsCenter = 'logisticsCenter',
  /** Логистический комплекс **/
  LogisticsComplex = 'logisticsComplex',
  /** Логистический парк **/
  LogisticsPark = 'logisticsPark',
  /** Особняк **/
  Mansion = 'mansion',
  /** Производственное здание **/
  ManufactureBuilding = 'manufactureBuilding',
  /** Производственный цех **/
  ManufacturingFacility = 'manufacturingFacility',
  /** Модульное здание **/
  Modular = 'modular',
  /** Многофункциональный комплекс **/
  MultifunctionalComplex = 'multifunctionalComplex',
  /** Офисно-гостиничный комплекс **/
  OfficeAndHotelComplex = 'officeAndHotelComplex',
  /** Офисно-жилой комплекс **/
  OfficeAndResidentialComplex = 'officeAndResidentialComplex',
  /** Офисно-складское **/
  OfficeAndWarehouse = 'officeAndWarehouse',
  /** Офисно-складской комплекс **/
  OfficeAndWarehouseComplex = 'officeAndWarehouseComplex',
  /** Офисное здание **/
  OfficeBuilding = 'officeBuilding',
  /** Офисный центр **/
  OfficeCenter = 'officeCenter',
  /** Офисный комплекс **/
  OfficeComplex = 'officeComplex',
  /** Офисно-производственный комплекс **/
  OfficeIndustrialComplex = 'officeIndustrialComplex',
  /** Офисный квартал **/
  OfficeQuarter = 'officeQuarter',
  /** Старый фонд **/
  Old = 'old',
  /** Другое **/
  Other = 'other',
  /** Аутлет **/
  Outlet = 'outlet',
  /** Имущественный комплекс **/
  PropertyComplex = 'propertyComplex',
  /** Жилой комплекс **/
  ResidentialComplex = 'residentialComplex',
  /** Жилой фонд **/
  ResidentialFund = 'residentialFund',
  /** Жилой дом **/
  ResidentialHouse = 'residentialHouse',
  /** Торгово-деловой комплекс **/
  ShoppingAndBusinessComplex = 'shoppingAndBusinessComplex',
  /** Торгово-общественный центр **/
  ShoppingAndCommunityCenter = 'shoppingAndCommunityCenter',
  /** Торгово-развлекательный центр **/
  ShoppingAndEntertainmentCenter = 'shoppingAndEntertainmentCenter',
  /** Торгово-складской комлекс **/
  ShoppingAndWarehouseComplex = 'shoppingAndWarehouseComplex',
  /** Торговый центр **/
  ShoppingCenter = 'shoppingCenter',
  /** Торговый комплекс **/
  ShoppingComplex = 'shoppingComplex',
  /** Специализированный торговый центр **/
  SpecializedShoppingCenter = 'specializedShoppingCenter',
  /** Отдельно стоящее здание **/
  StandaloneBuilding = 'standaloneBuilding',
  /** Технопарк **/
  Technopark = 'technopark',
  /** Торгово-выставочный комплекс **/
  TradeAndExhibitionComplex = 'tradeAndExhibitionComplex',
  /** Торговый дом **/
  TradingHouse = 'tradingHouse',
  /** Торгово-офисный комплекс **/
  TradingOfficeComplex = 'tradingOfficeComplex',
  /** Нежилой фонд **/
  UninhabitedFund = 'uninhabitedFund',
  /** Склад **/
  Warehouse = 'warehouse',
  /** Складской комплекс **/
  WarehouseComplex = 'warehouseComplex',
}
export enum EConditioningType {
  /** Центральное **/
  Central = 'central',
  /** Местное **/
  Local = 'local',
  /** Нет **/
  No = 'no',
}
export enum EExtinguishingSystemType {
  /** Сигнализация **/
  Alarm = 'alarm',
  /** Газовая **/
  Gas = 'gas',
  /** Гидрантная **/
  Hydrant = 'hydrant',
  /** Нет **/
  No = 'no',
  /** Порошковая **/
  Powder = 'powder',
  /** Спринклерная **/
  Sprinkler = 'sprinkler',
}
export enum EFloorMaterialType {
  /** Кирпич **/
  Brick = 'brick',
  /** Смешанный **/
  Hybrid = 'hybrid',
  /** Крупноблочные **/
  LargeBlock = 'largeBlock',
  /** Металлические **/
  Metal = 'metal',
  /** Монолитный каркас с наружными кирпичными стенами **/
  MonolithicFrameWithBrickExternalWalls = 'monolithicFrameWithBrickExternalWalls',
  /** Из прочих материалов **/
  Other = 'other',
  /** Панель **/
  Panel = 'panel',
  /** Железобетон **/
  ReinforcedConcrete = 'reinforcedConcrete',
  /** Cэндвич-панели **/
  SandwichPanels = 'sandwichPanels',
  /** Каркасно-обшивные **/
  SkeletonSheathed = 'skeletonSheathed',
  /** Стальная конструкция **/
  Steel = 'steel',
  /** Дерево **/
  Wood = 'wood',
}
export enum EFloorType {
  /** Асфальт **/
  Asphalt = 'asphalt',
  /** Бетон **/
  Concrete = 'concrete',
  /** Ламинат **/
  Laminate = 'laminate',
  /** Линолеум **/
  Linoleum = 'linoleum',
  /** Полимерный **/
  Polymeric = 'polymeric',
  /** Железобетон **/
  ReinforcedConcrete = 'reinforcedConcrete',
  /** Наливной пол **/
  SelfLeveling = 'selfLeveling',
  /** Плитка **/
  Tile = 'tile',
  /** Дерево **/
  Wood = 'wood',
}
export enum EGatesType {
  /** На нулевой отметке **/
  AtZero = 'atZero',
  /** Докового типа **/
  DockType = 'dockType',
  /** На пандусе **/
  OnRamp = 'onRamp',
}
export enum EHeatingType {
  /** Автономное **/
  Autonomous = 'autonomous',
  /** Автономное газовое **/
  AutonomousGas = 'autonomousGas',
  /** Котел **/
  Boiler = 'boiler',
  /** Центральное **/
  Central = 'central',
  /** Центральное угольное **/
  CentralCoal = 'centralCoal',
  /** Центральное газовое **/
  CentralGas = 'centralGas',
  /** Дизельное **/
  Diesel = 'diesel',
  /** Электрическое **/
  Electric = 'electric',
  /** Камин **/
  Fireplace = 'fireplace',
  /** Нет **/
  No = 'no',
  /** Другое **/
  Other = 'other',
  /** Твердотопливный котел **/
  SolidFuelBoiler = 'solidFuelBoiler',
  /** Печь **/
  Stove = 'stove',
}
export enum EInputType {
  /** Общий с улицы **/
  CommonFromTheStreet = 'commonFromTheStreet',
  /** Общий со двора **/
  CommonFromTheYard = 'commonFromTheYard',
  /** Отдельный с улицы **/
  SeparateFromTheStreet = 'separateFromTheStreet',
  /** Отдельный со двора **/
  SeparateFromTheYard = 'separateFromTheYard',
}
export enum EMaterialType {
  /** Кирпич **/
  Brick = 'brick',
  /** Смешанный **/
  Hybrid = 'hybrid',
  /** Крупноблочные **/
  LargeBlock = 'largeBlock',
  /** Металлические **/
  Metal = 'metal',
  /** Монолитный каркас с наружными кирпичными стенами **/
  MonolithicFrameWithBrickExternalWalls = 'monolithicFrameWithBrickExternalWalls',
  /** Из прочих материалов **/
  Other = 'other',
  /** Панель **/
  Panel = 'panel',
  /** Железобетон **/
  ReinforcedConcrete = 'reinforcedConcrete',
  /** Cэндвич-панели **/
  SandwichPanels = 'sandwichPanels',
  /** Каркасно-обшивные **/
  SkeletonSheathed = 'skeletonSheathed',
  /** Стальная конструкция **/
  Steel = 'steel',
  /** Дерево **/
  Wood = 'wood',
}
export enum EParentBuildingType {
  /** Административное здание **/
  AdministrativeBuilding = 'administrativeBuilding',
  /** Бизнес-центр **/
  BusinessCenter = 'businessCenter',
  /** Деловой центр **/
  BusinessCenter2 = 'businessCenter2',
  /** Деловой дом **/
  BusinessHouse = 'businessHouse',
  /** Бизнес-парк **/
  BusinessPark = 'businessPark',
  /** Бизнес-квартал **/
  BusinessQuarter = 'businessQuarter',
  /** Деловой квартал **/
  BusinessQuarter2 = 'businessQuarter2',
  /** Свободное **/
  Free = 'free',
  /** Объект свободного назначения **/
  FreeAppointmentObject = 'freeAppointmentObject',
  /** Производственный комплекс **/
  IndustrialComplex = 'industrialComplex',
  /** Индустриальный парк **/
  IndustrialPark = 'industrialPark',
  /** Промплощадка **/
  IndustrialSite = 'industrialSite',
  /** Производственно-складской комплекс **/
  IndustrialWarehouseComplex = 'industrialWarehouseComplex',
  /** Логистический центр **/
  LogisticsCenter = 'logisticsCenter',
  /** Логистический комплекс **/
  LogisticsComplex = 'logisticsComplex',
  /** Логистический парк **/
  LogisticsPark = 'logisticsPark',
  /** Особняк **/
  Mansion = 'mansion',
  /** Производственное здание **/
  ManufactureBuilding = 'manufactureBuilding',
  /** Производственный цех **/
  ManufacturingFacility = 'manufacturingFacility',
  /** Модульное здание **/
  Modular = 'modular',
  /** Многофункциональный комплекс **/
  MultifunctionalComplex = 'multifunctionalComplex',
  /** Офисно-гостиничный комплекс **/
  OfficeAndHotelComplex = 'officeAndHotelComplex',
  /** Офисно-жилой комплекс **/
  OfficeAndResidentialComplex = 'officeAndResidentialComplex',
  /** Офисно-складское **/
  OfficeAndWarehouse = 'officeAndWarehouse',
  /** Офисно-складской комплекс **/
  OfficeAndWarehouseComplex = 'officeAndWarehouseComplex',
  /** Офисное здание **/
  OfficeBuilding = 'officeBuilding',
  /** Офисный центр **/
  OfficeCenter = 'officeCenter',
  /** Офисный комплекс **/
  OfficeComplex = 'officeComplex',
  /** Офисно-производственный комплекс **/
  OfficeIndustrialComplex = 'officeIndustrialComplex',
  /** Офисный квартал **/
  OfficeQuarter = 'officeQuarter',
  /** Старый фонд **/
  Old = 'old',
  /** Другое **/
  Other = 'other',
  /** Аутлет **/
  Outlet = 'outlet',
  /** Имущественный комплекс **/
  PropertyComplex = 'propertyComplex',
  /** Жилой комплекс **/
  ResidentialComplex = 'residentialComplex',
  /** Жилой фонд **/
  ResidentialFund = 'residentialFund',
  /** Жилой дом **/
  ResidentialHouse = 'residentialHouse',
  /** Торгово-деловой комплекс **/
  ShoppingAndBusinessComplex = 'shoppingAndBusinessComplex',
  /** Торгово-общественный центр **/
  ShoppingAndCommunityCenter = 'shoppingAndCommunityCenter',
  /** Торгово-развлекательный центр **/
  ShoppingAndEntertainmentCenter = 'shoppingAndEntertainmentCenter',
  /** Торгово-складской комлекс **/
  ShoppingAndWarehouseComplex = 'shoppingAndWarehouseComplex',
  /** Торговый центр **/
  ShoppingCenter = 'shoppingCenter',
  /** Торговый комплекс **/
  ShoppingComplex = 'shoppingComplex',
  /** Специализированный торговый центр **/
  SpecializedShoppingCenter = 'specializedShoppingCenter',
  /** Отдельно стоящее здание **/
  StandaloneBuilding = 'standaloneBuilding',
  /** Технопарк **/
  Technopark = 'technopark',
  /** Торгово-выставочный комплекс **/
  TradeAndExhibitionComplex = 'tradeAndExhibitionComplex',
  /** Торговый дом **/
  TradingHouse = 'tradingHouse',
  /** Торгово-офисный комплекс **/
  TradingOfficeComplex = 'tradingOfficeComplex',
  /** Нежилой фонд **/
  UninhabitedFund = 'uninhabitedFund',
  /** Склад **/
  Warehouse = 'warehouse',
  /** Складской комплекс **/
  WarehouseComplex = 'warehouseComplex',
}
export enum ERepairStateType {
  /** После реконструкции **/
  AfterReconstruction = 'afterReconstruction',
  /** Нужен косметический ремонт **/
  CosmeticRepairsRequired = 'cosmeticRepairsRequired',
  /** Нужен капитальный ремонт **/
  MajorRepairsRequired = 'majorRepairsRequired',
  /** Необходима реконструкция **/
  NeedsReconstruction = 'needsReconstruction',
  /** Новое **/
  New = 'new',
  /** Рабочее **/
  WorkingCondition = 'workingCondition',
}
export enum EShoppingCenterScaleType {
  /** Районный **/
  District = 'district',
  /** Микрорайонный **/
  Microdistrict = 'microdistrict',
  /** Окружной **/
  Okrug = 'okrug',
  /** Региональный **/
  Regional = 'regional',
  /** Суперокружной **/
  SuperOkrug = 'superOkrug',
  /** Суперрегиональный **/
  SuperRegional = 'superRegional',
}
export enum EStatus {
  /** Проверяется **/
  Check = 'check',
  /** Удален **/
  Deleted = 'deleted',
  /** Черновик **/
  Draft = 'draft',
  /** Истек **/
  Expired = 'expired',
  /** Новый **/
  New = 'new',
  /** Опубликован **/
  Published = 'published',
  /** Отозван **/
  Withdrawn = 'withdrawn',
}
export enum ETemperatureMode {
  /** Холодный **/
  Cool = 'cool',
  /** Морозильный **/
  Freezing = 'freezing',
  /** Теплый **/
  Warm = 'warm',
}
export enum EType {
  /** Бизнес-центр **/
  BusinessCenter = 'businessCenter',
  /** Торговый центр **/
  ShoppingCenter = 'shoppingCenter',
  /** Складской комплекс **/
  Warehouse = 'warehouse',
}
export enum ETypes {
  /** Бизнес-центр **/
  BusinessCenter = 'businessCenter',
  /** Торговый центр **/
  ShoppingCenter = 'shoppingCenter',
  /** Складской комплекс **/
  Warehouse = 'warehouse',
}
export enum EVentilationType {
  /** Приточная **/
  Forced = 'forced',
  /** Естественная **/
  Natural = 'natural',
  /** Нет **/
  No = 'no',
}

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeBestPlaceModal } from 'shared/actions/bestPlaceReportModal';
import { BestPlaceReportModal } from 'shared/components/BestPlaceAnalytics/components/BestPlaceReportModal';
import { FeedbackFormSuccessScreen } from 'shared/components/BestPlaceAnalytics/components/FeedbackFormSuccessScreen';
import { CheckEmailContainer } from 'shared/components/BestPlaceAnalytics/containers/CheckEmailContainer';
import { FeedbackFormContainer } from 'shared/components/BestPlaceAnalytics/containers/FeedbackFormContainer';
import { PurchaseFormContainer } from 'shared/components/BestPlaceAnalytics/containers/PurchaseFormContainer';
import { StatusBarContainer } from 'shared/components/BestPlaceAnalytics/containers/StatusBarContainer';
import { selectStatus } from 'shared/selectors/bestPlaceAnalytics';
import { selectBestPlaceReportModalOpened } from 'shared/selectors/bestPlaceAnalytics/selectBestPlaceReportModalOpened';
import { EBestPlaceReportStatus } from 'shared/types/bestPlaceAnalytics';

import { useInitBestPlaceReportEmailContext } from './internal/hooks/useInitBestPlaceReportEmailContext';

export const BestPlaceReportModalContainer: React.FC = () => {
  const dispatch = useDispatch();
  const isOpened = useSelector(selectBestPlaceReportModalOpened);
  const bestPlaceReportStatus = useSelector(selectStatus);

  const { context: BestPlaceReportEmailContext, contextValue: bestPlaceReportEmailContext } =
    useInitBestPlaceReportEmailContext();

  const onClose = React.useCallback(() => {
    bestPlaceReportEmailContext.setReportEmail('');
    dispatch(closeBestPlaceModal());
  }, [dispatch, bestPlaceReportEmailContext]);

  const modalContent = React.useMemo(() => {
    switch (bestPlaceReportStatus) {
      case EBestPlaceReportStatus.CheckEmail: {
        return <CheckEmailContainer />;
      }

      case EBestPlaceReportStatus.ReportPaid: {
        return <FeedbackFormContainer />;
      }

      case EBestPlaceReportStatus.FeedbackSent: {
        return <FeedbackFormSuccessScreen />;
      }

      default:
        return (
          <>
            <PurchaseFormContainer />
            <StatusBarContainer />
          </>
        );
    }
  }, [bestPlaceReportStatus]);

  return (
    <BestPlaceReportEmailContext.Provider value={bestPlaceReportEmailContext}>
      <BestPlaceReportModal onClose={onClose} isOpened={isOpened} modalContent={modalContent} />
    </BestPlaceReportEmailContext.Provider>
  );
};

import { TControlOptions } from '@cian/ui-kit';

export const USER_TYPE_OPTIONS: TControlOptions = [
  {
    value: 'agent',
    label: 'Агент',
  },
  {
    value: 'owner',
    label: 'Собственник помещения',
  },
  {
    value: 'businessOwner',
    label: 'Владелец бизнеса',
  },
];

export const REPORT_TARGET_OPTIONS: TControlOptions = [
  {
    value: 'forMyself',
    label: 'Для себя',
  },
  {
    value: 'forMyCustomers',
    label: 'Для клиента',
  },
];

export const ANALYTICS_FREQUENCY_OPTIONS: TControlOptions = [
  {
    value: 'week',
    label: 'Каждую неделю',
  },
  {
    value: 'month',
    label: '1-2 раза в месяц',
  },
  {
    value: 'quarter',
    label: '1-2 раза в квартал',
  },
  {
    value: 'year',
    label: '1-2 раза в год',
  },
];

export const FORM_FIELDS = {
  userType: 'userType',
  reportTarget: 'reportTarget',
  analyticsFrequency: 'analyticsFrequency',
  businessType: 'businessType',
} as const;

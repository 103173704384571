import { getGlobalEventsLogs } from '@cian/events-log';

export function callAuthPopup(): void {
  const globalLog = getGlobalEventsLogs();

  globalLog.produce('user', {
    type: 'authenticate',
    value: {
      autoRefresh: true,
      viewData: { headingType: 'informationPermission' },
    },
  });
}

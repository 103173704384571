import { locales } from '../../../../components/commercial/locales';
import { IOption, Option } from '../../../../models/Option';
import { IBuildingSchema } from '../../../../repositories/offer-card/entities/building/BuildingSchema';
import { IOptionFacade } from '../../IOptionFacade';

export class WorkingDaysTypeOptionFacade implements IOptionFacade<string, string> {
  protected readonly building: IBuildingSchema | null;

  public constructor(building: IBuildingSchema | null) {
    this.building = building;
  }

  public getOption(): IOption<string, string> | null {
    const value = this.value;

    if (!value) {
      return null;
    }

    return new Option(this.label, value, this.key);
  }

  protected get value(): string | null {
    if (this.building?.workingDaysType == null) {
      return null;
    }

    return locales.workingDaysType[this.building.workingDaysType];
  }

  protected get label(): string {
    return 'Дни работы';
  }

  protected get key(): string {
    return 'workingDaysType';
  }
}

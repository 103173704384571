/**
 * @todo Удалить файл с экспериментом
 * @description Данный функционал появился в задаче CD-129223, будет удалён в задаче CD-129224
 * Удалить, если эксперимент будет не удачным
 */

import { createSelector } from '@reduxjs/toolkit';

import { offerDataSelector, selectIsAuthenticated } from './offerData';

/**
 * @deprecated
 * @todo Удалить комментарий с экспериментом
 * @description Данный функционал появился в задаче CD-129223, будет удалён в задаче CD-129224
 * Удалить если эксперимент будет удачным
 */
export const disablePhoneButtonSelector = createSelector(
  [selectIsAuthenticated, offerDataSelector],
  (isAuthenticated, offerData) => {
    // @ts-ignore TODO: Свойство больше не генерируется
    return !isAuthenticated && offerData.userPhoneShowStatus?.canViewPhone === false;
  },
);

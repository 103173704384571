import { IOption, Option } from 'shared/models/Option';
import { EHeatingType, IBuildingSchema } from 'shared/repositories/offer-card/entities/building/BuildingSchema';
import { IBusinessShoppingCenterSchema } from 'shared/repositories/offer-card/entities/business_shopping_center/BusinessShoppingCenterSchema';
import { IOptionFacade } from 'shared/services/OptionFacade';

export class HeatingTypeOptionFacade implements IOptionFacade<string, string> {
  protected readonly building: IBuildingSchema | null;
  protected readonly businessShoppingCenter: IBusinessShoppingCenterSchema | null;

  public constructor(building: IBuildingSchema | null, businessShoppingCenter: IBusinessShoppingCenterSchema | null) {
    this.building = building;
    this.businessShoppingCenter = businessShoppingCenter;
  }

  public getOption(): IOption<string, string> | null {
    const value = this.value;

    if (!value) {
      return null;
    }

    return new Option(this.label, value, this.key);
  }

  protected get value(): string | null {
    const heatingType = this.building?.heatingType ?? this.businessShoppingCenter?.heatingType;

    switch (heatingType) {
      case EHeatingType.Autonomous:
        return 'Автономное';
      case EHeatingType.Boiler:
        return 'Котел';
      case EHeatingType.CentralCoal:
        return 'Центральное угольное';
      case EHeatingType.CentralGas:
        return 'Центральное газовое';
      case EHeatingType.Fireplace:
        return 'Камин';
      case EHeatingType.Other:
        return 'Другое';
      case EHeatingType.Stove:
        return 'Печь';
      case EHeatingType.Central:
        return 'Центральное';
      case EHeatingType.Electric:
        return 'Электрическое';
      case EHeatingType.AutonomousGas:
        return 'Автономное газовое';
      case EHeatingType.Diesel:
        return 'Дизельное';
      case EHeatingType.SolidFuelBoiler:
        return 'Твердотопливный котел';
      default:
        return null;
    }
  }

  protected get label(): string {
    return 'Отопление';
  }

  protected get key(): string {
    return 'heatingType';
  }
}

import { ILabelsModel, LabelsModel } from 'shared/models/LabelsModel';
import { EOfferGroupType } from 'shared/repositories/offer-card/entities/business_shopping_center_offers/BusinessShoppingCenterOfferGroupSchema';

export function getOtherOfferLabelSingularFactory(offerGroupType: EOfferGroupType): ILabelsModel {
  switch (offerGroupType) {
    case EOfferGroupType.Office:
      return new LabelsModel('Офис');
    case EOfferGroupType.Building:
      return new LabelsModel('Здание');
    case EOfferGroupType.CommercialLand:
      return new LabelsModel('Коммерческая земля', 'Ком. земля');
    case EOfferGroupType.Business:
      return new LabelsModel('Готовый бизнес', 'Готов. бизнес');
    case EOfferGroupType.Coworking:
      return new LabelsModel('Коворкинг');
    case EOfferGroupType.Industry:
      return new LabelsModel('Производство');
    case EOfferGroupType.ShoppingArea:
      return new LabelsModel('Торговая площадь', 'Торг. площадь');
    case EOfferGroupType.Warehouse:
      return new LabelsModel('Склад');
    case EOfferGroupType.PublicCatering:
      return new LabelsModel('Общепит');
    case EOfferGroupType.FreeAppointmentObject:
      return new LabelsModel('Помещение свободного назначения', 'Своб. назнач.');
    case EOfferGroupType.CarService:
      return new LabelsModel('Автосервис');
    case EOfferGroupType.DomesticServices:
      return new LabelsModel('Бытовые услуги');
  }
}

import { IOption } from 'shared/models/Option';
import { IBuildingSchema } from 'shared/repositories/offer-card/entities/building/BuildingSchema';
import { IBusinessShoppingCenterSchema } from 'shared/repositories/offer-card/entities/business_shopping_center/BusinessShoppingCenterSchema';
import { IOfferSchema } from 'shared/repositories/offer-card/entities/offer/OfferSchema';
import {
  BuildingBuildYearOptionFacade,
  BuildingDeveloperFacade,
  BuildingTypeOptionFacade,
  ConditioningTypeOptionFacade,
  ExtinguishingSystemTypeOptionFacade,
  HeatingTypeOptionFacade,
  ManagementCompanyOptionFacade,
  OpeningHoursOptionFacade,
  ShoppingCenterScaleTypeOptionFacade,
  StatusTypeOptionFacade,
  TotalAreaOptionFacade,
  TotalLiftsCountOptionFacade,
  VentilationTypeOptionFacade,
  WorkingDaysTypeOptionFacade,
} from 'shared/services/OptionFacade';
import { OptionsFacadeService } from 'shared/services/OptionsFacadeService';

export function getTCBCParams(
  building: IBuildingSchema | null,
  businessShoppingCenter: IBusinessShoppingCenterSchema,
  offer: IOfferSchema,
): Array<IOption<string, string | number>> {
  const optionsFacadeService = new OptionsFacadeService<string, string | number>([]);

  optionsFacadeService.pushOptionsIfNonNullable(
    new BuildingBuildYearOptionFacade(building, businessShoppingCenter),
    new BuildingDeveloperFacade(building, businessShoppingCenter),
    new BuildingTypeOptionFacade(building, businessShoppingCenter),
    new ManagementCompanyOptionFacade(building, businessShoppingCenter),
    new StatusTypeOptionFacade(building, businessShoppingCenter),
    new TotalAreaOptionFacade(offer, building, businessShoppingCenter),
    new ShoppingCenterScaleTypeOptionFacade(building, businessShoppingCenter),
    new TotalLiftsCountOptionFacade(building, businessShoppingCenter),
    new WorkingDaysTypeOptionFacade(building),
    new OpeningHoursOptionFacade(building, businessShoppingCenter),
    new VentilationTypeOptionFacade(building, businessShoppingCenter),
    new ConditioningTypeOptionFacade(building, businessShoppingCenter),
    new HeatingTypeOptionFacade(building, businessShoppingCenter),
    new ExtinguishingSystemTypeOptionFacade(building, businessShoppingCenter),
  );

  return optionsFacadeService.options;
}

import { ILabelsModel, LabelsModel } from 'shared/models/LabelsModel';
import { EOfferGroupType } from 'shared/repositories/offer-card/entities/business_shopping_center_offers/BusinessShoppingCenterOfferGroupSchema';

import { getOtherOfferLabelSingularFactory } from './getOtherOfferLabelSingularFactory';

export function getOtherOfferLabelPluralFactory(offerGroupType: EOfferGroupType): ILabelsModel {
  switch (offerGroupType) {
    case EOfferGroupType.Office:
      return new LabelsModel('Офисы');
    case EOfferGroupType.Building:
      return new LabelsModel('Здания');
    case EOfferGroupType.CommercialLand:
      return new LabelsModel('Коммерческие земли', 'Ком. земли');
    case EOfferGroupType.Coworking:
      return new LabelsModel('Коворкинги');
    case EOfferGroupType.Industry:
      return new LabelsModel('Производства', 'Произв.');
    case EOfferGroupType.ShoppingArea:
      return new LabelsModel('Торговые площади', 'Торг. площади');
    case EOfferGroupType.Warehouse:
      return new LabelsModel('Склады');
    case EOfferGroupType.FreeAppointmentObject:
      return new LabelsModel('Помещения свободного назначения', 'Своб. назнач.');
    case EOfferGroupType.CarService:
      return new LabelsModel('Автосервисы');
    case EOfferGroupType.Business:
    case EOfferGroupType.PublicCatering:
    case EOfferGroupType.DomesticServices:
      return getOtherOfferLabelSingularFactory(offerGroupType);
  }
}

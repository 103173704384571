/* istanbul ignore next */
import { IWorkTimeInfoSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

export function getAction(defaultAction: string, workTimeInfo?: IWorkTimeInfoSchema) {
  if (workTimeInfo) {
    return workTimeInfo.isAvailable ? defaultAction : `${defaultAction}_inactive`;
  } else {
    return defaultAction;
  }
}

import { UIHeading1, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './SuccessPaymentHeader.css';
import { ISuccessPaymentHeaderProps } from './types';

const bestPlaceSupportEmail = 'support-smb@bestplace.pro';

export const SuccessPaymentHeader: React.FC<ISuccessPaymentHeaderProps> = props => {
  const { reportUserEmail } = props;

  const emailPartial = (() => {
    return reportUserEmail ? (
      <>
        на&nbsp;<b>{reportUserEmail} </b>
      </>
    ) : null;
  })();

  return (
    <section className={styles['success-header']}>
      <div className={styles['header-title-container']}>
        <div className={styles['success-header-icon']}></div>
        <UIHeading1>Деньги пришли, спасибо</UIHeading1>
      </div>
      <UIText1>
        Вы&nbsp;получите отчёт {emailPartial} в&nbsp;течение 10&nbsp;минут. Если его долго не&nbsp;будет, проверьте
        папку &laquo;Спам&raquo;&nbsp;&mdash; иногда наши письма попадают туда.
        <br />
        Также отчёт доступен в личном кабинете, проверьте раздел уведомлений.
        <br />
        Если возникнут проблемы,&nbsp;
        <a className={styles['partner-link']} href={`mailto:${bestPlaceSupportEmail}`}>
          напишите нашему партнеру.
        </a>
      </UIText1>
      <div className={styles['hr']}></div>
    </section>
  );
};

SuccessPaymentHeader.displayName = 'SuccessPaymentHeader';

import { Image } from '@cian/ui-kit';
import * as React from 'react';

import { VerticalSlider } from 'shared/components/VerticalSlider';

import { PHOTO_HEIGHT, PHOTO_WIDTH } from './internal/constants/photoConstants';
import { ICommercialTCBCPhotoGalleryProps } from './types';

export const CommercialTCBCPhotoGallery: React.FC<ICommercialTCBCPhotoGalleryProps> = props => {
  const { photos, onPhotoClick } = props;

  return (
    <VerticalSlider onItemClick={onPhotoClick}>
      {photos.map(photo => (
        <Image data-testid="photo" height={PHOTO_HEIGHT} key={photo.key} src={photo.url} width={PHOTO_WIDTH} />
      ))}
    </VerticalSlider>
  );
};

CommercialTCBCPhotoGallery.displayName = 'CommercialTCBCPhotoGallery';

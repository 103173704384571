import cx from 'clsx';
import * as React from 'react';

import {
  IBuildingSchema,
  IBusinessShoppingCenterSchema,
  IOfferSchema,
} from 'shared/types/api-models/offer-card/v1/get-offer-data';

import * as styles from './index.css';
import { AdditionalFeatureItem, IAdditionalFeatureItemProps } from './item';
import { isArrayWithItems } from '../../../utils';
import {
  getBuildingBuildYear,
  getBuildingDeveloper,
  getBuildingManagementCompany,
  getBuildingTotalArea,
  getShoppingCenterScaleType,
  getTotalLiftsCount,
} from '../../../utils/building';
import {
  getBuildingType,
  getLandArea,
  getLandStatusType,
  getOpeningHours,
  getStatusType,
} from '../../../utils/offer_helpers';
import { FOfferType, getOfferType, isCommercial } from '../../../utils/offer_type';
import { locales } from '../locales';

export interface ICommercialAboutOtherProps {
  building: IBuildingSchema | null;
  businessShoppingCenter: IBusinessShoppingCenterSchema | null;
  offer: IOfferSchema;
}

const IGNORED_COMMERCIAL_OFFER_TYPES = [FOfferType.Building, FOfferType.Business, FOfferType.CommercialLand];

export class CommercialAboutOther extends React.PureComponent<ICommercialAboutOtherProps, {}> {
  public render() {
    const offerType = getOfferType(this.props.offer);

    if (!isCommercial(offerType) || IGNORED_COMMERCIAL_OFFER_TYPES.includes(offerType)) {
      return null;
    }

    const params = this.getParams();

    if (!isArrayWithItems<IAdditionalFeatureItemProps>(params)) {
      return null;
    }

    const title = this.getTitle();

    return (
      <section className={cx([styles['container'], offerType === FOfferType.Garage && styles['with-delimiter']])}>
        {title && <h2 className={styles['title']}>{title}</h2>}
        <ul className={styles['list']}>
          {params.map(({ name, value }, index) => (
            <AdditionalFeatureItem
              className={styles['item']}
              key={`commercial-about-other-${index}`}
              name={name}
              value={value}
            />
          ))}
        </ul>
      </section>
    );
  }

  private getTitle(): string | undefined {
    const { businessShoppingCenter, building, offer } = this.props;

    const offerType = getOfferType(offer);

    if (offerType === FOfferType.Garage) {
      return 'О здании';
    }

    /* istanbul ignore next */
    if (!businessShoppingCenter && !building) {
      return undefined;
    }

    let title = '';

    /* istanbul ignore next */
    if (businessShoppingCenter) {
      const { name, parentName, useParentName } = businessShoppingCenter;

      const type = getBuildingType(building, businessShoppingCenter);

      if (type) {
        title += `${type} `;
      }

      if (parentName && useParentName) {
        if (name) {
          title += `${name} (${parentName})`;
        } else {
          title += parentName;
        }
      } else if (name) {
        title += name;
      }
    } else if (building && building.name) {
      title = building.name;
    }

    /* istanbul ignore next */
    return title || undefined;
  }

  private getParams(): Array<IAdditionalFeatureItemProps> {
    const { building, businessShoppingCenter, offer } = this.props;
    const params: Array<IAdditionalFeatureItemProps> = [];
    const offerType = getOfferType(offer);

    if (offerType === FOfferType.Garage && building && building.name) {
      params.push({
        name: 'Название ГСК',
        value: building.name,
      });

      return params;
    }

    const statusType = getStatusType(building, businessShoppingCenter);
    const landArea = getLandArea(offer, businessShoppingCenter);
    const landStatusType = getLandStatusType(offer, businessShoppingCenter);
    const buildingType = getBuildingType(building, businessShoppingCenter);
    const openingHours = getOpeningHours(building, businessShoppingCenter);
    const buildYear = getBuildingBuildYear(building, businessShoppingCenter);
    const developer = getBuildingDeveloper(building, businessShoppingCenter);
    const managementCompany = getBuildingManagementCompany(building, businessShoppingCenter);
    const shoppingCenterScaleType = getShoppingCenterScaleType(building, businessShoppingCenter);
    const totalLiftsCount = getTotalLiftsCount(building, businessShoppingCenter);
    const totalArea = getBuildingTotalArea(offer, building, businessShoppingCenter);

    if (buildYear) {
      params.push({
        name: 'Год постройки',
        value: buildYear,
      });
    }

    if (developer) {
      params.push({
        name: 'Девелопер',
        value: developer,
      });
    }

    if (buildingType) {
      params.push({
        name: 'Тип здания',
        value: buildingType,
      });
    }

    if (managementCompany) {
      params.push({
        name: 'Управляющая компания',
        value: managementCompany,
      });
    }

    if (statusType) {
      params.push({
        name: 'Категория здания',
        value: statusType,
      });
    }

    if (landArea) {
      params.push({
        name: 'Площадь участка',
        value: landArea,
      });
    }

    if (totalArea) {
      params.push({
        name: 'Общая площадь',
        value: totalArea,
      });
    }

    if (landStatusType) {
      params.push({
        name: 'Статус участка',
        value: landStatusType,
      });
    }

    if (building && building.houseLineType) {
      params.push({
        name: 'Линия домов',
        value: locales.houseLineType[building.houseLineType],
      });
    }

    if (shoppingCenterScaleType) {
      params.push({
        name: 'Масштаб торгового комплекса',
        value: locales.shoppingCenterScaleType[shoppingCenterScaleType],
      });
    }

    if (totalLiftsCount && offerType === FOfferType.TradeArea) {
      params.push({
        name: 'Лифты',
        value: totalLiftsCount,
      });
    }

    if (building && building.workingDaysType && offerType === FOfferType.TradeArea) {
      params.push({
        name: 'Дни работы',
        value: locales.workingDaysType[building.workingDaysType],
      });
    }

    if (openingHours && offerType === FOfferType.TradeArea) {
      params.push({
        name: 'Часы работы',
        value: openingHours,
      });
    }

    if (building && building.tenants && offerType === FOfferType.TradeArea) {
      params.push({
        name: 'Арендаторы',
        value: building.tenants,
      });
    }

    return params;
  }
}

import * as React from 'react';

import { ILinkButton } from 'shared/models/LinkButton';
import { IOfferSchema as DEPRECATED__IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';
import { getIsRentByOffer } from 'shared/utils/deal_type';
import { getCommercialAboutTCBCTitle } from 'shared/utils/tcbc/getCommercialAboutTCBCTitle';

import { trackTCBCFactory } from './internal/tracking/trackTCBCFactory';
import { getOtherOffersGroupsTitle } from './internal/utils/getOtherOffersGroupsTitle';
import { getPhotoGalleryTitle } from './internal/utils/getPhotoGalleryTitle';
import { getTCBCMoreDetailsButton } from './internal/utils/getTCBCMoreDetailsButton';
import { getTCBCOffersButton } from './internal/utils/getTCBCOffersButton';
import { getTCBCParams } from './internal/utils/getTCBCParams';
import { getTCBCPhotos } from './internal/utils/getTCBCPhotos';
import { onPhotoClick } from './internal/utils/onPhotoClick';
import { HandlePhotoClick, ICommercialAboutTCBCControllerProps, TcbcParams } from './types';
import { CommercialAboutTCBCView } from '../CommercialAboutTCBCView';

export const CommercialAboutTCBCController: React.FC<ICommercialAboutTCBCControllerProps> = props => {
  const { businessShoppingCenter, offer, agent } = props;
  const { building = null } = offer;

  const trackTCBC = React.useMemo(
    () => trackTCBCFactory(offer, businessShoppingCenter, agent),
    [agent, businessShoppingCenter, offer],
  );

  const tcbcParams = React.useMemo<TcbcParams>(
    () => getTCBCParams(building, businessShoppingCenter, offer),
    [building, businessShoppingCenter, offer],
  );

  const tcbcPhotos = React.useMemo(() => getTCBCPhotos(businessShoppingCenter), [businessShoppingCenter]);

  const photoGalleryTitle = getPhotoGalleryTitle(businessShoppingCenter.type);

  const moreDetailsButton = React.useMemo<ILinkButton | null>(
    () => getTCBCMoreDetailsButton(offer, businessShoppingCenter, agent),
    [agent, businessShoppingCenter, offer],
  );

  const tcbcOffersButton = React.useMemo<ILinkButton | null>(
    () => getTCBCOffersButton(offer, businessShoppingCenter, agent),
    [agent, businessShoppingCenter, offer],
  );

  const handlePhotoClick = React.useCallback<HandlePhotoClick>(() => {
    onPhotoClick(offer, businessShoppingCenter, agent);
  }, [agent, businessShoppingCenter, offer]);

  const handleShowAllOffersClick = React.useCallback<VoidFunction>(() => {
    trackTCBC('click', 'show_bc_announcements');
  }, [trackTCBC]);

  const handleListItemClick = React.useCallback<VoidFunction>(() => {
    trackTCBC('to_card', 'bc_announcements_list');
  }, [trackTCBC]);

  const commercialTCBCTitle = getCommercialAboutTCBCTitle(businessShoppingCenter, building);

  const otherOffersTitle = getOtherOffersGroupsTitle(businessShoppingCenter);

  const isRent = getIsRentByOffer(offer as unknown as DEPRECATED__IOfferSchema);

  if (!tcbcParams.length) {
    return null;
  }

  return (
    <CommercialAboutTCBCView
      isRent={isRent}
      moreDetailsButton={moreDetailsButton}
      photoGalleryTitle={photoGalleryTitle}
      photos={tcbcPhotos}
      tcbcInfoItems={tcbcParams}
      tcbcOffersButton={tcbcOffersButton}
      tcbcInfoTitle={commercialTCBCTitle}
      otherOffersTitle={otherOffersTitle}
      onPhotoClick={handlePhotoClick}
      businessShoppingCenterOffers={businessShoppingCenter.businessShoppingCenterOffers}
      onShowAllOffersClick={handleShowAllOffersClick}
      onListItemClick={handleListItemClick}
    />
  );
};

CommercialAboutTCBCController.displayName = 'CommercialAboutTCBCController';

import * as React from 'react';

import { BestPlaceReportEmailContext, IBestPlaceReportContextValue } from 'shared/context/bestPlaceReportEmailContext';

type TUseInitBestPlaceReportEmailContextReturnValue = {
  contextValue: IBestPlaceReportContextValue;
  context: React.Context<IBestPlaceReportContextValue>;
};

export function useInitBestPlaceReportEmailContext(): TUseInitBestPlaceReportEmailContextReturnValue {
  const [reportEmail, setReportEmail] = React.useState('');

  return React.useMemo(() => {
    const contextValue: IBestPlaceReportContextValue = { reportEmail, setReportEmail };

    return {
      contextValue,
      context: BestPlaceReportEmailContext,
    };
  }, [reportEmail]);
}

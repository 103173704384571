import {
  TConditioningType,
  TExtinguishingSystemType,
  THeatingType,
  TVentilationType,
} from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { ConditioningType, ExtinguishingSystemType, HeatingType, VentilationType } from '../store/offer';

export function getExtinguishingSystemType(type: TExtinguishingSystemType | null): string {
  if (!type || (type && type === ExtinguishingSystemType.No)) {
    return '';
  }

  const extinguishingSystemTypeName = {
    [ExtinguishingSystemType.Alarm]: 'Сигнализация',
    [ExtinguishingSystemType.Gas]: 'Газовая',
    [ExtinguishingSystemType.Hydrant]: 'Гидрантная',
    [ExtinguishingSystemType.Powder]: 'Порошковая',
    [ExtinguishingSystemType.Sprinkler]: 'Спринклерная',
  };

  if (type === ExtinguishingSystemType.Alarm) {
    return extinguishingSystemTypeName[type] ? extinguishingSystemTypeName[type] : '';
  }

  return extinguishingSystemTypeName[type] ? `${extinguishingSystemTypeName[type]} система пожаротушения` : '';
}

export function getHeatingType(type: THeatingType | null): string {
  if (!type || (type && type === HeatingType.No)) {
    return '';
  }

  const heatingTypeName = {
    [HeatingType.Autonomous]: 'Автономное',
    [HeatingType.Boiler]: 'Котел',
    [HeatingType.CentralCoal]: 'Центральное угольное',
    [HeatingType.CentralGas]: 'Центральное газовое',
    [HeatingType.Fireplace]: 'Камин',
    [HeatingType.Other]: 'Другое',
    [HeatingType.Stove]: 'Печь',
    [HeatingType.Central]: 'Центральное',
    [HeatingType.Electric]: 'Электрическое',
    [HeatingType.AutonomousGas]: 'Автономное газовое',
    [HeatingType.Diesel]: 'Дизельное',
    [HeatingType.SolidFuelBoiler]: 'Твердотопливный котел',
  };

  if ([HeatingType.Boiler, HeatingType.Fireplace, HeatingType.Other, HeatingType.Stove].includes(type)) {
    return heatingTypeName[type] ? heatingTypeName[type] : '';
  }

  return heatingTypeName[type] ? `${heatingTypeName[type]} отопление` : '';
}

export function getConditioningType(type: TConditioningType | null): string {
  if (!type || (type && type === ConditioningType.No)) {
    return '';
  }

  const conditioningTypeName = {
    [ConditioningType.Central]: 'Центральное',
    [ConditioningType.Local]: 'Местное',
  };

  return conditioningTypeName[type] ? `${conditioningTypeName[type]} кондиционирование` : '';
}

export function getVentilationType(type: TVentilationType | null): string {
  if (!type || (type && type === VentilationType.No)) {
    return '';
  }

  const ventilationTypeName = {
    [VentilationType.Forced]: 'Приточная',
    [VentilationType.Natural]: 'Естественная',
  };

  return ventilationTypeName[type] ? `${ventilationTypeName[type]} вентиляция` : '';
}

import {
  IBuildingSchema as DEPRECATED__IBuildingSchema,
  IBusinessShoppingCenterSchema as DEPRECATED__IBusinessShoppingCenterSchema,
} from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IOption, Option } from '../../../../models/Option';
import { IBuildingSchema } from '../../../../repositories/offer-card/entities/building/BuildingSchema';
import { IBusinessShoppingCenterSchema } from '../../../../repositories/offer-card/entities/business_shopping_center/BusinessShoppingCenterSchema';
import { getBuildingBuildYear } from '../../../../utils/building';
import { IOptionFacade } from '../../IOptionFacade';

export class BuildingBuildYearOptionFacade implements IOptionFacade<string, number> {
  protected readonly building: IBuildingSchema | null;
  protected readonly businessShoppingCenter: IBusinessShoppingCenterSchema | null;

  public constructor(building: IBuildingSchema | null, businessShoppingCenter: IBusinessShoppingCenterSchema | null) {
    this.building = building;
    this.businessShoppingCenter = businessShoppingCenter;
  }

  public getOption(): IOption<string, number> | null {
    const value = this.value;

    if (!value) {
      return null;
    }

    return new Option(this.label, value, this.key);
  }

  protected get value(): number | null {
    const buildingBuildYear = getBuildingBuildYear(
      this.building as DEPRECATED__IBuildingSchema | null,
      this.businessShoppingCenter as DEPRECATED__IBusinessShoppingCenterSchema | null,
    );

    return buildingBuildYear ?? null;
  }

  protected get label(): string {
    return 'Год постройки';
  }

  protected get key(): string {
    return 'buildingBuildYear';
  }
}

import * as React from 'react';
import { useSelector } from 'react-redux';

import { IFormValues } from 'shared/components/BestPlaceAnalytics/components/FeedbackForm/types';
import { selectOfferId, selectTrackingPage, selectTrackingUser } from 'shared/selectors';

import * as tracking from '../tracking';

export function useFeedbackModalTracking() {
  const page = useSelector(selectTrackingPage);
  const user = useSelector(selectTrackingUser);
  const offerId = useSelector(selectOfferId);

  const trackGeoAnalyticsPopupOpened = React.useCallback(() => {
    tracking.trackGeoAnalyticsPopupOpened(user, page, offerId)();
  }, [user, page, offerId]);

  const trackGeoAnalyticsPopupFeedback = React.useCallback(
    (formValues: IFormValues) => {
      tracking.trackGeoAnalyticsPopupFeedback(user, page, offerId)(formValues);
    },
    [user, page, offerId],
  );

  return { trackGeoAnalyticsPopupOpened, trackGeoAnalyticsPopupFeedback };
}

import { IBusinessShoppingCenterSchema, IPhoneSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IOffer } from '../../types/offerData';
import { getCommercialAreaForMultiAds, getCommercialBuildingClass, getOfferTitle } from '../../utils/offer_helpers';
import { formatNumber } from '../../utils/phone';

interface IPrepareTitle {
  (offer: IOffer, businessShoppingCenter: IBusinessShoppingCenterSchema | null): string;
}

export const prepareTitle: IPrepareTitle = (offer, businessShoppingCenter) => {
  let title = getOfferTitle(offer).trim();
  const buildingClass = getCommercialBuildingClass(offer.building, businessShoppingCenter);
  const area = getCommercialAreaForMultiAds(offer.minArea, offer.maxArea);

  if (buildingClass) {
    title += ` ${buildingClass}`;
  }

  if (area) {
    title += `, ${area}`;
  }

  return title;
};

interface IPrepareFormattedPhones {
  (phonesAvailable: boolean, phones: IPhoneSchema[] | null): string;
}

export const prepareFormattedPhones: IPrepareFormattedPhones = (phonesAvailable, phones) => {
  return phonesAvailable ? (phones as IPhoneSchema[]).map(formatNumber).join(', ') : '';
};

interface IGetIsPhonesAvailable {
  (hidePhoneButton: boolean, phones: IPhoneSchema[] | null): boolean;
}

export const getIsPhonesAvailable: IGetIsPhonesAvailable = (hidePhoneButton, phones) =>
  !hidePhoneButton && !!phones && !phones.some(phone => !phone.countryCode || !phone.number);

import { numberToPrettyString } from '@cian/utils';

import { CurrencySymbol } from './formatters';
import { IAreaPart } from '../types/areaParts';

export function getIsAnnual(paymentPeriod: string | undefined | null): boolean {
  return paymentPeriod === 'annual';
}

export function getIsRent(paymentPeriod: string | undefined | null): boolean {
  return !!paymentPeriod && /^(annual|monthly)$/.test(paymentPeriod);
}

export function getPrice(isRent: boolean, part: IAreaPart | undefined | null): string {
  if (part?.price) {
    return `${numberToPrettyString(part.price)} ${CurrencySymbol.Rur}${isRent ? '/мес.' : ''}`;
  }

  return '';
}

export function getPriceOfMeter(isRent: boolean, part: IAreaPart | undefined | null): string {
  if (part?.area && part.price) {
    const { area, price } = part;

    const pricePrepared = Math.round(isRent ? (price * 12) / area : price / area);

    return `${numberToPrettyString(pricePrepared)} ${CurrencySymbol.Rur}/м²${isRent ? ' в год' : ''}`;
  }

  return '';
}

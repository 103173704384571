import { Button, Label, UIHeading2, UIHeading4, UIText1 } from '@cian/ui-kit';
import { TColors } from '@cian/ui-kit-design-tokens/colors';
import { IconFunctionalBullet16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { ChartIcon } from './ChartIcon';
import { REPORT_PROPERTIES_NAMES } from './constants';
import * as style from './style.css';

export interface IAnalyticsBlockNewProps {
  addressString: string;
  reportPrice: string;
  onBuyClick: () => void;
}

export const AnalyticsBlockNew: React.FC<React.PropsWithChildren<IAnalyticsBlockNewProps>> = ({
  addressString,
  reportPrice,
  onBuyClick,
}) => {
  return (
    <div className={style['container']}>
      <div className={style['inner']}>
        <div className={style['header']}>
          <ChartIcon />

          <div className={style['title']}>
            <div className={style['top']}>
              <UIHeading2 color="text-primary-default">Аналитический отчёт по зданию</UIHeading2>
              <Label color="accent-negative-primary" background={'rgba(255, 31, 52, 0.1)' as TColors}>
                Новое
              </Label>
            </div>

            <UIText1 color="text-primary-default">{addressString}</UIText1>
          </div>
        </div>

        <UIHeading4 color="text-primary-default">Что входит в PDF-отчёт</UIHeading4>

        <ul className={style['properties']}>
          {REPORT_PROPERTIES_NAMES.map((property, index) => (
            <li key={property + index}>
              <div className={style['item']}>
                <div className={style['bullet']}>
                  <IconFunctionalBullet16 color="icon-secondary-default" />
                </div>
                <UIText1 color="text-primary-default">{property}</UIText1>
              </div>
            </li>
          ))}
        </ul>

        <Button theme="fill_primary" size="XS" onClick={onBuyClick}>
          Купить отчёт за {reportPrice}
        </Button>
      </div>
    </div>
  );
};

import { DynamicCalltrackingButton, EPageTypes, EPlatformTypes } from '@cian/frontend-dynamic-calltracking-component';
import { UIHeading4, UIText2 } from '@cian/ui-kit';
import { IconCommunicationPhone16 } from '@cian/ui-kit-design-tokens/icons';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './AreaPartsFullScreenControls.css';
import { FavoriteButtonContainer } from '../../containers/FavoriteButtonContainer';
import { FavoritesButtonContext } from '../../context';
import { IFavoriteButtonContext } from '../../context/favoritesButton';

export interface IFullScreenControlsProps {
  offerId: number;
  siteBlockId: number | null;
  title: string;
  areaPartTitle: string;
  formattedPhones: string;
  callButtonText: string;
  phonesAvailable?: boolean;
  hideFavoritesButton?: boolean;
  nextSliderCompat?: boolean;
  setPhoneCollapse(): void;
}

const favoriteButtonContext: IFavoriteButtonContext = {
  customClass: styles['favorites'],
  placement: 'gallery',
  popupPivotX: 'right' as const,
};

export const AreaPartsFullScreenControls: React.FC<IFullScreenControlsProps> = props => {
  const [showPhones, setShowPhones] = React.useState(false);

  const {
    offerId,
    siteBlockId,
    setPhoneCollapse,
    title,
    areaPartTitle,
    formattedPhones,
    phonesAvailable,
    hideFavoritesButton,
    nextSliderCompat,
    callButtonText,
  } = props;

  const onShowPhones = React.useCallback(() => {
    setPhoneCollapse();
    setShowPhones(true);
  }, [setPhoneCollapse]);

  return (
    <div className={cx(styles['controls'], nextSliderCompat && styles['controls--next-compat'])}>
      <div className={styles['title']}>
        <UIText2 color="white_60">{title}</UIText2>
        <UIHeading4 color="text-inverted-default">{areaPartTitle}</UIHeading4>
      </div>

      {phonesAvailable && (
        <DynamicCalltrackingButton
          announcementId={offerId}
          blockId={siteBlockId}
          className={styles['show-phone']}
          isOpen={showPhones}
          onClick={onShowPhones}
          openPhoneClassName={styles['phone']}
          pageType={EPageTypes.OfferCard}
          phone={formattedPhones}
          placeType="Fotorama"
          platformType={EPlatformTypes.WebDesktop}
        >
          <span className={styles['show-phone-icon-wrapper']}>
            <IconCommunicationPhone16 color="icon-inverted-default" />
          </span>
          {callButtonText}
        </DynamicCalltrackingButton>
      )}

      {!hideFavoritesButton && (
        <div className={styles['favorites-wrapper']}>
          <FavoritesButtonContext.Provider value={favoriteButtonContext}>
            <FavoriteButtonContainer />
          </FavoritesButtonContext.Provider>
        </div>
      )}
    </div>
  );
};

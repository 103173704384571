import * as React from 'react';
import { useDispatch } from 'react-redux';

import { getBestPlaceAnalyticsPaymentUrl, setBestPlaceAnalyticsStatus } from 'shared/actions/bestPlaceAnalytics';

import { CheckEmail } from '../../components/CheckEmail';
import { useBestPlaceReportEmailContext } from '../../hooks/useBestPlaceReportEmailContext';

export const CheckEmailContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { reportEmail } = useBestPlaceReportEmailContext();

  const onReturnToPreviousStepClick = React.useCallback(() => {
    dispatch(setBestPlaceAnalyticsStatus(null));
  }, [dispatch]);

  const onProceedToPaymentClick = React.useCallback(() => {
    dispatch(getBestPlaceAnalyticsPaymentUrl(reportEmail));
  }, [dispatch, reportEmail]);

  return (
    <CheckEmail
      email={reportEmail}
      onReturnToPreviousStepClick={onReturnToPreviousStepClick}
      onProceedToPaymentClick={onProceedToPaymentClick}
    />
  );
};

CheckEmailContainer.displayName = 'CheckEmailContainer';

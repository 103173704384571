import * as yup from 'yup';
import { RequiredStringSchema } from 'yup/lib/string';

import { CIAN_SOCIAL_EMAIL_REGEXP, VALIDATION_ERRORS } from './constants';

export const schema: RequiredStringSchema<string | undefined, {}> = yup
  .string()
  .email(VALIDATION_ERRORS.incorrectFormat)
  .test('social.cian.ru', VALIDATION_ERRORS.isCianSocial, value => {
    if (!value) {
      return true;
    }

    return !CIAN_SOCIAL_EMAIL_REGEXP.test(value);
  })
  .required(VALIDATION_ERRORS.required);

/* eslint-disable */

export interface IPublicV1GetPaymentStatusResponseSchema {
  /** Статус заказа **/
  paymentStatus: EPaymentStatus;
  /** Почта, которую указал пользователь **/
  userEmail: string;
}
export enum EPaymentStatus {
  /** Заказ отменен **/
  'Canceled' = 'canceled',
  /** Заказ создан **/
  'Created' = 'created',
  /** Заказ оплачен **/
  'Paid' = 'paid',
  /** Оплата заказа завершилась ошибкой **/
  'Paid_error' = 'paid_error',
  /** Ожидание формирования отчета **/
  'Pending_report' = 'pending_report',
  /** Отчет доставлен **/
  'Report_delivered' = 'report_delivered',
  /** Отчет сформирован **/
  'Report_ready' = 'report_ready',
  /** Отчет отправлен **/
  'Report_sent' = 'report_sent',
  /** Отправка отчета завершилась с ошибкой **/
  'Sending_error' = 'sending_error',
}

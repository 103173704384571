import { ILinkButton } from '../ILinkButton';

export class LinkButton implements ILinkButton {
  public readonly link: string;
  public readonly title: string;
  public readonly onClick?: VoidFunction;

  public constructor(link: string, title: string, onClick?: VoidFunction) {
    this.link = link;
    this.title = title;

    if (onClick) {
      this.onClick = onClick;
    }
  }
}

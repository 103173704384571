import * as React from 'react';

export function useToggleShowMore<T extends {}>(
  tableData: T[],
  maxItemsDisplayed: number,
): [T[], boolean, boolean, VoidFunction] {
  const [isFullListDisplayed, setFullListDisplayed] = React.useState(false);
  const shouldShowToggleControl = tableData.length > maxItemsDisplayed;

  const dataToDisplay = React.useMemo(
    () => (isFullListDisplayed ? tableData : tableData.slice(0, maxItemsDisplayed)),
    [isFullListDisplayed, tableData, maxItemsDisplayed],
  );

  const toggleShowFullList = React.useCallback(() => setFullListDisplayed(!isFullListDisplayed), [isFullListDisplayed]);

  return [dataToDisplay, isFullListDisplayed, shouldShowToggleControl, toggleShowFullList];
}

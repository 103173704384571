import * as React from 'react';
import { useSelector } from 'react-redux';

import { CallSourceWish } from '../../components/CallSourceWish';
import { selectIsLeadFactory, selectIsEnabledCallTracking, selectCallTrackingProvider } from '../../selectors';

export const CallSourceWishContainer: React.FC = () => {
  const isLeadFactory = useSelector(selectIsLeadFactory);
  const isEnabledCallTracking = useSelector(selectIsEnabledCallTracking);
  const callTrackingProvider = useSelector(selectCallTrackingProvider);

  if ((isEnabledCallTracking && callTrackingProvider) || isLeadFactory) {
    return null;
  }

  return <CallSourceWish />;
};

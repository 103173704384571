import { fetchBestPlaceAnalyticsReportPrices } from 'shared/services/bestPlaceAnalytics';

import { setBestPlaceAnalyticsReportPrices } from './actions';
import { IThunkActionCreator } from '../../store';

export function getBestPlaceAnalyticsReportPrices(): IThunkActionCreator<Promise<void>> {
  return async (dispatch, getState, context) => {
    const priceData = await fetchBestPlaceAnalyticsReportPrices(context);

    if (priceData) {
      dispatch(setBestPlaceAnalyticsReportPrices(priceData));
    }
  };
}

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { startBuyAnalytics } from '../../actions/buyAnalyticsModal';
import { AnalyticsBlockNew } from '../../components/AnalyticsBlockNew/AnalyticsBlockNew';
import { addressSelector } from '../../selectors';
import { selectBuyAnalyticsReportPrice } from '../../selectors/buyAnalyticsModal';

export const AnalyticsBlockContainer: React.FC = () => {
  const address = useSelector(addressSelector);
  const reportPrice = useSelector(selectBuyAnalyticsReportPrice);
  const dispatch = useDispatch();

  const addressString = address.map(addr => addr.shortName).join(', ');

  const onBuyAnalyticsClick = React.useCallback(() => {
    dispatch(startBuyAnalytics());
  }, [dispatch]);

  return <AnalyticsBlockNew addressString={addressString} reportPrice={reportPrice} onBuyClick={onBuyAnalyticsClick} />;
};

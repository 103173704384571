import * as React from 'react';

import * as styles from './CommercialOtherTCBCOffers.css';
import { OfferGroupByType } from './internal/components/OfferGroupByType';
import { CommercialOtherTCBCOffersProps } from './types';

export const CommercialOtherTCBCOffers: React.FC<CommercialOtherTCBCOffersProps> = props => {
  const { otherOffersGroups, onShowAllOffersClick, onListItemClick, isRent } = props;

  return (
    <div className={styles['container']}>
      {otherOffersGroups.map(otherOffersGroup => {
        return (
          <OfferGroupByType
            isRent={isRent}
            key={otherOffersGroup.offerGroupType}
            otherOffersGroup={otherOffersGroup}
            onShowAllOffersClick={onShowAllOffersClick}
            onListItemClick={onListItemClick}
          />
        );
      })}
    </div>
  );
};

import { batch } from 'react-redux';

import { IPublicV1GetPaymentUrlRequestSchema } from 'shared/repositories/commercial-geo-analytics/entities/requests/PublicV1GetPaymentUrlRequestSchema';
import { selectOfferId } from 'shared/selectors';
import { fetchBestPlaceAnalyticsPaymentUrl } from 'shared/services/bestPlaceAnalytics';
import { EBestPlaceReportStatus } from 'shared/types/bestPlaceAnalytics';

import { setBestPlaceAnalyticsStatusMessage, setBestPlaceAnalyticsStatus } from './actions';
import { MESSAGE_BY_STATUS, STATUS_BY_TYPE } from './constants';
import { trackBestPlaceAnalytics } from './trackBestPlaceAnalytics';
import { getRequestUrl, unsubscribePaymentStatusUpdate } from './utils';
import { IThunkActionCreator } from '../../store';

export function getBestPlaceAnalyticsPaymentUrl(email: string): IThunkActionCreator<Promise<void>> {
  return async (dispatch, getState, context) => {
    unsubscribePaymentStatusUpdate();

    const state = getState();

    const offerId = selectOfferId(state);

    if (!offerId) {
      return;
    }

    batch(() => {
      dispatch(setBestPlaceAnalyticsStatus(EBestPlaceReportStatus.PaymentUrlLoading));
      dispatch(setBestPlaceAnalyticsStatusMessage(MESSAGE_BY_STATUS[EBestPlaceReportStatus.PaymentUrlLoading]));
    });

    const requestParams: IPublicV1GetPaymentUrlRequestSchema = {
      email,
      offerId,
      requestUrl: getRequestUrl().href,
    };

    try {
      const { response, errorMessage, errorKey } = await fetchBestPlaceAnalyticsPaymentUrl(context, requestParams);

      if (response && !errorMessage && !errorKey) {
        batch(() => {
          dispatch(setBestPlaceAnalyticsStatus(EBestPlaceReportStatus.PaymentUrlLoaded));
          dispatch(setBestPlaceAnalyticsStatusMessage(MESSAGE_BY_STATUS[EBestPlaceReportStatus.PaymentUrlLoaded]));
          dispatch(trackBestPlaceAnalytics());
        });

        window.location.href = response.paymentUrl;
      } else {
        const status = errorKey ? STATUS_BY_TYPE[errorKey] : EBestPlaceReportStatus.UnknownServerError;

        batch(() => {
          dispatch(setBestPlaceAnalyticsStatus(status));
          dispatch(setBestPlaceAnalyticsStatusMessage(MESSAGE_BY_STATUS[status]));
        });
      }
    } catch (error) {
      batch(() => {
        dispatch(setBestPlaceAnalyticsStatus(EBestPlaceReportStatus.UnknownServerError));
        dispatch(setBestPlaceAnalyticsStatusMessage(MESSAGE_BY_STATUS[EBestPlaceReportStatus.UnknownServerError]));
      });
    }
  };
}

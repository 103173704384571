import * as React from 'react';
import { RequiredStringSchema } from 'yup/lib/string';

interface IUseEmailValidation {
  isValidating: boolean;
  hasError: boolean;
  errorMessage: string;
  validateForm: (email: string) => Promise<boolean>;
  resetError: VoidFunction;
}

export const useEmailValidation = (schema: RequiredStringSchema<string | undefined, {}>): IUseEmailValidation => {
  const [isValidating, setValidating] = React.useState(false);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const validateForm = React.useCallback(
    async (emailValue: string) => {
      setValidating(true);

      let isInvalid = false;

      try {
        await schema.validate(emailValue);

        setHasError(false);
      } catch (error) {
        const [validationError] = error.errors;

        setErrorMessage(validationError as string);

        isInvalid = true;
      }

      setHasError(isInvalid);
      setValidating(false);

      return isInvalid;
    },
    [setErrorMessage, schema],
  );

  const resetError = React.useCallback(() => {
    setHasError(false);
    setErrorMessage('');
  }, [setHasError, setErrorMessage]);

  return {
    isValidating,
    hasError,
    errorMessage,
    validateForm,
    resetError,
  };
};

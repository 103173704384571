import { IOfferSchema as DEPRECATED__IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IBusinessShoppingCenterSchema } from '../../repositories/offer-card/entities/business_shopping_center/BusinessShoppingCenterSchema';
import { IOfferSchema } from '../../repositories/offer-card/entities/offer/OfferSchema';
import { getIsSaleByOffer } from '../deal_type';

export function getTCBCOffersCount(
  offer: IOfferSchema,
  businessShoppingCenter: IBusinessShoppingCenterSchema | null,
): number | null {
  if (!businessShoppingCenter) {
    return null;
  }

  const { offersRentCount, offersSaleCount } = businessShoppingCenter;

  const isSale = getIsSaleByOffer(offer as unknown as DEPRECATED__IOfferSchema);

  const offersCount = isSale ? offersSaleCount : offersRentCount;

  return offersCount ?? null;
}

import { useToggleState } from '@cian/react-utils';
import { UIHeading3, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import styles from './IllegalConstructionBanner.css';
import { CollapseButton } from '../CollapseButton';

export const IllegalConstructionBanner: React.FC = () => {
  const { state: isCollapsed, toggle: toggleCollapsed } = useToggleState(true);

  return (
    <div className={styles['container']}>
      <div className={styles['warning-icon']} />
      <div className={styles['content-container']}>
        <UIHeading3>Признаки самовольного строительства</UIHeading3>
        <div data-testid="content-text" className={styles['content-text']}>
          <UIText1>
            Госинспекцией по недвижимости выявлены признаки самовольного строительства по&nbsp;данному объекту (его
            части).
          </UIText1>
          {!isCollapsed && (
            <UIText1 data-testid="content-extra-text">
              Возможно наступление юридических последствий вплоть до&nbsp;требований по&nbsp;демонтажу. Перед принятием
              решения о проведении сделки в&nbsp;отношении данного объекта рекомендуем уточнять у&nbsp;собственника
              о&nbsp;статусе выявленных нарушений.
            </UIText1>
          )}
        </div>
        <CollapseButton isCollapsed={isCollapsed} onClick={toggleCollapsed} collapsedText="Подробнее" />
      </div>
    </div>
  );
};

import { TGetPaymentStatusMappers } from './types';
import { IBuyAnalyticsPaymentStatusData } from '../../../../shared/actions/buyAnalyticsModal';
import { EBuyAnalyticsFormStatus } from '../../../../shared/components/BuyAnalyticsModal';
import { IGetPaymentStatusResponse } from '../../../repositories/iap-reports-sale/v1/get-payment-status';

export const prepareData = (rawData: IGetPaymentStatusResponse): IBuyAnalyticsPaymentStatusData => {
  const { paymentStatus, paymentUrl, propertyName, propertyAddress, userEmail } = rawData;

  return {
    propertyAddress,
    propertyName,
    status: paymentStatus as unknown as EBuyAnalyticsFormStatus,
    paymentUrl,
    userEmail,
  };
};

export const mappers: TGetPaymentStatusMappers = {
  200: response => ({
    response: prepareData(response),
    errorMessage: null,
  }),
  400: ({ message }) => ({
    response: null,
    errorMessage: message,
  }),
};

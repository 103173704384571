import {
  IBuildingSchema as DEPRECATED__IBuildingSchema,
  IBusinessShoppingCenterSchema as DEPRECATED__IBusinessShoppingCenterSchema,
  IOfferSchema as DEPRECATED__IOfferSchema,
} from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IOption, Option } from '../../../../models/Option';
import { IBuildingSchema } from '../../../../repositories/offer-card/entities/building/BuildingSchema';
import { IBusinessShoppingCenterSchema } from '../../../../repositories/offer-card/entities/business_shopping_center/BusinessShoppingCenterSchema';
import { IOfferSchema } from '../../../../repositories/offer-card/entities/offer/OfferSchema';
import { getBuildingTotalArea } from '../../../../utils/building';
import { IOptionFacade } from '../../IOptionFacade';

export class TotalAreaOptionFacade implements IOptionFacade<string, string> {
  protected readonly offer: IOfferSchema;
  protected readonly building: IBuildingSchema | null;
  protected readonly businessShoppingCenter: IBusinessShoppingCenterSchema | null;

  public constructor(
    offer: IOfferSchema,
    building: IBuildingSchema | null,
    businessShoppingCenter: IBusinessShoppingCenterSchema | null,
  ) {
    this.offer = offer;
    this.building = building;
    this.businessShoppingCenter = businessShoppingCenter;
  }

  public getOption(): IOption<string, string> | null {
    const value = this.value;

    if (!value) {
      return null;
    }

    return new Option(this.label, value, this.key);
  }

  protected get value(): string | null {
    const buildingTotalArea = getBuildingTotalArea(
      this.offer as unknown as DEPRECATED__IOfferSchema,
      this.building as DEPRECATED__IBuildingSchema | null,
      this.businessShoppingCenter as DEPRECATED__IBusinessShoppingCenterSchema | null,
    );

    return buildingTotalArea ?? null;
  }

  protected get label(): string {
    return 'Общая площадь';
  }

  protected get key(): string {
    return 'totalArea';
  }
}

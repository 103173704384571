import { Image } from '@cian/slider';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './Thumb.css';
import { TMedia } from '../../../../types';

export const thumbTestId = 'thumb-test-id';

interface IThumbProps {
  active?: boolean;
  media: TMedia;
  onClick?(media: TMedia): void;
}

const ThumbComponent: React.FC<React.PropsWithChildren<IThumbProps>> = ({ active, media, onClick }) => {
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      if (onClick) {
        onClick(media);
      }
    },
    [media, onClick],
  );

  return (
    <li className={cx(styles['container'], active && styles['container--active'])}>
      <a href="#" onClick={handleClick} data-testid={thumbTestId}>
        <Image src={media.thumbnailSrc} objectFit="cover" />
      </a>
    </li>
  );
};

export const Thumb = React.memo(ThumbComponent);

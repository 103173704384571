import { DynamicCalltrackingButton, EPageTypes, EPlatformTypes } from '@cian/frontend-dynamic-calltracking-component';
import { UIHeading4, UIText1, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import { CallSourceWishContainer } from 'shared/containers/CallSourceWish';
import { CalltrackingBadgeContainer } from 'shared/containers/CalltrackingBadge';

import * as styles from './CommercialContactsMainView.css';
import { ICommercialContactsMainViewProps } from './types';
import { MoreInfoImage } from '../../images/MoreInfoImage';
import { PopupOwnOfferContainer } from '../../popups/own_offer/container';

export const CommercialContactsMainView: React.FC<ICommercialContactsMainViewProps> = props => {
  const {
    phone,
    announcementId,
    isPhoneOpen,
    onPhoneClick,
    isPopupOwnOfferOpen,
    onPopupOwnOfferClose,
    isCallTrackingButtonDisabled,
  } = props;

  return (
    <div data-name="CommercialContactsMainView" className={styles['container']}>
      <div className={styles['content']}>
        <div className={styles['header']}>
          <UIHeading4>Остались вопросы по объявлению?</UIHeading4>
        </div>

        <div className={styles['description']} data-testid="description">
          <UIText1>Позвоните владельцу объявления и&nbsp;уточните необходимую информацию.</UIText1>
        </div>

        <DynamicCalltrackingButton
          className={styles['button']}
          openPhoneClassName={styles['phones']}
          isOpen={isPhoneOpen}
          pageType={EPageTypes.OfferCard}
          platformType={EPlatformTypes.WebDesktop}
          placeType="ContactsMain"
          phone={phone}
          announcementId={announcementId}
          blockId={null}
          onClick={onPhoneClick}
          disableInnerBehavior={isCallTrackingButtonDisabled}
        >
          <UIText2 fontWeight="bold" color="text-inverted-default">
            Позвонить
          </UIText2>
        </DynamicCalltrackingButton>

        {isPhoneOpen && <CalltrackingBadgeContainer />}
        {isPhoneOpen && (
          <div className={styles['sign']}>
            <CallSourceWishContainer />
          </div>
        )}

        <PopupOwnOfferContainer isOpen={isPopupOwnOfferOpen} onClose={onPopupOwnOfferClose} />
      </div>
      <div className={styles['more-info-image-wrapper']}>
        <MoreInfoImage />
      </div>
    </div>
  );
};

CommercialContactsMainView.displayName = 'CommercialContactsMainView';

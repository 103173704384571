import {
  IconInfrastructureCommercialObject16,
  IconFinanceDynamicChart16,
  IconSocialLikeOn16,
  IconMapPin16,
  IconInfrastructureStore16,
  IconPlaceholderUser16,
  IconFinanceWallet16,
} from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { IReportFeature } from 'shared/components/BestPlaceAnalytics/components/BestPlaceReportDescription/constants';

export const WILL_KNOW_FEATURES: IReportFeature[] = [
  {
    Icon: <IconSocialLikeOn16 />,
    text: 'Оценку помещения',
  },
  {
    Icon: <IconPlaceholderUser16 />,
    text: 'Данные о трафике',
  },
  {
    Icon: <IconFinanceWallet16 />,
    text: 'Средний бюджет семьи по району',
  },
  {
    Icon: <IconInfrastructureStore16 />,
    text: 'Ликвидность помещения',
  },
];

export const WILL_HELP_FEATURES: IReportFeature[] = [
  {
    Icon: <IconMapPin16 />,
    text: 'Выбрать лучшее место для бизнеса',
  },
  {
    Icon: <IconInfrastructureCommercialObject16 />,
    text: 'Определить оптимальную арендную плату',
  },
  {
    Icon: <IconFinanceDynamicChart16 />,
    text: 'Рассчитать окупаемость и рентабельность помещения',
  },
];

import { Button, UIHeading1, UIText1, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './CheckEmail.css';
import { ICheckEmailProps } from './types';

export const CheckEmail: React.FC<React.PropsWithChildren<ICheckEmailProps>> = props => {
  const { email, onProceedToPaymentClick, onReturnToPreviousStepClick } = props;

  return (
    <div className={styles['container']}>
      <div className={styles['header-wrapper']}>
        <UIHeading1>Проверьте email</UIHeading1>
      </div>
      <UIText2>Чтобы мы случайно не отправили отчёт кому-то другому</UIText2>
      <div className={styles['email-wrapper']}>
        <UIText1 fontWeight="700">{email}</UIText1>
      </div>
      <div className={styles['controls-wrapper']}>
        <Button theme="fill_secondary" onClick={onReturnToPreviousStepClick}>
          Назад
        </Button>
        <Button onClick={onProceedToPaymentClick}>Всё верно</Button>
      </div>
    </div>
  );
};

import * as React from 'react';

import {
  IBusinessShoppingCenterSchema,
  IOfferSchema,
  THeatingType,
} from 'shared/types/api-models/offer-card/v1/get-offer-data';

import * as styles from './index.css';
import {
  getConditioningType,
  getExtinguishingSystemType,
  getHeatingType,
  getVentilationType,
} from '../../utils/technical_character_helpers';

export interface ITechnicalCharacterProps {
  businessShoppingCenter: IBusinessShoppingCenterSchema | null;
  offer: IOfferSchema;
}

export class TechnicalCharacter extends React.Component<ITechnicalCharacterProps, {}> {
  public render() {
    if (!this.props.businessShoppingCenter && !this.props.offer.building) {
      return null;
    }

    const { businessShoppingCenter } = this.props;

    const buildingVentilationType = this.props.offer.building && this.props.offer.building.ventilationType;
    const ventilationType = getVentilationType(
      buildingVentilationType || (businessShoppingCenter && businessShoppingCenter.ventilationType),
    );

    const buildingConditioningType = this.props.offer.building && this.props.offer.building.conditioningType;
    const conditioningType = getConditioningType(
      buildingConditioningType || (businessShoppingCenter && businessShoppingCenter.conditioningType),
    );

    const buildingHeatingType = this.props.offer.building && (this.props.offer.building.heatingType as THeatingType);
    const heatingType = getHeatingType(
      buildingHeatingType || (businessShoppingCenter && businessShoppingCenter.heatingType),
    );

    const buildingExtinguishingSystemType =
      this.props.offer.building && this.props.offer.building.extinguishingSystemType;
    const extinguishingSystemType = getExtinguishingSystemType(
      buildingExtinguishingSystemType || (businessShoppingCenter && businessShoppingCenter.extinguishingSystemType),
    );

    if (!conditioningType && !extinguishingSystemType && !heatingType && !ventilationType) {
      return null;
    }

    return (
      <div className={styles['container']}>
        {ventilationType && <div className={styles['ventilation']}>{ventilationType}</div>}
        {conditioningType && <div className={styles['condition']}>{conditioningType}</div>}
        {heatingType && <div className={styles['heating']}>{heatingType}</div>}
        {extinguishingSystemType && <div className={styles['extinguishing']}>{extinguishingSystemType}</div>}
      </div>
    );
  }
}

import { UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './OffersList.css';
import { OffersListProps } from './types';
import { getFriendlyArea } from '../../utils/getFriendlyArea';
import { getFriendlyPrice } from '../../utils/getFriendlyPrice';
import { getOtherOfferLabelSingularFactory } from '../../utils/getOtherOfferLabelSingularFactory';
import { OfferListItem } from '../OfferListItem';

export const OffersList: React.FC<OffersListProps> = props => {
  const { offers, onShowAllOffersClick, offersCount, offersLink, offerGroupType, isRent, onListItemClick } = props;

  const offerListLabelsModel = getOtherOfferLabelSingularFactory(offerGroupType);

  const handleShowAllOffersClick = React.useCallback(() => {
    onShowAllOffersClick(offerGroupType);
  }, [offerGroupType, onShowAllOffersClick]);

  return (
    <div>
      {offers.map(offer => {
        const price = getFriendlyPrice(offer.price, isRent);
        const totalArea = getFriendlyArea(offer.totalArea);

        return (
          <OfferListItem
            key={offer.cianId}
            labelsModel={offerListLabelsModel}
            photoMiniUrl={offer.photoMiniUrl}
            price={price}
            titleLink={offer.titleLink}
            totalArea={totalArea}
            onClick={onListItemClick}
          />
        );
      })}
      {offersCount > offers.length && (
        <a
          className={styles['show-all-others-link']}
          href={offersLink}
          rel="noopener noreferrer"
          target="_blank"
          onClick={handleShowAllOffersClick}
          data-testid="show-all-offers-button"
        >
          <UIText1 color="text-main-default">Смотреть все объявления</UIText1>
        </a>
      )}
    </div>
  );
};

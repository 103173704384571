import { DEFAULT_ERROR_MESSAGE } from './constants';
import { mappers } from './mappers';
import { IGetPaymentStatusResult } from './types';
import { ResponseError } from '../../../../shared/utils/errors';
import {
  fetchGetPaymentStatus,
  IGetPaymentStatusRequest,
} from '../../../repositories/iap-reports-sale/v1/get-payment-status';
import { IApplicationContext } from '../../../types/applicationContext';

export async function fetchBuyAnalyticsPaymentStatus(
  context: IApplicationContext,
  parameters: IGetPaymentStatusRequest,
): Promise<IGetPaymentStatusResult> {
  const { httpApi, logger } = context;

  try {
    const result = (await fetchGetPaymentStatus<IGetPaymentStatusResult, IGetPaymentStatusResult>({
      httpApi,
      parameters,
      mappers,
    })) as IGetPaymentStatusResult;

    if (result.errorMessage) {
      logger.warning(
        new ResponseError({
          domain:
            'shared/services/commercialAnalyticsReports/fetchBuyAnalyticsPaymentStatus/service.ts#commercialAnalyticsReports#fetchBuyAnalyticsPaymentStatus()',
          message: result.errorMessage,
          details: { requestParameters: JSON.stringify(parameters) },
        }),
      );
    }

    return result;
  } catch (error) {
    logger.warning(error, {
      domain:
        'shared/services/commercialAnalyticsReports/fetchBuyAnalyticsPaymentStatus/service.ts#commercialAnalyticsReports#fetchBuyAnalyticsPaymentStatus()',
      description: DEFAULT_ERROR_MESSAGE,
      requestParameters: JSON.stringify(parameters),
    });

    return {
      response: null,
      errorMessage: DEFAULT_ERROR_MESSAGE,
    };
  }
}

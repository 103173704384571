import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { isIllegalConstructedSelector } from 'shared/selectors/offerData/offer/isIllegalConstructedSelector';

import { IllegalConstructionBanner } from './IllegalConstructionBanner';
import { CardSectionNew } from '../CardSectionNew';

export const IllegalConstructionBannerContainer: FC = () => {
  const isIllegalConstructed = useSelector(isIllegalConstructedSelector);

  if (!isIllegalConstructed) {
    return null;
  }

  return (
    <CardSectionNew>
      <IllegalConstructionBanner />
    </CardSectionNew>
  );
};

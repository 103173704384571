/* eslint-disable */
import { ICranageTypeSchema_entities } from '../cranage_type/CranageTypeSchema';
import { IDeadlineSchema } from '../deadline/DeadlineSchema';
import { ICommercialBuildingInfrastructureSchema } from '../commercial_building_infrastructure/CommercialBuildingInfrastructureSchema';
import { ILiftTypeSchema_entities } from '../lift_type/LiftTypeSchema';
import { IOpeningHoursSchema_entities } from '../opening_hours/OpeningHoursSchema';
import { IParkingSchema_entities } from '../parking/ParkingSchema';

export interface IBuildingSchema {
  /** Вход **/
  accessType?: EAccessType;
  /** Год постройки **/
  buildYear?: number;
  /** Количество грузовых лифтов **/
  cargoLiftsCount?: number;
  /** Высота потолков, м **/
  ceilingHeight?: string;
  /** Класс **/
  classType?: EClassType;
  /** Сетка колонн **/
  columnGrid?: string;
  /** Состояние **/
  conditionRatingType?: EConditionRatingType;
  /** Кондиционирование **/
  conditioningType?: EConditioningType;
  /** Крановое оборудование **/
  cranageTypes?: ICranageTypeSchema_entities[];
  deadline?: IDeadlineSchema;
  /** Застройщик **/
  developer?: string;
  /** Система пожаротушения **/
  extinguishingSystemType?: EExtinguishingSystemType;
  /** Количество этажей в здании **/
  floorsCount?: number;
  /** Тип ворот **/
  gatesType?: EGatesType;
  /** Мусоропровод **/
  hasGarbageChute?: boolean;
  /** Отопление **/
  heatingType?: EHeatingType;
  /** Линия домов **/
  houseLineType?: EHouseLineType;
  /** Тип дома **/
  houseMaterialType?: EHouseMaterialType;
  /** Инфраструктура **/
  infrastructure?: ICommercialBuildingInfrastructureSchema;
  /** Аварийный? **/
  isEmergency?: boolean;
  /** Лифт'), help='Лифты **/
  liftTypes?: ILiftTypeSchema_entities[];
  /** Управляющая компания **/
  managementCompany?: string;
  /** Тип дома **/
  materialType?: EMaterialType;
  /** Название **/
  name?: string;
  /** Часы работы **/
  openingHours?: IOpeningHoursSchema_entities;
  /** Парковка **/
  parking?: IParkingSchema_entities;
  /** Количество пассажирских лифтов **/
  passengerLiftsCount?: number;
  /** Цена за м2) **/
  priceSqm?: number;
  /** Динамика цены за м2 за год (в процентах) **/
  priceSqmDiff?: number;
  /** Ставка аренды (для данной комнатности) **/
  roomRentPrice?: number;
  /** Динамика ставки за год (для данной комнатности) (в процентах) **/
  roomRentPriceDiff?: number;
  /** Серия дома **/
  series?: string;
  /** Масштаб торгового центра **/
  shoppingCenterScaleType?: EShoppingCenterScaleType;
  /** Категория **/
  statusType?: EStatusType;
  /** Арендаторы **/
  tenants?: string;
  /** Общая площадь, м2 **/
  totalArea?: string;
  /** Тип **/
  type?: EType;
  /** Вентиляция **/
  ventilationType?: EVentilationType;
  /** Рабочие дни **/
  workingDaysType?: EWorkingDaysType;
}
export enum EAccessType {
  /** Свободный **/
  'Free' = 'free',
  /** Пропускная система **/
  'PassSystem' = 'passSystem',
}
export enum EClassType {
  /** A **/
  'A' = 'a',
  /** A+ **/
  'APlus' = 'aPlus',
  /** B **/
  'B' = 'b',
  /** B- **/
  'BMinus' = 'bMinus',
  /** B+ **/
  'BPlus' = 'bPlus',
  /** C **/
  'C' = 'c',
  /** C+ **/
  'CPlus' = 'cPlus',
  /** D **/
  'D' = 'd',
}
export enum EConditionRatingType {
  /** Отличное **/
  'Excellent' = 'excellent',
  /** Хорошее **/
  'Good' = 'good',
  /** Удовлетворительное **/
  'Satisfactory' = 'satisfactory',
}
export enum EConditioningType {
  /** Центральное **/
  'Central' = 'central',
  /** Местное **/
  'Local' = 'local',
  /** Нет **/
  'No' = 'no',
}
export enum EExtinguishingSystemType {
  /** Сигнализация **/
  'Alarm' = 'alarm',
  /** Газовая **/
  'Gas' = 'gas',
  /** Гидрантная **/
  'Hydrant' = 'hydrant',
  /** Нет **/
  'No' = 'no',
  /** Порошковая **/
  'Powder' = 'powder',
  /** Спринклерная **/
  'Sprinkler' = 'sprinkler',
}
export enum EGatesType {
  /** На нулевой отметке **/
  'AtZero' = 'atZero',
  /** Докового типа **/
  'DockType' = 'dockType',
  /** На пандусе **/
  'OnRamp' = 'onRamp',
}
export enum EHeatingType {
  /** Автономное **/
  'Autonomous' = 'autonomous',
  /** Автономное газовое **/
  'AutonomousGas' = 'autonomousGas',
  /** Котел **/
  'Boiler' = 'boiler',
  /** Центральное **/
  'Central' = 'central',
  /** Центральное угольное **/
  'CentralCoal' = 'centralCoal',
  /** Центральное газовое **/
  'CentralGas' = 'centralGas',
  /** Дизельное **/
  'Diesel' = 'diesel',
  /** Электрическое **/
  'Electric' = 'electric',
  /** Камин **/
  'Fireplace' = 'fireplace',
  /** Нет **/
  'No' = 'no',
  /** Другое **/
  'Other' = 'other',
  /** Твердотопливный котел **/
  'SolidFuelBoiler' = 'solidFuelBoiler',
  /** Печь **/
  'Stove' = 'stove',
}
export enum EHouseLineType {
  /** Первая **/
  'First' = 'first',
  /** Иная **/
  'Other' = 'other',
  /** Вторая **/
  'Second' = 'second',
}
export enum EHouseMaterialType {
  /** Газобетонный блок **/
  'AerocreteBlock' = 'aerocreteBlock',
  /** Блочный **/
  'Block' = 'block',
  /** Щитовой **/
  'Boards' = 'boards',
  /** Кирпичный **/
  'Brick' = 'brick',
  /** Пенобетонный блок **/
  'FoamConcreteBlock' = 'foamConcreteBlock',
  /** Газосиликатный блок **/
  'GasSilicateBlock' = 'gasSilicateBlock',
  /** Монолитный **/
  'Monolith' = 'monolith',
  /** Монолитно-кирпичный **/
  'MonolithBrick' = 'monolithBrick',
  /** старый фонд **/
  'Old' = 'old',
  /** Панельный **/
  'Panel' = 'panel',
  /** Сталинский **/
  'Stalin' = 'stalin',
  /** Каркасный **/
  'Wireframe' = 'wireframe',
  /** Деревянный **/
  'Wood' = 'wood',
}
export enum EMaterialType {
  /** Газобетонный блок **/
  'AerocreteBlock' = 'aerocreteBlock',
  /** Блочный **/
  'Block' = 'block',
  /** Щитовой **/
  'Boards' = 'boards',
  /** Кирпичный **/
  'Brick' = 'brick',
  /** Пенобетонный блок **/
  'FoamConcreteBlock' = 'foamConcreteBlock',
  /** Газосиликатный блок **/
  'GasSilicateBlock' = 'gasSilicateBlock',
  /** Монолитный **/
  'Monolith' = 'monolith',
  /** Монолитно-кирпичный **/
  'MonolithBrick' = 'monolithBrick',
  /** старый фонд **/
  'Old' = 'old',
  /** Панельный **/
  'Panel' = 'panel',
  /** Сталинский **/
  'Stalin' = 'stalin',
  /** Каркасный **/
  'Wireframe' = 'wireframe',
  /** Деревянный **/
  'Wood' = 'wood',
}
export enum EShoppingCenterScaleType {
  /** Районный **/
  'District' = 'district',
  /** Микрорайонный **/
  'Microdistrict' = 'microdistrict',
  /** Окружной **/
  'Okrug' = 'okrug',
  /** Региональный **/
  'Regional' = 'regional',
  /** Суперокружной **/
  'SuperOkrug' = 'superOkrug',
  /** Суперрегиональный **/
  'SuperRegional' = 'superRegional',
}
export enum EStatusType {
  /** Новостройка/Строящееся **/
  'NewBuilding' = 'newBuilding',
  /** Действующее **/
  'Operational' = 'operational',
  /** Проект **/
  'Project' = 'project',
  /** Вторичный рынок **/
  'Secondary' = 'secondary',
  /** Строящееся **/
  'UnderConstruction' = 'underConstruction',
}
export enum EType {
  /** Административное здание **/
  'AdministrativeBuilding' = 'administrativeBuilding',
  /** Бизнес-центр **/
  'BusinessCenter' = 'businessCenter',
  /** Деловой центр **/
  'BusinessCenter2' = 'businessCenter2',
  /** Деловой дом **/
  'BusinessHouse' = 'businessHouse',
  /** Бизнес-парк **/
  'BusinessPark' = 'businessPark',
  /** Бизнес-квартал **/
  'BusinessQuarter' = 'businessQuarter',
  /** Деловой квартал **/
  'BusinessQuarter2' = 'businessQuarter2',
  /** Свободное **/
  'Free' = 'free',
  /** Объект свободного назначения **/
  'FreeAppointmentObject' = 'freeAppointmentObject',
  /** Производственный комплекс **/
  'IndustrialComplex' = 'industrialComplex',
  /** Индустриальный парк **/
  'IndustrialPark' = 'industrialPark',
  /** Промплощадка **/
  'IndustrialSite' = 'industrialSite',
  /** Производственно-складской комплекс **/
  'IndustrialWarehouseComplex' = 'industrialWarehouseComplex',
  /** Логистический центр **/
  'LogisticsCenter' = 'logisticsCenter',
  /** Логистический комплекс **/
  'LogisticsComplex' = 'logisticsComplex',
  /** Логистический парк **/
  'LogisticsPark' = 'logisticsPark',
  /** Особняк **/
  'Mansion' = 'mansion',
  /** Производственное здание **/
  'ManufactureBuilding' = 'manufactureBuilding',
  /** Производственный цех **/
  'ManufacturingFacility' = 'manufacturingFacility',
  /** Модульное здание **/
  'Modular' = 'modular',
  /** Многофункциональный комплекс **/
  'MultifunctionalComplex' = 'multifunctionalComplex',
  /** Офисно-гостиничный комплекс **/
  'OfficeAndHotelComplex' = 'officeAndHotelComplex',
  /** Офисно-жилой комплекс **/
  'OfficeAndResidentialComplex' = 'officeAndResidentialComplex',
  /** Офисно-складское **/
  'OfficeAndWarehouse' = 'officeAndWarehouse',
  /** Офисно-складской комплекс **/
  'OfficeAndWarehouseComplex' = 'officeAndWarehouseComplex',
  /** Офисное здание **/
  'OfficeBuilding' = 'officeBuilding',
  /** Офисный центр **/
  'OfficeCenter' = 'officeCenter',
  /** Офисный комплекс **/
  'OfficeComplex' = 'officeComplex',
  /** Офисно-производственный комплекс **/
  'OfficeIndustrialComplex' = 'officeIndustrialComplex',
  /** Офисный квартал **/
  'OfficeQuarter' = 'officeQuarter',
  /** Старый фонд **/
  'Old' = 'old',
  /** Другое **/
  'Other' = 'other',
  /** Аутлет **/
  'Outlet' = 'outlet',
  /** Имущественный комплекс **/
  'PropertyComplex' = 'propertyComplex',
  /** Жилой комплекс **/
  'ResidentialComplex' = 'residentialComplex',
  /** Жилой фонд **/
  'ResidentialFund' = 'residentialFund',
  /** Жилой дом **/
  'ResidentialHouse' = 'residentialHouse',
  /** Торгово-деловой комплекс **/
  'ShoppingAndBusinessComplex' = 'shoppingAndBusinessComplex',
  /** Торгово-общественный центр **/
  'ShoppingAndCommunityCenter' = 'shoppingAndCommunityCenter',
  /** Торгово-развлекательный центр **/
  'ShoppingAndEntertainmentCenter' = 'shoppingAndEntertainmentCenter',
  /** Торгово-складской комлекс **/
  'ShoppingAndWarehouseComplex' = 'shoppingAndWarehouseComplex',
  /** Торговый центр **/
  'ShoppingCenter' = 'shoppingCenter',
  /** Торговый комплекс **/
  'ShoppingComplex' = 'shoppingComplex',
  /** Специализированный торговый центр **/
  'SpecializedShoppingCenter' = 'specializedShoppingCenter',
  /** Отдельно стоящее здание **/
  'StandaloneBuilding' = 'standaloneBuilding',
  /** Технопарк **/
  'Technopark' = 'technopark',
  /** Торгово-выставочный комплекс **/
  'TradeAndExhibitionComplex' = 'tradeAndExhibitionComplex',
  /** Торговый дом **/
  'TradingHouse' = 'tradingHouse',
  /** Торгово-офисный комплекс **/
  'TradingOfficeComplex' = 'tradingOfficeComplex',
  /** Нежилой фонд **/
  'UninhabitedFund' = 'uninhabitedFund',
  /** Склад **/
  'Warehouse' = 'warehouse',
  /** Складской комплекс **/
  'WarehouseComplex' = 'warehouseComplex',
}
export enum EVentilationType {
  /** Приточная **/
  'Forced' = 'forced',
  /** Естественная **/
  'Natural' = 'natural',
  /** Нет **/
  'No' = 'no',
}
export enum EWorkingDaysType {
  /** Ежедневно **/
  'Everyday' = 'everyday',
  /** Пятница **/
  'Friday' = 'friday',
  /** Понедельник **/
  'Monday' = 'monday',
  /** Суббота **/
  'Saturday' = 'saturday',
  /** Воскресенье **/
  'Sunday' = 'sunday',
  /** Четверг **/
  'Thursday' = 'thursday',
  /** Вторник **/
  'Tuesday' = 'tuesday',
  /** Среда **/
  'Wednesday' = 'wednesday',
  /** Будни **/
  'Weekdays' = 'weekdays',
  /** Выходные **/
  'Weekends' = 'weekends',
}

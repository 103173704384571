import * as React from 'react';

import { usePrevious } from './usePrevious';

/**
 *
 * @param element - целевой элемент, до которого скролим
 * @param shouldScroll - значение, используемое как флаг необходимости отскроливания к целевому элементу
 */
export function useScrollToElement(element: HTMLElement | null, shouldScroll: boolean) {
  const previousShouldScroll = usePrevious(shouldScroll);

  React.useEffect(() => {
    if (previousShouldScroll !== undefined && shouldScroll) {
      element?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [previousShouldScroll, shouldScroll, element]);
}

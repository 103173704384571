import { IPublicV1GetPaymentStatusResponseSchema } from 'shared/repositories/commercial-geo-analytics/entities/responses/PublicV1GetPaymentStatusResponseSchema';

import { DEFAULT_ERROR_MESSAGE, REPORT_STATUS_BY_PAYMENT_STATUS } from './constants';
import {
  IError,
  TGetBestPlaceAnalyticsStatusMappers,
  IGetBestPlaceAnalyticsStatusResult400,
  IBestPlaceGetPaymentStatusData,
} from './types';

type TPrepare400ErrorFieldsResult = Omit<IGetBestPlaceAnalyticsStatusResult400, 'statusCode' | 'response'>;

export const defaultError: TPrepare400ErrorFieldsResult = {
  errorKey: 'integration',
  errorMessage: DEFAULT_ERROR_MESSAGE,
};

export const prepareData = (rawData: IPublicV1GetPaymentStatusResponseSchema): IBestPlaceGetPaymentStatusData => {
  const { paymentStatus, userEmail } = rawData;

  return {
    paymentStatus: REPORT_STATUS_BY_PAYMENT_STATUS[paymentStatus],
    userEmail,
  };
};

export const prepare400ErrorFields = (errors?: IError[]): TPrepare400ErrorFieldsResult => {
  if (!errors || !errors.length) {
    return defaultError;
  }

  const [{ key, message }] = errors;

  return { errorKey: key, errorMessage: message };
};

export const paymentStatusMappers: TGetBestPlaceAnalyticsStatusMappers = {
  200: response => ({
    response: prepareData(response),
    errorKey: null,
    errorMessage: null,
  }),
  400: response => ({
    response: null,
    ...prepare400ErrorFields(response.errors as IError[]),
  }),
};

import { Button, FormField, Input, RadioButtonGroup, UIHeading2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './FeedbackForm.css';
import { ANALYTICS_FREQUENCY_OPTIONS, FORM_FIELDS, REPORT_TARGET_OPTIONS, USER_TYPE_OPTIONS } from './constants';
import { IFeedbackFormProps } from './types';
import { SuccessPaymentHeader } from '../SuccessPaymentHeader';

const {
  userType: userTypeField,
  reportTarget: reportTargetField,
  analyticsFrequency: analyticsFrequencyField,
  businessType: businessTypeField,
} = FORM_FIELDS;

const FORM_FIELDS_FOR_SUBMIT = [userTypeField, reportTargetField, analyticsFrequencyField];

export const FeedbackForm: React.FC<IFeedbackFormProps> = props => {
  const { formik, reportUserEmail } = props;

  const isNoEssentialValuesProvided = React.useMemo(
    () => FORM_FIELDS_FOR_SUBMIT.every(fieldName => !formik.values[fieldName]),
    [formik.values],
  );

  return (
    <section className={styles['container']}>
      <SuccessPaymentHeader reportUserEmail={reportUserEmail} />
      <div className={styles['form-header']}>
        <UIHeading2>Расскажите о себе, пожалуйста</UIHeading2>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <FormField label="Кто вы?">
          <RadioButtonGroup
            options={USER_TYPE_OPTIONS}
            name={userTypeField}
            value={formik.values[userTypeField]}
            onChange={formik.handleChange}
          />
        </FormField>
        <FormField label="Для кого покупаете отчёт?">
          <RadioButtonGroup
            name={reportTargetField}
            options={REPORT_TARGET_OPTIONS}
            value={formik.values[reportTargetField]}
            onChange={formik.handleChange}
          />
        </FormField>
        <FormField label="Как часто вам требуется такая аналитика?">
          <RadioButtonGroup
            name={analyticsFrequencyField}
            options={ANALYTICS_FREQUENCY_OPTIONS}
            value={formik.values[analyticsFrequencyField]}
            onChange={formik.handleChange}
          />
        </FormField>
        <FormField label="Какой у вас бизнес?">
          <Input
            name={businessTypeField}
            placeholder="Например, кофейня или магазин"
            value={formik.values[businessTypeField]}
            onChange={formik.handleChange}
          />
        </FormField>
        <Button disabled={formik.isSubmitting || isNoEssentialValuesProvided} type="submit" theme="fill_secondary">
          Отправить
        </Button>
      </form>
    </section>
  );
};

FeedbackForm.displayName = 'FeedbackForm';

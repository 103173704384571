import * as React from 'react';
import { useSelector } from 'react-redux';

import { CardSectionNew } from 'shared/components/CardSectionNew';
import { useAbGroupSelector } from 'shared/hooks/selectors/useAbGroupSelector';
import { useOfferChatSelector } from 'shared/hooks/selectors/useOfferChatSelector';
import { useOfferDataSelector } from 'shared/hooks/selectors/useOfferDataSelector';
import { useIsElectronicTradingOffer } from 'shared/hooks/useIsElectronicTradingOffer';
import { getAgentAvailability } from 'shared/selectors';
import { disablePhoneButtonSelector } from 'shared/selectors/disablePhoneButtonSelector';

import { useCommercialContactsMainBoundActions } from './internal/hooks/useCommercialContactsMainBoundActions';
import { CommercialContactsMainController } from '../CommercialContactsMainController';

export const CommercialContactsMainContainer: React.FC = () => {
  const offerData = useOfferDataSelector();
  const offerChat = useOfferChatSelector();
  const abGroup = useAbGroupSelector();
  const isElectronicTradingOffer = useIsElectronicTradingOffer();
  const agentAvailability = useSelector(getAgentAvailability);

  /**
   * @todo Удалить блок с экспериментом
   * @description Данный функционал появился в задаче CD-129223, будет удалён в задаче CD-129224
   * Удалить, если эксперимент будет не удачным
   */
  const disablePhoneButton = useSelector(disablePhoneButtonSelector);
  const boundActions = useCommercialContactsMainBoundActions();

  const { offer, user } = offerData;
  const { phones } = offer;

  const phonesAvailable =
    !isElectronicTradingOffer &&
    !!phones &&
    !!phones.length &&
    phones.every(phone => !!phone.countryCode && !!phone.number);

  if (!phonesAvailable) {
    return null;
  }

  return (
    <CardSectionNew hideForPrint>
      <CommercialContactsMainController
        agent={offerData.agent}
        user={user}
        offer={offer}
        agentAvailability={agentAvailability}
        setPhoneCollapse={boundActions.setPhoneCollapse}
        offerChat={offerChat}
        abGroup={abGroup}
        phones={phones}
        isCallTrackingButtonDisabled={disablePhoneButton}
        openNotAvailablePopup={/* istanbul ignore next */ () => boundActions.setNotAvailablePopupOpenValue(true)}
      />
    </CardSectionNew>
  );
};

CommercialContactsMainContainer.displayName = 'CommercialContactsMainContainer';

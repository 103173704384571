import { ca } from '@cian/analytics';

import { IAgentSchema, IOfferSchema, IUserSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';
import { IRosreestrInfo } from 'shared/types/offerData';
import { getAction } from 'shared/utils/tracking/getAction';
import { getCustomUrl } from 'shared/utils/tracking/getCustomUrl';
import { getPage } from 'shared/utils/tracking/getPage';
import { getPartner } from 'shared/utils/tracking/getPartner';
import { getUser } from 'shared/utils/tracking/getUser';
import { getProducts } from 'shared/utils/tracking/get_products';

interface ITrackingProps {
  offer: IOfferSchema;
  agent: IAgentSchema | null;
  user: IUserSchema | null;
  abGroup: number;
  kpId: number | null;
  bot?: boolean;
  siteBlockId?: number | null;
  rosreestrInfo?: IRosreestrInfo | null;
}

export function trackOpenPhonesClick({
  offer,
  agent,
  user,
  abGroup,
  kpId,
  bot,
  siteBlockId,
  rosreestrInfo,
}: ITrackingProps) {
  const { workTimeInfo } = offer;

  const products = getProducts({ agent, offer, kpId, siteBlockId, rosreestrInfo });

  ca('eventEbc', {
    action: getAction(`Open_card_more_block${bot ? '_bot' : ''}`, workTimeInfo),
    category: 'Phones',
    label: getCustomUrl(offer, agent),
    name: 'oldevent',
    page: getPage(agent, offer, siteBlockId),
    partner: getPartner(user, offer),
    products: [
      {
        ...products,
        variant: products.variant ? products.variant.split('/') : [],
      },
    ],
    user: getUser(user, abGroup),
  });
}

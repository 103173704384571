import { IconActionChevronLeft16, IconActionChevronRight16 } from '@cian/ui-kit-design-tokens/icons';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './styles.css';

import type { TDirection } from '@cian/frontend-newbuilding-carousel-component';

export interface IVerticalArrowProps {
  direction: TDirection;
  offset?: number;
  containerClass?: string;
  positionTop?: string;
  onClick?(): void;
}

const DEFAULT_OFFSET = -20;
const DEFAULT_POSITION_TOP = '50%';

export function VerticalArrow({
  direction,
  offset = DEFAULT_OFFSET,
  containerClass,
  onClick,
  positionTop = DEFAULT_POSITION_TOP,
}: IVerticalArrowProps) {
  const ArrowIcon = direction < 0 ? IconActionChevronLeft16 : IconActionChevronRight16;
  const style = direction < 0 ? { left: `${offset}px`, top: positionTop } : { right: `${offset}px`, top: positionTop };

  return (
    <div
      data-testid="VerticalSliderArrow"
      className={cx(styles['arrow'], containerClass)}
      style={style}
      onClick={onClick}
    >
      <ArrowIcon color={'icon-secondary-default'} />
    </div>
  );
}

import { UIHeading1, UIText1 } from '@cian/ui-kit';
import cx from 'clsx';
import React, { FC } from 'react';

import { Shutter } from 'shared/components/Shutter';

import styles from './PossibleAssignment.css';
import { SpecialtyItemWithIcon } from './internal/utils/getSpecialties';

type PossibleAssignmentProps = {
  specialtyItemsWithIcon: SpecialtyItemWithIcon[];
  otherSpecialties: string | null;
};

export const PossibleAssignment: FC<React.PropsWithChildren<PossibleAssignmentProps>> = props => {
  const { otherSpecialties, specialtyItemsWithIcon } = props;

  return (
    <div className={styles['container']}>
      <div className={styles['specialties-title']}>
        <UIHeading1>Возможное назначение</UIHeading1>
      </div>

      {!!specialtyItemsWithIcon.length && (
        <ul className={styles['specialties']}>
          {specialtyItemsWithIcon.map(({ name, title, id }) => (
            <li data-testid="specialty" className={cx(styles['specialty'], styles[name])} key={`desc-specialty-${id}`}>
              <UIText1>{title}</UIText1>
            </li>
          ))}
        </ul>
      )}

      {!!otherSpecialties && (
        <div data-testid="specialties-other" className={styles['specialties-other']}>
          <Shutter maxHeight={66} closedText="Показать всё">
            <UIText1>{otherSpecialties}</UIText1>
          </Shutter>
        </div>
      )}
    </div>
  );
};

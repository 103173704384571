import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectCallButtonText } from 'shared/selectors/callButton';

import { getIsPhonesAvailable, prepareFormattedPhones, prepareTitle } from './helpers';
import { AreaPartsFullScreenControls } from '../../components/AreaPartsFullScreenControls';
import { IFormattedAreaPartSchema } from '../../repositories/offer-card/entities/formatted_area_parts_info/FormattedAreaPartSchema';
import { offerSelector } from '../../selectors';
import { getDynamicCalltrackingSiteBlockId } from '../../selectors/getDynamicCalltrackingSiteBlockId';
import { isRenterFormEnabled } from '../../selectors/isRenterFormEnabled';
import { businessShoppingCenterSelector } from '../../selectors/offerData/offer/businessShoppingCenterSelector';
import { setPhoneCollapse } from '../../store/phone_collapse';

export interface IAreaPartsFullScreenControlsContainerProps {
  areaPartData: IFormattedAreaPartSchema;
}

export const AreaPartsFullScreenControlsContainer: React.FC<IAreaPartsFullScreenControlsContainerProps> = ({
  areaPartData,
}) => {
  const dispatch = useDispatch();
  const onSetPhoneCollapse = React.useCallback(() => {
    dispatch(setPhoneCollapse());
  }, [dispatch]);

  const offer = useSelector(offerSelector);
  const siteBlockId = useSelector(getDynamicCalltrackingSiteBlockId);
  const hidePhoneButton = useSelector(isRenterFormEnabled);
  const businessShoppingCenter = useSelector(businessShoppingCenterSelector);
  const callButtonText = useSelector(selectCallButtonText);
  const { phones, id } = offer;

  const phonesAvailable = React.useMemo(() => getIsPhonesAvailable(hidePhoneButton, phones), [hidePhoneButton, phones]);

  const formattedPhones = React.useMemo(
    () => prepareFormattedPhones(phonesAvailable, phones),
    [phonesAvailable, phones],
  );

  const title = React.useMemo(() => prepareTitle(offer, businessShoppingCenter), [offer, businessShoppingCenter]);

  const areaPartTitle = React.useMemo(() => {
    return `${areaPartData.area}, ${areaPartData.floor} этаж`;
  }, [areaPartData]);

  return (
    <AreaPartsFullScreenControls
      offerId={id}
      siteBlockId={siteBlockId}
      phonesAvailable={phonesAvailable}
      title={title}
      callButtonText={callButtonText}
      areaPartTitle={areaPartTitle}
      formattedPhones={formattedPhones}
      setPhoneCollapse={onSetPhoneCollapse}
      nextSliderCompat={true}
    />
  );
};

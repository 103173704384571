import { connect } from 'react-redux';

import { ITechnicalCharacterProps, TechnicalCharacter } from './index';
import { IState } from '../../store';

function mapStateToProps(state: IState): ITechnicalCharacterProps {
  return {
    businessShoppingCenter: state.offerData.businessShoppingCenter,
    offer: state.offerData.offer,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TechnicalCharacterContainer = connect<ITechnicalCharacterProps, any, any>(mapStateToProps)(
  TechnicalCharacter,
);

import { ILinkButton } from '../../../../../../models/LinkButton';
import { IAgentSchema } from '../../../../../../repositories/offer-card/entities/agent/AgentSchema';
import { IBusinessShoppingCenterSchema } from '../../../../../../repositories/offer-card/entities/business_shopping_center/BusinessShoppingCenterSchema';
import { IOfferSchema } from '../../../../../../repositories/offer-card/entities/offer/OfferSchema';
import { ShowAllTCBCOffersButtonFacade } from '../../../../../../services/LinkButtonFacade/implementations/TCBC';
import { getTCBCOffersCount } from '../../../../../../utils/tcbc/getTCBCOffersCount';
import { trackTCBCFactory } from '../tracking/trackTCBCFactory';

export function getTCBCOffersButton(
  offer: IOfferSchema,
  businessShoppingCenter: IBusinessShoppingCenterSchema,
  agent: IAgentSchema | null,
): ILinkButton | null {
  const tcbcOffersCount = getTCBCOffersCount(offer, businessShoppingCenter);

  if (tcbcOffersCount != null && tcbcOffersCount > 1) {
    const showAllTCBCOffersButton = new ShowAllTCBCOffersButtonFacade(offer, businessShoppingCenter);

    return showAllTCBCOffersButton.getLinkButton(() => {
      trackTCBCFactory(offer, businessShoppingCenter, agent)('to_BC_listing');
    });
  }

  return null;
}

import {
  IconFunctionalShow16,
  IconToggleHeartOn16,
  IconProductSaveSearch16,
  IconFunctionalSuccess16,
} from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

export const authAdvantagesMapper = [
  { icon: <IconFunctionalShow16 />, content: 'Просматривайте условия сделки и всю информацию об объекте' },
  { icon: <IconProductSaveSearch16 />, content: 'Сохраняйте фильтры в поиске и не вводите заново' },
  { icon: <IconToggleHeartOn16 />, content: 'Доступ к избранному с любого устройства' },
  { icon: <IconFunctionalSuccess16 />, content: 'Неограниченное добавление в избранное' },
];

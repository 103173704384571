import { IBusinessShoppingCenterSchema as DEPRECATED__IBusinessShoppingCenterSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { getFriendlyBCTCShortLabel } from './getFriendlyBCTCShortLabel';
import { IBuildingSchema } from '../../repositories/offer-card/entities/building/BuildingSchema';
import { IBusinessShoppingCenterSchema } from '../../repositories/offer-card/entities/business_shopping_center/BusinessShoppingCenterSchema';
import { getCommercialParent } from '../offer_helpers';

const getTitleTemplateFactory = (type: string) => (name: string) => `О ${type} «${name}»`;

export function getCommercialAboutTCBCTitle(
  businessShoppingCenter: IBusinessShoppingCenterSchema | null,
  building: IBuildingSchema | null,
): string | null {
  const parent = getCommercialParent(businessShoppingCenter as DEPRECATED__IBusinessShoppingCenterSchema | null);

  const friendlyBCTCShortLabel = getFriendlyBCTCShortLabel(businessShoppingCenter?.type) ?? 'здании';

  const getTitleTemplate = getTitleTemplateFactory(friendlyBCTCShortLabel);

  if (parent?.name) {
    return getTitleTemplate(parent.name);
  }

  if (businessShoppingCenter?.geo?.fullAddress) {
    return getTitleTemplate(businessShoppingCenter.geo.fullAddress);
  }

  if (building?.name) {
    return getTitleTemplate(building.name);
  }

  return null;
}

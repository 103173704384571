import { Buttons, Image, Overlay, Scroller, Slider } from '@cian/slider';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './GalleryInner.css';
import { TMedia } from '../../types';
import { PaginationThumbs } from '../PaginationThumbs';

export const fullscreenControlsTestId = 'fullscreen-controls';
export const paginationThumbsTestId = 'pagination-thumbs';

interface IGalleryInnerProps {
  media: TMedia[];
  bordered?: boolean;
  slotScrollerEnd?: React.ReactNode;
  onScrollerClick?(): void;
  thumbsBounded?: boolean;
  objectFit?: React.ComponentProps<typeof Image>['objectFit'];
  isFullscreen?: boolean;
}

const GalleryInnerComponent: React.ForwardRefRenderFunction<
  React.ElementRef<typeof Slider> | null,
  IGalleryInnerProps
> = (
  { media, bordered, slotScrollerEnd, onScrollerClick, thumbsBounded, objectFit = 'cover', isFullscreen = false },
  ref,
) => {
  const sliderRef = React.useRef<React.ElementRef<typeof Slider>>(null);
  const paginationThumbsRef = React.useRef<React.ElementRef<typeof PaginationThumbs>>(null);

  /* istanbul ignore next */
  const handleScrollEnd = React.useCallback(() => {
    paginationThumbsRef.current?.scrollCurrentThumbIntoView();
  }, []);

  /* istanbul ignore next */
  const handleScrollerClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const target = event.target as HTMLElement;
      if (target?.closest('a, button')) {
        return;
      }

      if (onScrollerClick) {
        onScrollerClick();
      }
    },
    [onScrollerClick],
  );

  React.useImperativeHandle<React.ElementRef<typeof Slider> | null, React.ElementRef<typeof Slider> | null>(
    ref,
    () => sliderRef.current,
    [],
  );

  const shouldShowFullscreenControls = isFullscreen && media.length > 1;

  return (
    <div className={styles['container']}>
      <Slider ref={sliderRef} loop={true} behavior="auto">
        <div
          className={cx(
            styles['scroller'],
            bordered && styles['scroller--bordered'],
            isFullscreen && styles['fullscreen'],
          )}
          onClick={handleScrollerClick}
        >
          <Scroller onScrollEnd={handleScrollEnd}>
            {media.map((m, index) => (
              <Image
                key={`slide_${index}`}
                src={m.src}
                objectFit={objectFit}
                loading={index === 0 ? null : undefined}
                decoding={index === 0 ? null : undefined}
              />
            ))}
          </Scroller>
          {shouldShowFullscreenControls && (
            <Overlay>
              <span data-testid={fullscreenControlsTestId}>
                <Buttons size="S" />
              </span>
            </Overlay>
          )}
          {slotScrollerEnd}
        </div>
        {isFullscreen && (
          <div className={styles['thumbs']} data-testid={paginationThumbsTestId}>
            <div className={cx(styles['thumbs-wrapper'], thumbsBounded && styles['thumbs-wrapper--bounded'])}>
              <PaginationThumbs ref={paginationThumbsRef} media={media} />
            </div>
          </div>
        )}
      </Slider>
    </div>
  );
};

export const GalleryInner = React.memo(React.forwardRef(GalleryInnerComponent));

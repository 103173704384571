import { plural } from '@cian/utils';
import * as React from 'react';

import { IPhotoSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { AreasHeaderRow } from './AreasHeaderRow';
import * as styles from './AreasTable.css';
import { ShowFullListButton } from './ShowFullListButton';
import { useScrollToElement, useToggleShowMore } from './hooks';
import { IFormattedAreaPartsInfoSchema } from '../../../../repositories/offer-card/entities/formatted_area_parts_info/FormattedAreaPartsInfoSchema';
import { AreasRowContainer } from '../../containers/AreasRowContainer';

interface IAreasTableProps {
  formattedAreaParts: IFormattedAreaPartsInfoSchema;
  offerDataPhotos: IPhotoSchema[] | null;
}

const AREAS_COUNT_TO_DISPLAY = 5;

export const areasTableTestId = 'areas-table';

export const AreasTable: React.FC<IAreasTableProps> = props => {
  const { formattedAreaParts, offerDataPhotos } = props;

  const headerRef = React.useRef<HTMLDivElement>(null);

  const [tableData, isFullListDisplayed, shouldShowToggleControl, toggleShowFullList] = useToggleShowMore(
    formattedAreaParts.parts,
    AREAS_COUNT_TO_DISPLAY,
  );

  useScrollToElement(headerRef.current, !isFullListDisplayed);

  const numberOfAreas = formattedAreaParts.parts.length;
  const partialListText = `Показать все ${numberOfAreas} ${plural(numberOfAreas, ['площадь', 'площади', 'площадей'])}`;

  return (
    <div className={styles['container']} data-testid={areasTableTestId} ref={headerRef}>
      <AreasHeaderRow numberOfParts={formattedAreaParts.parts.length} />

      {tableData.map((areaPart, index) => (
        <AreasRowContainer
          // Сделано намеренно, комбинация area-price может не быть уникальной внутри объекта недвижимости
          key={`${areaPart.area}-${areaPart.price}-${index}`}
          part={areaPart}
          isTableExpandable={shouldShowToggleControl}
          offerDataPhotos={offerDataPhotos}
        />
      ))}

      {shouldShowToggleControl && (
        <span className={styles['show-all-areas-container']}>
          <ShowFullListButton
            toggleShowFullList={toggleShowFullList}
            isFullListDisplayed={isFullListDisplayed}
            fullListText="Свернуть"
            partialListText={partialListText}
          />
        </span>
      )}
    </div>
  );
};

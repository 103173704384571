import { IBusinessShoppingCenterSchema } from '../../../../../../repositories/offer-card/entities/business_shopping_center/BusinessShoppingCenterSchema';
import { IPhoto, Photo } from '../../../CommercialTCBCPhotoGallery';

export function getTCBCPhotos(businessShoppingCenter: IBusinessShoppingCenterSchema): Array<IPhoto> {
  if (!businessShoppingCenter.photos) {
    return Array.of<IPhoto>();
  }

  return businessShoppingCenter.photos.reduce((accumulator, photo) => {
    if (photo.fullUrl) {
      accumulator.push(new Photo(`${photo.id}`, photo.fullUrl));
    }

    return accumulator;
  }, Array.of<IPhoto>());
}

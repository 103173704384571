import { Button, LinkButton, UIHeading2, UIHeading4, UIText1, UIText2 } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './BestPlaceReportDescription.css';
import { REPORT_FEATURES } from './constants';

export interface IBestPlaceReportDescriptionProps {
  reportUrl: string;
  isBestPlaceCheckout: boolean;
  reportExampleUrl: string;
  providerInfo?: string;
  handleBuyReport: VoidFunction;
  handleExamineReportExample: VoidFunction;
  getReportPrices: VoidFunction;
}

export const BestPlaceReportDescription: React.FC<IBestPlaceReportDescriptionProps> = props => {
  const {
    reportUrl,
    providerInfo,
    reportExampleUrl,
    isBestPlaceCheckout,
    handleBuyReport,
    handleExamineReportExample,
    getReportPrices,
  } = props;

  React.useEffect(() => {
    getReportPrices();
  }, [getReportPrices]);

  const content = (() => {
    const numberOfItemsPerColumn = Math.ceil(REPORT_FEATURES.length / 2);

    const columns = REPORT_FEATURES.reduce(
      (acc: { columnA: JSX.Element[]; columnB: JSX.Element[] }, { Icon, text }, ind) => {
        const item = (
          <li className={styles['content-item']} key={ind}>
            <span className={styles['content-item-icon']}>{Icon}</span>
            <UIText1>{text}</UIText1>
          </li>
        );

        const isFirstColumnContent = ind + 1 <= numberOfItemsPerColumn;

        (isFirstColumnContent ? acc.columnA : acc.columnB).push(item);

        return acc;
      },
      { columnA: [], columnB: [] },
    );

    return (
      <ul className={styles['content']}>
        <div className={styles['content-column']}>{columns.columnA}</div>
        <div className={styles['content-column']}>{columns.columnB}</div>
      </ul>
    );
  })();

  const purchaseButton = isBestPlaceCheckout ? (
    <LinkButton
      theme="fill_primary"
      beforeIcon={
        <span className={styles['price-wrapper']}>
          <UIText1 color="text-inverted-default" fontWeight="700">
            Купить отчёт у партнёра
          </UIText1>
        </span>
      }
      onClick={handleBuyReport}
      href={reportUrl}
      target="_blank"
    />
  ) : (
    <Button
      theme="fill_primary"
      beforeIcon={
        <span className={styles['price-wrapper']}>
          <UIText1 color="text-inverted-default" fontWeight="700">
            Купить отчёт у партнёра
          </UIText1>
        </span>
      }
      onClick={handleBuyReport}
    />
  );

  return (
    <section className={styles['wrapper']}>
      <div className={styles['header']}>
        <span>
          <UIHeading2>Отчёт о привлекательности объекта</UIHeading2>
          <span className={cx(styles['header-description'])}>
            <UIText1>Узнайте, насколько помещение и район подходят для вашего бизнеса</UIText1>
          </span>
        </span>
      </div>
      <div className={styles['secondary-header']}>
        <UIHeading4>Что входит в отчёт</UIHeading4>
      </div>
      {content}
      <div className={cx(styles['footer'])}>
        <div className={cx(styles['footer-buttons'])}>
          {purchaseButton}
          <LinkButton
            theme={'fill_white_secondary'}
            beforeIcon={
              <UIText1 color="text-main-default" fontWeight="700">
                Посмотреть пример отчёта
              </UIText1>
            }
            href={reportExampleUrl}
            download="report-example"
            target="_blank"
            rel="noreferrer"
            onClick={handleExamineReportExample}
          />
        </div>
        {providerInfo && <UIText2 color="gray60_100">{providerInfo}</UIText2>}
      </div>
    </section>
  );
};

BestPlaceReportDescription.displayName = 'BestPlaceReportDescription';

import { batch } from 'react-redux';

import { EBestPlaceReportStatus } from 'shared/types/bestPlaceAnalytics';

import { IThunkActionCreator } from '../../store';
import { setBestPlaceAnalyticsStatus, setBestPlaceAnalyticsStatusMessage } from '../bestPlaceAnalytics';
import { getRequestUrl, unsubscribePaymentStatusUpdate } from '../bestPlaceAnalytics/utils';

export function proceedToCheckEmail(): IThunkActionCreator {
  return dispatch => {
    unsubscribePaymentStatusUpdate();

    const url = getRequestUrl();

    window.history.pushState({}, document.title, url);

    batch(() => {
      dispatch(setBestPlaceAnalyticsStatus(EBestPlaceReportStatus.CheckEmail));
      dispatch(setBestPlaceAnalyticsStatusMessage(null));
    });
  };
}

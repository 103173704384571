import { connect } from 'react-redux';

import { closeBuyAnalyticsModal, getPaymentUrl } from '../../actions/buyAnalyticsModal';
import { sendSoprAnalytics } from '../../actions/buyAnalyticsModal/sendSoprAnalytics';
import { subscribePaymentStatusUpdate } from '../../actions/buyAnalyticsModal/subscribePaymentStatusUpdate';
import { BuyAnalyticsModal } from '../../components/BuyAnalyticsModal';
import { IBuyAnalyticsModalWidgetProps } from '../../components/BuyAnalyticsModal/types';
import {
  selectIsBuyAnalyticsModalOpened,
  selectBuyAnalyticsReportPrice,
  selectBuyAnalyticsModalStatus,
  selectFreeReportData,
  selectBuyAnalyticsPropertyName,
  selectBuyAnalyticsPropertyAddress,
  selectBuyAnalyticsPaymentEmail,
  selectLegalDocsUrl,
} from '../../selectors/buyAnalyticsModal';
import { selectBuyAnalyticsLegalDocsUrlEnabled } from '../../selectors/featureToggle';
import { IState } from '../../store';

type StateProps = Pick<
  IBuyAnalyticsModalWidgetProps,
  | 'buildingAddress'
  | 'buildingName'
  | 'isOpened'
  | 'reportPrice'
  | 'status'
  | 'userEmail'
  | 'freeReportData'
  | 'isLegalDocsUrlEnabled'
  | 'legalDocsUrl'
>;
type DispatchProps = Pick<
  IBuyAnalyticsModalWidgetProps,
  'onClose' | 'onSubmit' | 'onUpdatePaymentStatus' | 'onTrackEvent'
>;
type OwnProps = Omit<IBuyAnalyticsModalWidgetProps, keyof StateProps | keyof DispatchProps>;

/* istanbul ignore next */
export const BuyAnalyticsModalContainer = connect<StateProps, DispatchProps, OwnProps, IState>(
  state => {
    return {
      buildingAddress: selectBuyAnalyticsPropertyAddress(state),
      buildingName: selectBuyAnalyticsPropertyName(state),
      isOpened: selectIsBuyAnalyticsModalOpened(state),
      reportPrice: selectBuyAnalyticsReportPrice(state),
      status: selectBuyAnalyticsModalStatus(state),
      userEmail: selectBuyAnalyticsPaymentEmail(state),
      freeReportData: selectFreeReportData(state),
      /**
       * @todo Удалить проверку фичатоггла buyAnalyticsLegalDocsUrl.iap.Enabled
       * @description Данный функционал появился в задаче CD-137590, будет удалён в задаче CD-137656
       */
      isLegalDocsUrlEnabled: selectBuyAnalyticsLegalDocsUrlEnabled(state),
      legalDocsUrl: selectLegalDocsUrl(state),
    };
  },
  {
    onClose: closeBuyAnalyticsModal,
    onSubmit: getPaymentUrl,
    onUpdatePaymentStatus: subscribePaymentStatusUpdate,
    onTrackEvent: sendSoprAnalytics,
  },
)(BuyAnalyticsModal);

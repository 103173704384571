import { ca } from '@cian/analytics';

import { IUserTrackingData, ITrackingPageData } from '../../../../../types';
import { FORM_FIELDS } from '../../../components/FeedbackForm/constants';
import { IFormValues } from '../../../components/FeedbackForm/types';

export const trackGeoAnalyticsPopupOpened =
  (user: IUserTrackingData, page: ITrackingPageData, offerId: number) => () => {
    ca('eventSite', {
      name: 'oldevent',
      category: 'Card',
      action: 'show_sopr',
      label: 'geoanalytics_popup_feedback',
      user: {
        userId: user.cianUserId,
      },
      page: {
        page: page.pageType,
      },
      products: [{ id: offerId }],
    });
  };

const trackingFeedbackOrder = [
  FORM_FIELDS.userType,
  FORM_FIELDS.reportTarget,
  FORM_FIELDS.analyticsFrequency,
  FORM_FIELDS.businessType,
];

export const trackGeoAnalyticsPopupFeedback =
  (user: IUserTrackingData, page: ITrackingPageData, offerId: number) => (feedbackValues: IFormValues) => {
    const feedbackResults = trackingFeedbackOrder
      .reduce((acc, field) => [...acc, feedbackValues[field] || 'none'], [])
      .join('/');

    ca('eventSite', {
      name: 'oldevent',
      category: 'Card',
      action: 'send',
      label: 'geoanalytics_popup_feedback',
      user: {
        userId: user.cianUserId,
      },
      page: {
        page: page.pageType,
        extra: {
          feedbackResults,
        },
      },
      products: [{ id: offerId }],
    });
  };

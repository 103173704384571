import { EPaymentStatus } from 'shared/repositories/commercial-geo-analytics/entities/responses/PublicV1GetPaymentStatusResponseSchema';
import { EBestPlaceReportStatus } from 'shared/types/bestPlaceAnalytics';

export const DEFAULT_ERROR_MESSAGE =
  '[fetchBestPlaceAnalyticsPaymentStatus]: Ошибка получения статуса платежа аналитического отчета best place';

export const DOMAIN_TEXT =
  'shared/services/bestPlaceAnalytics/fetchBestPlaceAnalyticsPaymentStatus#fetchBestPlaceAnalyticsPaymentStatus()';

export const REPORT_STATUS_BY_PAYMENT_STATUS = {
  [EPaymentStatus.Canceled]: EBestPlaceReportStatus.PaymentCancelled,
  [EPaymentStatus.Created]: EBestPlaceReportStatus.PaymentCreated,
  [EPaymentStatus.Paid]: EBestPlaceReportStatus.ReportPaid,
  [EPaymentStatus.Paid_error]: EBestPlaceReportStatus.ReportPaidError,
  // Статусы ниже используются только внутри бэка и используются уже после оплаты
  // Для нас это означает что оплата прошла и можем юзать EBestPlaceReportStatus.ReportPaid
  [EPaymentStatus.Pending_report]: EBestPlaceReportStatus.ReportPaid,
  [EPaymentStatus.Report_delivered]: EBestPlaceReportStatus.ReportPaid,
  [EPaymentStatus.Report_ready]: EBestPlaceReportStatus.ReportPaid,
  [EPaymentStatus.Report_sent]: EBestPlaceReportStatus.ReportPaid,
  [EPaymentStatus.Sending_error]: EBestPlaceReportStatus.ReportPaid,
};

import { ILinkButton } from '../../../../../../models/LinkButton';
import { IAgentSchema } from '../../../../../../repositories/offer-card/entities/agent/AgentSchema';
import { IBusinessShoppingCenterSchema } from '../../../../../../repositories/offer-card/entities/business_shopping_center/BusinessShoppingCenterSchema';
import { IOfferSchema } from '../../../../../../repositories/offer-card/entities/offer/OfferSchema';
import { TCBCMoreDetailsButtonFacade } from '../../../../../../services/LinkButtonFacade/implementations/TCBC';
import { trackTCBCFactory } from '../tracking/trackTCBCFactory';

export function getTCBCMoreDetailsButton(
  offer: IOfferSchema,
  businessShoppingCenter: IBusinessShoppingCenterSchema,
  agent: IAgentSchema | null,
): ILinkButton | null {
  const tcbcMoreDetailsButtonFacade = new TCBCMoreDetailsButtonFacade(businessShoppingCenter);

  return tcbcMoreDetailsButtonFacade.getLinkButton(() => {
    trackTCBCFactory(offer, businessShoppingCenter, agent)('to_BC_card');
  });
}

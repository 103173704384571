import React from 'react';

import { IOption } from 'shared/models/Option';

import styles from './CommercialTCBCInfo.css';
import { CommercialTCBCInfoItem } from '../CommercialTCBCInfoItem';

export interface ICommercialTCBCInfoProps {
  items: Array<IOption<JSX.Element | string | number, JSX.Element | string | number>>;
}

export const CommercialTCBCInfo: React.FC<ICommercialTCBCInfoProps> = props => {
  const { items } = props;

  return (
    <ul className={styles['container']}>
      {items.map(item => (
        <CommercialTCBCInfoItem key={item.key} label={item.label} value={item.value} />
      ))}
    </ul>
  );
};

CommercialTCBCInfo.displayName = 'CommercialTCBCInfo';

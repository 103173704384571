import { connect } from 'react-redux';

import { CommercialAboutOther, ICommercialAboutOtherProps } from './index';
import { IState } from '../../../store';

function mapStateToProps(state: IState): ICommercialAboutOtherProps {
  return {
    building: state.offerData.offer.building,
    businessShoppingCenter: state.offerData.businessShoppingCenter,
    offer: state.offerData.offer,
  };
}

export const CommercialAboutOtherContainer = connect<ICommercialAboutOtherProps, {}, {}>(mapStateToProps)(
  CommercialAboutOther,
);

import * as React from 'react';

import * as styles from './InformationWithIconItemView.css';
import { IInformationWithIconItemViewProps } from './types';

export const InformationWithIconItemView: React.FC<IInformationWithIconItemViewProps> = props => {
  const { icon, content } = props;

  return (
    <div className={styles['container']}>
      <div className={styles['icon']}>{icon}</div>
      <div className={styles['content']}>{content}</div>
    </div>
  );
};

InformationWithIconItemView.displayName = 'InformationWithIconItemView';

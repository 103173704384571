import * as React from 'react';

import * as styles from './ShowFullListButton.css';

interface IShowFullListButtonProps {
  toggleShowFullList: VoidFunction;
  isFullListDisplayed: boolean;
  fullListText: string;
  partialListText: string;
}

export const showFullListButtonTestId = 'show-fullList-button-test-id';

export const ShowFullListButton: React.FC<IShowFullListButtonProps> = props => {
  const { toggleShowFullList, isFullListDisplayed, fullListText, partialListText } = props;

  return (
    <span data-testid={showFullListButtonTestId}>
      <button onClick={toggleShowFullList} className={styles['button']}>
        {isFullListDisplayed ? fullListText : partialListText}
      </button>
    </span>
  );
};

ShowFullListButton.displayName = 'ShowFullListButton';

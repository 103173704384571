interface ILocalesItem {
  [index: string]: string;
}

interface ILocales {
  conditionRatingType: ILocalesItem;
  houseLineType: ILocalesItem;
  repairStateType: ILocalesItem;
  shoppingCenterScaleType: ILocalesItem;
  workingDaysType: ILocalesItem;
}

export const locales: ILocales = {
  conditionRatingType: {
    excellent: 'Отличное',
    good: 'Хорошее',
    satisfactory: 'Удовлетворительное',
  },
  houseLineType: {
    first: 'Первая',
    other: 'Иная',
    second: 'Вторая',
  },
  repairStateType: {
    afterReconstruction: 'После реконструкции',
    cosmeticRepairsRequired: 'Нужен косметический ремонт',
    majorRepairsRequired: 'Нужен капитальный ремонт',
    needsReconstruction: 'Необходима реконструкция',
    new: 'Новое',
    workingCondition: 'Рабочее',
  },
  shoppingCenterScaleType: {
    district: 'Районный',
    microdistrict: 'Микрорайонный',
    okrug: 'Окружной',
    regional: 'Региональный',
    superOkrug: 'Суперокружной',
    superRegional: 'Суперрегиональный',
  },
  workingDaysType: {
    everyday: 'Ежедневно',
    friday: 'Пятница',
    monday: 'Понедельник',
    saturday: 'Суббота',
    sunday: 'Воскресенье',
    thursday: 'Четверг',
    tuesday: 'Вторник',
    wednesday: 'Среда',
    weekdays: 'Будни',
    weekends: 'Выходные',
  },
};

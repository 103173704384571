import { ca } from '@cian/analytics';

import {
  IAgentSchema as DEPRECATED__IAgentSchema,
  IOfferSchema as DEPRECATED__IOfferSchema,
} from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IAgentSchema } from '../../../../../../repositories/offer-card/entities/agent/AgentSchema';
import { IBusinessShoppingCenterSchema } from '../../../../../../repositories/offer-card/entities/business_shopping_center/BusinessShoppingCenterSchema';
import { IOfferSchema } from '../../../../../../repositories/offer-card/entities/offer/OfferSchema';
import { getPage } from '../../../../../../utils/tracking/getPage';
import { getTCBCAnalyticsLabel } from '../../../../../../utils/tracking/getTCBCAnalyticsLabel';
import { getProducts } from '../../../../../../utils/tracking/get_products';

type ActionType = `to_BC_${'listing' | 'card'}` | 'click' | 'to_card';
type LabelType = 'show_bc_announcements' | 'bc_announcements_list';

export const trackTCBCFactory =
  (offer: IOfferSchema, businessShoppingCenter: IBusinessShoppingCenterSchema, agent: IAgentSchema | null) =>
  (action: ActionType, label?: LabelType) => {
    const products = getProducts({
      agent: agent as unknown as DEPRECATED__IAgentSchema,
      offer: offer as unknown as DEPRECATED__IOfferSchema,
      kpId: null,
    });

    ca('eventSite', {
      name: 'oldevent',
      action,
      category: 'Card',
      label: label ?? getTCBCAnalyticsLabel(businessShoppingCenter.type),
      page: getPage(agent as unknown as DEPRECATED__IAgentSchema, offer as unknown as DEPRECATED__IOfferSchema, null),
      products: [{ ...products, variant: products.variant ? products.variant.split('/') : [] }],
    });
  };

/**
 * @todo Удалить файл с экспериментом
 * @description Данный функционал появился в задаче CD-129223, будет удалён в задаче CD-129224
 * Удалить, если эксперимент будет не удачным
 */

import { ca } from '@cian/analytics';

import { IAgentSchema, IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { getPage } from './getPage';
import { getProducts } from './get_products';

/**
 * @deprecated
 * @todo Удалить комментарий с экспериментом
 * @description Данный функционал появился в задаче CD-129223, будет удалён в задаче CD-129224
 * Удалить если эксперимент будет удачным
 */
export function trackPhonesLimitShown(offer: IOfferSchema, agent: IAgentSchema | null) {
  const product = getProducts({ agent, offer, kpId: null });

  ca('eventSite', {
    action: 'Phones_limit_show',
    category: 'Card',
    name: 'oldevent',
    page: getPage(agent, offer),
    products: [product],
  });
}

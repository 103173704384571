import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  selectStatusBarHasError,
  selectStatusBarMessage,
  selectStatusBarHasSpinner,
} from 'shared/selectors/bestPlaceAnalytics';

import { StatusBar } from '../../components/StatusBar';

export const StatusBarContainer: React.FC = () => {
  const statusMessage = useSelector(selectStatusBarMessage);
  const hasSpinner = useSelector(selectStatusBarHasSpinner);
  const hasError = useSelector(selectStatusBarHasError);

  return <StatusBar statusMessage={statusMessage} hasSpinner={hasSpinner} hasError={hasError} />;
};

StatusBarContainer.displayName = 'StatusBarContainer';

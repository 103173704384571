import { ca } from '@cian/analytics';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useAbGroupSelector } from 'shared/hooks/selectors/useAbGroupSelector';
import { useOfferSelector } from 'shared/hooks/selectors/useOfferSelector';
import { selectUser } from 'shared/selectors';
import { getUser } from 'shared/utils/tracking/getUser';

type Action = 'click_buy_report' | 'click_see_report_example' | 'click_order_report';

export function useTrackBestPlaceReport() {
  const { id } = useOfferSelector();
  const user = useSelector(selectUser);
  const abGroup = useAbGroupSelector();
  const { userId } = getUser(user, abGroup);

  return useCallback<(action: Action) => void>(
    action =>
      ca('eventSite', {
        name: 'oldevent',
        action,
        category: 'Card',
        label: 'geoanalytics_popup',
        page: {
          pageType: 'Card',
        },
        products: [{ id }],
        user: { userId },
      }),
    [id, userId],
  );
}

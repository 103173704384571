import { ReactNode } from 'react';

import { IOption } from '../IOption';

export class Option<Label extends ReactNode, Value extends ReactNode> implements IOption<Label, Value> {
  public readonly label: Label;

  public readonly value: Value;

  public readonly key: string;

  public constructor(label: Label, value: Value, key: string) {
    this.label = label;
    this.value = value;
    this.key = key;
  }
}

import { ActionCreator, ActionCreatorsMapObject, bindActionCreators } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useBoundActions<A extends ActionCreator<any>>(actionCreator: A): A;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useBoundActions<A extends ActionCreator<any>, B extends ActionCreator<any>>(actionCreator: A): B;

export function useBoundActions<M extends ActionCreatorsMapObject>(actionCreators: M): M;

export function useBoundActions<M extends ActionCreatorsMapObject, N extends ActionCreatorsMapObject>(
  actionCreators: M,
): N;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useBoundActions<A extends ActionCreator<any>>(actionCreators: A): A {
  const dispatch = useDispatch();

  return useMemo(() => bindActionCreators(actionCreators, dispatch), [actionCreators, dispatch]);
}

import * as React from 'react';

import iconSrc from './chart.png';

interface IChartIconProps {
  size?: number;
}

export const ChartIcon: React.FC<IChartIconProps> = ({ size = 56 }) => {
  return <img width={size} height={size} src={iconSrc} alt="chart icon" />;
};

ChartIcon.displayName = 'ChartIcon';

import { ActionCreator, ActionCreatorsMapObject } from '@reduxjs/toolkit';

import { useBoundActions } from './useBoundActions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function boundActionsFactory<A extends ActionCreator<any>>(actionCreator: A): () => A;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function boundActionsFactory<A extends ActionCreator<any>, B extends ActionCreator<any>>(
  actionCreator: A,
): () => B;

export function boundActionsFactory<M extends ActionCreatorsMapObject>(actionCreators: M): () => M;

export function boundActionsFactory<M extends ActionCreatorsMapObject, N extends ActionCreatorsMapObject>(
  actionCreators: M,
): () => N;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function boundActionsFactory<A extends ActionCreator<any>>(actionCreators: A): () => A {
  return useBoundActions.bind(null, actionCreators);
}

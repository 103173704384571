/* eslint-disable */
//tslint:disable

import { IBusinessShoppingCenterGroupOfferSchema } from './BusinessShoppingCenterGroupOfferSchema';

export interface IBusinessShoppingCenterOfferGroupSchema {
  /** Минимальная цена в группе **/
  minPrice?: number | null;
  /** Минимальная площадь в группе **/
  minTotalArea?: number | null;
  /** Тип группы **/
  offerGroupType: EOfferGroupType;
  /** Количество объявлений в группе **/
  offersCount: number;
  /** undefined **/
  otherOffers: IBusinessShoppingCenterGroupOfferSchema[];
  /** Ссылка на список объявлений в выдаче объявлений **/
  titleLink: string;
}

export enum EOfferGroupType {
  /** Здание **/
  Building = 'building',
  /** Готовый бизнес **/
  Business = 'business',
  /** Аренда автосервис **/
  CarService = 'carService',
  /** Коммерческая земля **/
  CommercialLand = 'commercialLand',
  /** Коворкинг **/
  Coworking = 'coworking',
  /** Помещение под бытовые услуги **/
  DomesticServices = 'domesticServices',
  /** Помещение свободного назначения **/
  FreeAppointmentObject = 'freeAppointmentObject',
  /** Производство **/
  Industry = 'industry',
  /** Офис **/
  Office = 'office',
  /** Общепит **/
  PublicCatering = 'publicCatering',
  /** Торговая площадь **/
  ShoppingArea = 'shoppingArea',
  /** Склад **/
  Warehouse = 'warehouse',
}

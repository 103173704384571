import { Button, UIHeading2, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './AuthRequiredBlock.css';
import { LockImage } from './internal/components/LockImage';
import { authAdvantagesMapper } from './internal/mappers/authAdvantagesMapper';
import { callAuthPopup } from './internal/utils/callAuthPopup';
import { InformationWithIconItemView } from '../InformationWithIconItemView';

export const AuthRequiredBlock: React.FC = () => {
  return (
    <section className={styles['container']}>
      <div className={styles['lock-icon-wrapper']}>
        <LockImage />
      </div>
      <h2 className={styles['heading-wrapper']}>
        <UIHeading2 textAlign="center">
          Войдите или зарегистрируйтесь
          <br />
          для просмотра информации
        </UIHeading2>
      </h2>
      <div className={styles['enter-button']}>
        <Button type="button" size="XS" theme="fill_primary" onClick={callAuthPopup}>
          Войти
        </Button>
      </div>
      <div className={styles['auth-advantages-container']}>
        {authAdvantagesMapper.map((advantageItem, index) => {
          return (
            <InformationWithIconItemView
              key={index}
              icon={advantageItem.icon}
              content={<UIText1>{advantageItem.content}</UIText1>}
            />
          );
        })}
      </div>
    </section>
  );
};

AuthRequiredBlock.displayName = 'AuthRequiredBlock';

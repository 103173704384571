import { batch } from 'react-redux';

import { selectStatus } from 'shared/selectors/bestPlaceAnalytics';
import { EBestPlaceReportStatus } from 'shared/types/bestPlaceAnalytics';

import { closeBestPlaceReportModal } from './actions';
import { IThunkActionCreator } from '../../store';
import { setBestPlaceAnalyticsStatus, setBestPlaceAnalyticsStatusMessage } from '../bestPlaceAnalytics';
import { getRequestUrl, unsubscribePaymentStatusUpdate } from '../bestPlaceAnalytics/utils';

const statusesToRemoveTransactionId = [EBestPlaceReportStatus.ReportPaid, EBestPlaceReportStatus.FeedbackSent];

export function closeBestPlaceModal(): IThunkActionCreator {
  return (dispatch, getState) => {
    unsubscribePaymentStatusUpdate();

    const paymentStatus = selectStatus(getState());

    if (paymentStatus && statusesToRemoveTransactionId.includes(paymentStatus)) {
      const url = getRequestUrl();

      window.history.pushState({}, document.title, url);
    }

    batch(() => {
      dispatch(closeBestPlaceReportModal());
      dispatch(setBestPlaceAnalyticsStatus(null));
      dispatch(setBestPlaceAnalyticsStatusMessage(null));
    });
  };
}

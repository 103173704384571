import * as React from 'react';

import { IPhotoSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { prepareFormattedMedia } from './helpers';
import { AreaPartsFullScreenControlsContainer } from '../../../../containers/AreaPartsFullScreenControlsContainer';
import { IFormattedAreaPartSchema } from '../../../../repositories/offer-card/entities/formatted_area_parts_info/FormattedAreaPartSchema';
import { AreaPartsGallery } from '../../../AreaPartsGallery';
import { AreasRow } from '../../components/AreasTable';

interface IAreasTableRowContainerProps {
  part: IFormattedAreaPartSchema;
  isTableExpandable: boolean;
  offerDataPhotos: IPhotoSchema[] | null;
}

export const AreasRowContainer: React.FC<IAreasTableRowContainerProps> = props => {
  const { part, isTableExpandable, offerDataPhotos } = props;

  const partPhotosWithFullData = React.useMemo(() => {
    if (part.photos && part.photos.length !== 0 && offerDataPhotos) {
      return part.photos.reduce<IPhotoSchema[]>((acc, partPhoto) => {
        const offerDataPhoto = offerDataPhotos.find(offerDataPhoto => offerDataPhoto.id === partPhoto.id);

        if (offerDataPhoto) {
          acc.push({ ...offerDataPhoto, isDefault: Boolean(partPhoto.isDefault) });
        }

        return acc;
      }, []);
    }

    return [];
  }, [offerDataPhotos, part.photos]);

  const media = React.useMemo(() => prepareFormattedMedia(partPhotosWithFullData), [partPhotosWithFullData]);

  return (
    <AreasRow
      part={part}
      isTableExpandable={isTableExpandable}
      gallerySlot={
        <AreaPartsGallery
          media={media}
          slotFullscreenBefore={<AreaPartsFullScreenControlsContainer areaPartData={part} />}
        />
      }
    />
  );
};

AreasRowContainer.displayName = 'AreasRowContainer';
